const trJSON = {
  'DUPLICATE_OF': 'Kopyası',
  'DUPLICATED_BY': 'Tarafından çoğaltıldı',
  'RELATED_TO': 'İle ilgili',
  'SPLIT_TO': 'Şuna bölün:',
  'SPLIT_FROM': 'Ayrılmak',
  'BLOCKED_BY': 'Tarafından engellendi',
  'BLOCKS': 'Bloklar',
  'MORE_THAN': 'Şundan büyük:',
  'LESS_THAN': 'Daha az',
  'OPEN': 'Açık',
  'IN_PROGRESS': 'Devam etmekte',
  'ON_HOLD': 'Beklemede',
  'COMPLETE': 'Tamamlanmış',
  'home.built': 'Bakım Yönetimini Kolaylaştırmak için Tasarlandı',
  'home_description': 'Beyaz tahtalar ve yapışkan notlar kaosa dönüştüğünde ve ekibinizin gerçekten kullanabileceği kadar kolay bir CMMS yazılımına ihtiyaç duyduğunuzda Atlas\'ı deneyin',
  'try_grash': 'Atlas\'ı deneyin',
  'login': 'Giriş',
  'start_trial': 'Ücretsiz denemeyi başlatın',
  'home.what': 'Ne',
  'home.you_will_have': 'Sahip olacaksın',
  'home.you_will_have_description': 'Bakımın kontrolünü size veren verimli, güvenilir iş akışları oluşturun. ',
  'key_features': 'Ana Özellikler',
  'key_features_description': 'Atlas\'ı günümüzün en iyi CMMS\'lerinden biri yapan özelliklerden bazıları',
  'contribute': 'Katkıda bulun',
  'work_orders': 'İş Emirleri',
  'request_system': 'İş Talep Sistemi',
  'mobile_app': 'Mobil uygulama',
  'asset_management': 'Varlık Yönetimi',
  'preventive_maintenance': 'Önleyici Bakım',
  'parts_inventory': 'Parça Envanteri',
  'custom_dashboards': 'Özel Kontrol Panelleri',
  'work-orders.title': 'İş Emirlerinizi bir Patron gibi yönetin',
  'work-orders.description.short': 'Tüm Görevlerinizi Görüntüleyin ve Yönetin',
  'work-orders.descriptions.0': 'Bir yığın evraktan ve kayıp İş Emirlerinden bıktınız mı? ',
  'work-orders.descriptions.1': 'Atlas ile ekibinizdeki tüm İş Emirlerinizi, PM\'lerinizi ve diğer Görevlerinizi, herhangi bir cihazdan herhangi bir yerden erişilebilen merkezi bir veritabanında kolayca ve hızlı bir şekilde düzenleyebilirsiniz.',
  'work-orders.checks.0': 'WO\'lar, PM\'ler ve İş Talepleri dahil tüm Görevlerinizi görüntüleyin',
  'work-orders.checks.1': 'Doğru işin ilk önce yapılması için Görev önceliğini ayarlayın.',
  'work-orders.checks.2': 'Mevcut ve gelecek Görevlerin takvimini görüntüleyin.',
  'work-orders.checks.3': 'Varlık bilgileri, son tarih, öncelik ve daha fazlası gibi kritik bilgileri içeren iş atamalarını görüntüleyin.',
  'work-orders.checks.4': 'Gerçekte tamamlanan iş, harcanan süre, planlanan ve planlanmayan iş gibi kritik KPI\'ları görüntüleyin.',
  'work-requests.title': 'Kolaylaştırılmış bir mobil iş talebi sistemi',
  'work-requests.descriptions.0': 'KAYIP biletlere, sürekli günlük kesintilere ve mükerrer işlere yol açan eski telefon ve e-posta sistemini kullanmaktan bıktınız mı?',
  'work-requests.descriptions.1': 'Atlas\'ın bakım yönetimi çözümü, yetkilendirdiğiniz herkesin, yalnızca bir QR kodunu (barkod) tarayarak veya bir URL\'yi ziyaret edip mobil cihazları aracılığıyla isteklerini yazarak bakım departmanınıza sorun göndermesine olanak tanır.',
  'work-requests.checks.0': 'İş\'i bir daha asla kaybetmeyin.',
  'work-requests.checks.1': 'İş Taleplerini işlemeye harcanan zamanı %34\'e kadar azaltın.',
  'work-requests.checks.2': 'Talep Edenlerle iletişim kurma süresini %41\'e kadar azaltın (çağrılar, e-postalar, mesajlar).',
  'mobile-app.title': 'Bakım ekibinize güç veren mobil CMMS uygulaması',
  'mobile-app.descriptions.0': 'Müşterilerimiz, Android ve IOS mobil uygulamalarımızı kullanarak bakım ekiplerinin tamamında %30\'a kadar daha iyi organizasyon, iletişim, sorumluluk ve üretkenlik elde ediyor.',
  'mobile-app.descriptions.1': 'Bakım ekibinizdeki herkes, el tipi cihazlarından şunları yapabilir:',
  'mobile-app.checks.0': 'Atanan İş Emirlerini ve PM\'lerini görüntüleyin.',
  'mobile-app.checks.1': 'İş Emirlerini 60 saniyeden kısa sürede kaydedin',
  'mobile-app.checks.2': 'Push ve e-posta bildirimleri aracılığıyla anında iletişim alın.',
  'mobile-app.checks.3': 'Sahadayken yeni İş Emirleri başlatın.',
  'mobile-app.checks.4': 'Sorun üzerinde çalışırken sorunlara yanıt verin ve bunları günlüğe kaydedin',
  'mobile-app.checks.5': 'Bir sorunu teşhis ederken kritik varlık bilgilerini arayın.',
  'eam.title': 'Kurumsal Varlık Yönetimi',
  'eam.description.short': 'Varlıklarınızı bilin',
  'eam.descriptions.0': 'Şirketin ekipmanlarının kaotik karmaşasını organize etmeye çalışmaktan, ekipmana gereken bakımın yapılıp yapılmadığını merak etmekten, bakımının ne kadara mal olacağını bilmemekten ve varlık yönetiminin getirdiği tüm diğer baş ağrılarından bıktınız mı?',
  'eam.descriptions.1': 'Atlas CMMS\'nin Kurumsal Varlık Yönetimi modülü, 10 ila 1.000.000 Varlık arasında herhangi bir yerde kullanılabilir ve kullanımı kolay ve aranabilir bir ağaç yapısında tam olarak ne istediğinizi, istediğiniz şekilde izlemenize olanak tanır.',
  'eam.checks.0': 'Varlıklarınızın sağlığını ve size ne kadara mal olduklarını her zaman öğrenin',
  'eam.checks.1': 'Tam ve ayrıntılı Bakım Günlüklerini görüntüleyin.',
  'eam.checks.2': 'Sınırsız özel alanlarla yalnızca istediğinizi takip edin',
  'eam.checks.3': 'Varlıklarınızı net bir ebeveynden çocuğa hiyerarşide düzenleyin',
  'eam.checks.4': 'QR Kodları (barkodlar) ile bilgileri hızlı ve kolay bir şekilde bulun',
  'eam.checks.5': 'Sensör bağlantısıyla gerçek zamanlı varlık verilerini alın.',
  'pm.title': 'Etkili Bir Önleyici Bakım Programı',
  'pm.descriptions.0': 'Değerli saatlerinizi PM kontrol listelerini manuel olarak dağıtarak ve işin tamamlandığından emin olmak için ekibinizle takip ederek boşa harcamayı bırakın.',
  'pm.descriptions.1': 'Atlas, PM planlamasını kolaylıkla otomatikleştirmenize olanak tanır; böylece doğru iş, doğru Varlık için doğru zamanda, doğru teknisyene otomatik olarak teslim edilir.',
  'pm.checks.0': 'PM planlamasını günlük, haftalık, aylık, yıllık veya olaylara veya Ölçer okumalarına dayalı olacak şekilde otomatikleştirin.',
  'pm.checks.1': 'Özel Mesaj oluşturulduğunda veya zamanı geldiğinde otomatik push ve e-posta bildirimleri.',
  'pm.checks.2': 'Kullanımı kolay Takvimimiz, tüm açık işleri ve yaklaşan PM programlarını görüntülemenize olanak tanır.',
  'pm.checks.3': 'Son tarihi değiştirmek için sürükleyip bırakın.',
  'pm.checks.4': 'PM tamamlandıktan sonra iş otomatik olarak varlığın bakım günlüğüne kaydedilir.',
  'pm.checks.5': 'Kullanımı çok az eğitim gerektirir veya hiç gerektirmez.',
  'part.title': 'Yedek Parça Envanterinizin Kontrolünü Elinize Alın',
  'part.description.short': 'Parçalarınızın ne zaman tükeneceğini bilin',
  'part.descriptions.0': 'Stokta hangi parçaların bulunduğundan veya bunların nerede kullanıldığından emin değil misiniz? ',
  'part.descriptions.1': 'Atlas\'ın parça yönetimini bu sorunları ve daha fazlasını çözmek için tasarladık:',
  'part.checks.0': 'Bir parçanın miktarı azaldığında anında push ve e-posta bildirimleri alın. ',
  'part.checks.1': 'Teknisyenler iş emrinin içinden parçanın stokta olup olmadığını anında anlarlar. ',
  'part.checks.2': 'Otomatik parça kullanım takibi. ',
  'part.checks.3': 'Parça kullanımını bilin. ',
  'part.checks.4': 'Kullanılmayan parçaları ortadan kaldırın. ',
  'part.checks.5': 'Gerçek Zamanlı Raporlama. ',
  'part.checks.6': 'Stok seviyelerini optimize edin. ',
  'part.checks.7': 'Barkod araması. ',
  'dashboard.title': 'Özel kontrol panelleriyle verileriniz sizin tarzınızda',
  'dashboard.descriptions.0': 'Kabul edelim ki operasyonlarınızı iyileştirmenin ilk adımı iyiyi, kötüyü ve çirkini bilmektir.',
  'dashboard.descriptions.1': 'Atlas\'ın Özel Kontrol Panelleri ile nihayet bu kötü niyetli "bakım kara deliğini" anlamaya başlayabilir ve veri destekli planları eyleme geçirmeye başlayabilirsiniz, bu da işçilik maliyetlerinin azalmasına, arıza sürelerinin azalmasına ve daha fazlasına yol açabilir.',
  'dashboard.descriptions.2': 'Atlas\'ın Özel Kontrol Panelleri ile şunları elde edeceksiniz:',
  'dashboard.checks.0': 'Paranızın nereye ve neden harcandığını bilin',
  'dashboard.checks.1': 'Hangi varlığın en çok maliyetli olduğunu ve nedenini öğrenin',
  'dashboard.checks.2': 'Kendi özel KPI\'larınızı oluşturun.',
  'dashboard.checks.3': 'Metriğinize göre otomatik olarak oluşturulan ve güncellenen raporları görüntüleyin',
  'dashboard.checks.4': 'Excel sayfaları, PDF\'ler veya Atlas aracılığıyla kolayca paylaşılan raporları görüntüleyin',
  'dashboard.checks.5': 'Ve çok daha fazlası...',
  'home.smarter': 'Daha akıllı',
  'home.work': 'İş',
  'home.smarter_description': 'Atlas, teknisyenlerin işlerini yapmak için ihtiyaç duydukları araçlara ve verilere avuçlarının içinden erişmelerini sağlayarak verimli çalışmalarını inanılmaz derecede kolaylaştırıyor.',
  'leading_maintenance': 'Bakım ve Güvenilirlik Konusunda Daha İyi Bir Geleceğe Yol Açıyoruz',
  'premium_included': 'Premium özellikler dahil',
  'no_credit_card': 'Kredi kartına gerek yok',
  'perfect_tool': 'Bakım ekipleri için mükemmel araç',
  'start_trial_today': 'Ücretsiz denemenizi bugün başlatın',
  'create_account': 'Hesap oluşturun',
  'signup_description': 'Hesaba kaydolmak için aşağıdaki alanları doldurun.',
  'account_already': 'Zaten hesabınız var mı?',
  'signin_here': 'Oturum açın',
  'invalid_email': 'Sağlanan e-posta geçerli bir e-posta adresi olmalıdır',
  'required_email': 'E-posta alanı zorunludur',
  'required_firstName': 'Ad alanı zorunludur',
  'required_lastName': 'Soyadı alanı zorunludur',
  'required_company': 'Şirket adı alanı zorunludur',
  'required_employeesCount': 'Lütfen çalışan sayısını belirtin',
  'invalid_phone': 'telefon numarası geçersiz',
  'required_password': 'Şifre alanı zorunludur',
  'required_terms': 'Şartlar ve koşullarımızı kabul etmelisiniz',
  'verify_email': 'Hesabınızı etkinleştirmek için lütfen e-postanızı kontrol edin',
  'registration_error': 'Kayıt başarılı olmadı',
  'first_name': 'İsim',
  'last_name': 'Soy isim',
  'email': 'E-posta adresi',
  'phone': 'Telefon',
  'password': 'Şifre',
  'companyName': 'Firma Adı',
  'employeesCount': 'Çalışan Sayısı',
  'i_accept': 'Kabul ediyorum',
  'terms_conditions': 'Şartlar ve koşullar',
  'create_your_account': 'Hesabını oluştur',
  'register': 'Kayıt ol',
  'login_description': 'Hesabınızda oturum açmak için aşağıdaki alanları doldurun.',
  'no_account_yet': 'Henüz bir hesabınız yok mu?',
  'signup_here': 'Buradan kaydolun',
  'wrong_credentials': 'Yanlış kimlik bilgileri sağlandı',
  'lost_password': 'Kayıp Şifre ?',
  'link_wo': 'Bağlantı İş Emirleri',
  'link_wo_description': 'İş Emirleri arasında ilişkiler oluşturma',
  'required_relationType': 'Lütfen ilişki türünü seçin.',
  'required_wo': 'İş Emri alanı zorunludur.',
  'this_wo': 'Bu İş Emri',
  'the_wo': 'İş Emri',
  'link': 'Bağlantı',
  'cost_description': 'Maliyet Açıklaması',
  'assigned_to': 'Atandı',
  'category': 'Kategori',
  'date': 'Tarih',
  'cost': 'Maliyet',
  'include_cost': 'Bu maliyeti toplam maliyete dahil edin',
  'include_cost_description': 'Bu, maliyeti İş Emri için harcanan toplam maliyete ekleyecektir',
  'required_cost_description': 'Maliyet Açıklaması gerekli',
  'required_cost': 'Maliyet gerekli',
  'add_cost': 'Ek Maliyet Ekle',
  'add_cost_description': 'Ek Maliyet oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'add': 'Ekle',
  'hourly_rate': 'Saatlik ücret',
  'include_time': 'Bu süreyi toplam süreye dahil edin',
  'include_time_description': 'Bu, süreyi İş Emri üzerinde harcanan toplam süreye ekleyecektir',
  'work_started_at': 'Çalışmanın Başlama Tarihi',
  'duration': 'Süre',
  'hours': 'Saat',
  'minutes': 'dakika',
  'required_hours': 'Saat alanı zorunludur',
  'required_minutes': 'Dakika alanı zorunludur',
  'add_time': 'Zaman ekle',
  'add_time_description': 'Zaman oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'feedback': 'Geri bildirim',
  'feedback_description': 'Geri bildiriminizi bildirin',
  'required_feedback': 'Geri bildirim gerekli',
  'signature': 'İmza',
  'required_signature': 'Resim gerekli',
  'close_wo': 'İş Emri Kapat',
  'close': 'Kapalı',
  'task_update_success': 'Görev başarıyla güncellendi',
  'task_update_failure': 'Görev güncellenemedi',
  'notes_save_success': 'Notlar başarıyla kaydedildi',
  'images_add_task_success': 'Resimler göreve eklendi',
  'images_add_task_failure': 'Bir şeyler yanlış gitti',
  'images': 'Görüntüler',
  'required_images': 'Lütfen en azından bir resim yükleyin',
  'add_images': 'Görsel ekle',
  'tasks': 'Görevler',
  'wo_archive_success': 'İş Emri arşivlendi',
  'wo_archive_failure': 'İş Emri arşivlenemedi',
  'wo_archive_confirm': 'Bu İş Emri\'ni arşivlemek istediğinizden emin misiniz? ',
  'quantity_change_success': 'Miktar başarıyla değiştirildi',
  'quantity_change_failure': 'Miktar değiştirilemedi',
  'details': 'Detaylar',
  'updates': 'Güncellemeler',
  'id': 'İD',
  'due_date': 'Bitiş tarihi',
  'location': 'Konum',
  'asset': 'Varlık',
  'team': 'Ekip',
  'created_at': 'Tarih oluşturuldu',
  'list_view': 'Liste görünümü',
  'calendar_view': 'Takvim Görünümü',
  'column_view': 'Sütun Görünümü',
  'wo_create_success': 'İş Emri başarıyla oluşturuldu',
  'wo_create_failure': 'İş Emri oluşturulamadı',
  'changes_saved_success': 'Değişiklikler kaydedildi',
  'wo_update_failure': 'İş Emri düzenlenemedi',
  'wo_delete_success': 'İş Emri başarıyla silindi',
  'wo_delete_failure': 'İş Emri silinemedi',
  'status': 'Durum',
  'title': 'Başlık',
  'priority': 'Öncelik',
  'description': 'Tanım',
  'worker': 'Çalışan',
  'location_name': 'Yer ismi',
  'location_address': 'Konum adresi',
  'asset_name': 'Varlık adı',
  'days_since_creation': 'Yaratılıştan bu yana geçen günler',
  'files': 'Dosyalar',
  'requested_by': 'İsteyen',
  'completed_on': 'Tamamlandı',
  'updated_at': 'Güncelleme Tarihi:',
  'work_order': 'İş emri',
  'timer_running': 'Zamanlayıcı çalışıyor',
  'run_timer': 'Zamanlayıcıyı Çalıştır',
  'approved_by': 'Tarafından onaylandı',
  'created_by': 'Tarafından yaratıldı',
  'completed_by': 'Tarafından tamamlanmıştır',
  'time': 'Zaman',
  'save': 'Kaydet',
  'customers': 'Yükleniciler',
  'no_labor': 'Henüz işçilik maliyeti eklenmemiştir. ',
  'not_assigned': 'Atanmadı',
  'no_additional_cost': 'Henüz ek maliyet eklenmedi',
  'parts': 'Parçalar',
  'links': 'Bağlantılar',
  'confirm_delete_file_wo': 'Bu dosyayı bu İş Emri\'nden kaldırmak istediğinizden emin misiniz?',
  'archive': 'Arşiv',
  'labors': 'Emekler',
  'add_additional_cost': 'Ek Maliyet Ekle',
  'additional_costs': 'Ek maliyetler',
  'add_parts': 'Parça Ekle',
  'wo.title_description': 'İş Emri başlığını girin',
  'image': 'Resim',
  'estimated_duration': 'Saat Olarak Tahmini Süre',
  'primary_worker': 'Birincil İşçi',
  'additional_workers': 'Ek İşçiler',
  'select_team': 'Ekip seç',
  'select_location': 'Konum seçin',
  'select_asset': 'Varlık seçin',
  'select_tasks': 'Görevleri Seçin',
  'requires_signature': 'İmza Gerektirir',
  'required_wo_title': 'WorkOrder başlığı gerekli',
  'add_wo': 'İş Emri Ekle',
  'add_wo_description': 'Yeni bir İş Emri oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'edit_wo': 'İş Emrini Düzenle',
  'edit_wo_description': 'İş Emri\'ni güncellemek için aşağıdaki alanları doldurun',
  'noRows.wo.message': 'İş Emirleri, birisine planlanabilen veya atanabilen görevler veya işlerdir.',
  'noRows.wo.action': 'İş Emri oluşturmak için \'\' butonuna basın',
  'to_delete': 'Sil',
  'confirm_delete_wo': 'Bu İş Emri\'ni silmek istediğinizden emin misiniz?',
  'no_access_wo': 'İş Emirlerine erişiminiz yok. ',
  'cancel': 'İptal Et',
  'select_parts': 'Parça Seç',
  'sets_of_parts': 'Parça Setleri',
  'paused': 'Duraklatıldı',
  'trigger_details': 'Tetikleyici ayrıntıları',
  'starts_on': 'Başlangıç ​​Tarihi',
  'ends_on': 'Bitiş Tarihi',
  'frequency': 'Sıklık',
  'every_frequency_days': 'Her {{frequency}} gün',
  'wo_details': 'İş Emri ayrıntıları',
  'confirm_delete_file': 'Bu dosyayı kaldırmak istediğinizden emin misiniz?',
  'wo_schedule_success': 'İş Emri başarıyla planlandı',
  'wo_schedule_failure': 'İş Emri planlanamadı',
  'wo_trigger_edit_failure': 'İş Emri Tetikleyicisi düzenlenemedi',
  'wo_trigger_delete_success': 'İş Emri tetikleyicisi başarıyla silindi',
  'wo_trigger_delete_failure': 'İş Emri Tetikleyicisi silinemedi',
  'name': 'İsim',
  'wo_title': 'İş Emri Başlığı',
  'next_wo': 'Sonraki İş Emri',
  'trigger_name': 'Tetikleyici Adı',
  'enter_trigger_name': 'Tetikleyici Adını girin',
  'frequency_description': 'Gün cinsinden sıklık',
  'wo_configuration': 'İş Emri Yapılandırması',
  'required_trigger_name': 'Tetikleyici adı gerekli',
  'required_frequency': 'Tetikleme frekansı gerekli',
  'invalid_frequency': 'Frekans 0\'dan büyük olmalıdır',
  'schedule_wo': 'İş Emri Planla',
  'schedule_wo_description': 'İş Emri planlamak için aşağıdaki alanları doldurun',
  'edit_wo_trigger': 'İş Emri Tetikleyicisini Düzenle',
  'edit_wo_trigger_description': 'İş Emri Tetikleyicisini düzenlemek için aşağıdaki alanları doldurun',
  'create_trigger': 'Tetikleyici Oluştur',
  'noRows.pm.message': 'Özel tetikleyicilerle İş Emirlerini planlayın',
  'noRows.pm.action': 'Tetikleyici oluşturmak için \'\' düğmesine basın',
  'confirm_delete_pm': 'Bu İş Emri Tetikleyicisini silmek istediğinizden emin misiniz?',
  'no_access_pm': 'Önleyici Bakım bölümüne erişiminiz yok. ',
  'enter_wo_title': 'İş Emri başlığını girin',
  'status_report': 'Durum Raporu',
  'wo_analysis': 'İş Emri Analizi',
  'wo_aging': 'İş Emri Yaşlandırma',
  'time_and_cost': 'Zaman ve Maliyet',
  'assets': 'Varlıklar',
  'reliability_dashboard': 'Güvenilirlik Kontrol Paneli',
  'total_maintenance_cost': 'Toplam Bakım Maliyeti',
  'useful_life': 'Faydalı ömür',
  'requests': 'Talepler',
  'locations': 'Konumlar',
  'parts_and_inventory': 'Parçalar/Envanter',
  'purchase_orders': 'Satın alma siparişleri',
  'meters': 'Ölçerler',
  'people_teams': 'İnsanlar & Ekipler',
  'people': 'İnsanlar',
  'teams': 'Ekipler',
  'vendors_customers': 'Satıcılar',
  'vendors': 'Satıcılar',
  'categories': 'Kategoriler',
  'settings': 'Ayarlar',
  'cancelled': 'İptal edildi',
  'reject': 'Reddet',
  'approve': 'Onayla',
  'request_create_success': 'İş Emri başarıyla talep edildi',
  'request_create_failure': 'İş Talebi oluşturulamadı',
  'request_edit_failure': 'Talep düzenlenemedi',
  'request_delete_success': 'Talep başarıyla silindi',
  'request_delete_failure': 'Talep silinemedi',
  'rejected': 'Reddedilmiş',
  'approved': 'Onaylı',
  'pending': 'Askıda',
  'required_request_name': 'Talep başlığı gerekli',
  'required_field': 'Bu alan gereklidir',
  'add_request': 'Talep Ekle',
  'add_request_description': 'Yeni bir talep oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'edit_request': 'İsteği Düzenle',
  'edit_request_description': 'Talebi düzenlemek için aşağıdaki alanları doldurun',
  'noRows.request.message': 'İş taleplerinizi Yönetin',
  'noRows.request.action': 'İş talebi oluşturmak için \'\' butonuna basın',
  'confirm_delete_request': 'Bu talebini silmek istediğinizden emin misiniz?',
  'request': 'Rica et',
  'request_details': 'Talep detayları',
  'none_priority': 'Hiçbiri',
  'low_priority': 'Düşük',
  'medium_priority': 'Orta',
  'high_priority': 'Yüksek',
  'downtimes': 'Arıza süreleri',
  'confirm_delete_file_asset': 'Bu dosyayı bu varlıktan kaldırmak istediğinizden emin misiniz?',
  'actions': 'İşlemler',
  'show': 'Göster',
  'model': 'Model',
  'serial_number': 'Seri numarası',
  'operational': 'Operasyonel',
  'down': 'Aşağı',
  'area': 'Alan',
  'barcode': 'Barkod',
  'placed_in_service': 'Hizmete Yerleştirildi',
  'warranty_expiration': 'Garantinin sona ermesi',
  'asset_information': 'Varlık Bilgileri',
  'more_informations': 'Daha fazla bilgi',
  'confirm_delete_asset_downtime': 'Bu Kesintiyi kaldırmak istediğinizden emin misiniz?',
  'create_downtime_success': 'Kesinti Süresi başarıyla eklendi',
  'create_downtime_failure': 'Kesinti Süresi eklenemedi',
  'edit_downtime_success': 'Kesinti Süresi başarıyla düzenlendi',
  'started_on': 'Başladı',
  'required_startsOn': 'Başlangıç ​​tarihi gerekli',
  'reading_create_success': 'Okuma başarıyla eklendi',
  'reading_create_failure': 'Okuma eklenemedi',
  'reading': 'Okuma',
  'added_by': 'Tarafından eklendi',
  'enter_meter_value': 'Ölçer değerini girin',
  'add_reading': 'Okuma Ekle',
  'select_meter': 'Ölçer Seçin',
  'confirm_delete_part_asset': 'Bu Parçayı bu varlıktan kaldırmak istediğinizden emin misiniz?',
  'no_due_date': 'Son tarih yok',
  'due_at_date': 'Bitiş Tarihi{{date}}',
  'no_primary_worker': 'Birincil Çalışan yok',
  'no_wo_linked_asset': 'Bu Varlığa bağlı İş Emri yok',
  'asset_remove_success': 'Varlık başarıyla silindi',
  'asset_remove_failure': 'Varlık silinemedi',
  'asset_name_description': 'Varlık adını girin',
  'select_asset_location': 'Varlık konumunu seçin',
  'primary_user_description': 'Birincil kullanıcıyı seçin',
  'teams_description': 'Ekipleri seçin',
  'customers_description': 'Yüklenicileri seçin',
  'vendors_description': 'Satıcıları seçin',
  'inServiceDate_description': 'Hizmete girme tarihi',
  'warranty_expiration_date': 'Garanti süresinin sona erme tarihi',
  'additional_information': 'Ek Bilgiler',
  'structure': 'Yapı',
  'parent_asset': 'Ana Varlık',
  'asset_create_success': 'Varlık başarıyla oluşturuldu',
  'asset_create_failure': 'Varlık oluşturulamadı',
  'additional_workers_description': 'Ek çalışanları seçin',
  'required_asset_name': 'Varlık adı gerekli',
  'add_asset': 'Varlık Ekle',
  'add_asset_description': 'Yeni bir varlık oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'create_asset': 'Varlık Oluştur',
  'hierarchy': 'Hiyerarşi',
  'add_downtime': 'Kesinti Ekle',
  'edit_downtime': 'Kesinti Süresini Düzenle',
  'remove_downtime': 'Kesinti Süresini Kaldır',
  'edit': 'Düzenle',
  'edit_asset': 'Varlığı Düzenle',
  'edit_asset_description': 'Bu varlığı düzenlemek için aşağıdaki alanları doldurun',
  'confirm_delete_asset': 'Bu varlığı silmek istediğinizden emin misiniz?',
  'loading_assets': '{{name}} #{{id}} altındaki varlıklar yükleniyor',
  'no_content': 'İçerik yok',
  'no_result_criteria': 'Arama kriterlerinizle eşleşen sonuç yok',
  'map_view': 'Harita Görünümü',
  'location_create_success': 'Konum başarıyla oluşturuldu',
  'location_create_failure': 'Konum oluşturulamadı',
  'location_edit_failure': 'Konum düzenlenemedi',
  'location_delete_success': 'Konum başarıyla silindi',
  'location_delete_failure': 'Konum silinemedi',
  'enter_location_name': 'Konum adını girin',
  'address': 'Adres',
  'parent_location': 'Ana Konumu',
  'select_workers': 'İşçileri Seçin',
  'map_coordinates': 'Harita Koordinatları',
  'required_location_name': 'Konum başlığı gerekli',
  'required_location_address': 'Konum adresi gerekli',
  'add_location': 'Konum ekle',
  'add_location_description': 'Yeni bir konum oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'edit_location': 'Konumunu düzenle',
  'edit_location_description': 'Konumu güncellemek için aşağıdaki alanları doldurun',
  'confirm_delete_location': 'Bu Konumu silmek istediğinizden emin misiniz?',
  'floor_plans': 'Kat planları',
  'floor_plan': 'Kat planı',
  'floor_plan_name_description': 'Kat planı adı',
  'Floor plan area in m²': 'm² cinsinden kat planı alanı',
  'upload_image': 'Bir görüntü yükle',
  'required_floor_plan_name': 'Kat planı adı gerekli',
  'add_floor_plan': 'Yeni Kat Planı ekle',
  'add_floor_plan_description': 'Yeni bir Kat Planı oluşturmak için aşağıdaki alanları doldurun',
  'no_asset_in_location': 'Bu konumda varlık yok',
  'no_wo_in_location': 'Bu lokasyonda İş Emri yok',
  'no_file_in_location': 'Bu konuma eklenmiş dosya yok',
  'no_floor_plan_in_location': 'Bu konumda Kat Planı yok',
  'assigned_teams': 'Atanan Ekipler',
  'assigned_customers': 'Atanan Yükleniciler',
  'assigned_vendors': 'Atanan Satıcılar',
  'no_access_location': 'Konumlara erişiminiz yok. ',
  'no_access_vendors_customers': 'Satıcılara ve Yüklenicilere erişiminiz yok. ',
  'no_access_people_team': 'Kişiler ve Ekipler\'e erişiminiz yok. ',
  'no_access_analytics': 'Analizlere erişiminiz yok. ',
  'no_access_assets': 'Varlıklara erişiminiz yok. ',
  'no_access_categories': 'Kategorilere erişiminiz yok. ',
  'no_access_files': 'Dosyalara erişiminiz yok. ',
  'no_access_inventory': 'Envanter\'e erişiminiz yok. ',
  'no_access_meters': 'Meters\'a erişiminiz yok. ',
  'no_access_purchase_orders': 'Satınalma Siparişi oluşturamazsınız. ',
  'no_access_requests': 'Taleplere erişiminiz yok. ',
  'no_access_settings': 'Ayarlara erişiminiz yok. ',
  'no_access_page': 'Bu sayfaya erişiminiz yok',
  'file': 'Dosya',
  'noRows.location.message': 'Konumlar, varlıkları ve çalışanları daha verimli bir şekilde yönetmenize olanak tanır',
  'noRows.location.action': 'Konum oluşturmak için \'\' düğmesine basın',
  'card_view': 'Kart Görünümü',
  'set_create_success': 'Set başarıyla oluşturuldu',
  'set_create_failure': 'Set oluşturulamadı',
  'set_edit_failure': 'Set düzenlenemedi',
  'set_delete_success': 'Set başarıyla silindi',
  'set_delete_failure': 'Set silinemedi',
  'total_cost': 'Toplam tutar',
  'part': 'Parça',
  'enter_part_name': 'Parça adını girin',
  'required_set_name': 'Ayar adı gerekli',
  'add_set': 'Set Ekle',
  'add_set_description': 'Yeni bir Set oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'create_set': 'Set Oluştur',
  'edit_set': 'Seti Düzenle',
  'edit_set_description': 'Seti düzenlemek için aşağıdaki alanları doldurun',
  'confirm_delete_set': 'Bu Seti silmek istediğinizden emin misiniz?',
  'part_create_success': 'Parça başarıyla oluşturuldu',
  'part_create_failure': 'Parça oluşturulamadı',
  'part_edit_failure': 'Bölüm düzenlenemedi',
  'part_delete_success': 'Parça başarıyla silindi',
  'part_delete_failure': 'Parça silinemedi',
  'quantity': 'Miktar',
  'open_wo': 'Açık İş Emirleri',
  'enter_part_category': 'Parça kategorisine girin',
  'enter_part_cost': 'Parça maliyetini girin',
  'enter_part_quantity': 'Parça miktarını girin',
  'minimum_quantity': 'Minimum Adet',
  'enter_part_minimum_quantity': 'Parça minimum miktarını girin',
  'non_stock': 'Stok Dışı',
  'enter_part_barcode': 'Parça Barkodunu Girin',
  'enter_part_area': 'Parça Alanına Girin',
  'additional_part_details': 'Ek Parça Detayları',
  'add_part': 'Parça Ekle',
  'add_part_description': 'Yeni bir Parça oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'create_part': 'Parça Oluştur',
  'update_part': 'Parçayı Güncelle',
  'update_part_description': 'Parçayı güncellemek için aşağıdaki alanları doldurun',
  'noRows.part.message': 'Parçalar varlıklar halinde birleştirilebilir veya tek başına kullanılabilir',
  'noRows.part.action': 'Parça oluşturmak için \'\' düğmesine basın',
  'confirm_delete_part': 'Bu Bölümü silmek istediğinizden emin misiniz?',
  'part_details': 'Parça ayrıntıları',
  'area_details': 'Alan ayrıntıları',
  'assigned_people': 'Atanan kişiler',
  'no_asset_related_part': 'Bu bölümle ilgili varlık yok',
  'no_file_found': 'Dosya bulunamadı',
  'no_wo_found': 'İş Emri bulunamadı',
  'workers': 'İşçiler',
  'Parts_and_Inventory': 'Parçalar',
  'po_edit_failure': 'Satınalma Siparişi düzenlenemedi',
  'po_delete_success': 'Satınalma Siparişi başarıyla silindi',
  'po_delete_failure': 'Satınalma Siparişi silinemedi',
  'number_of_items': 'Öğe sayısı',
  'total_quantity': 'Toplam miktar',
  'shipping_to': 'Nakliye',
  'shipping_address': 'Gönderi Adresi',
  'po_details': 'Satın Alma Siparişi Ayrıntıları',
  'enter_po_name': 'Satınalma Siparişi adını girin',
  'additional_details': 'Ek detaylar',
  'vendor': 'Satıcı',
  'shipping_information': 'Nakliye Bilgisi',
  'company_name': 'Firma Adı',
  'ship_to': 'Alıcı',
  'city': 'Şehir',
  'state': 'Durum',
  'zip_code': 'Posta kodu',
  'fax_number': 'Faks numarası',
  'po_date': 'Satın Alma Siparişi Tarihi',
  'notes': 'Notlar',
  'add_notes': 'Not Ekle',
  'requisitioner': 'Talep sahibi',
  'terms': 'Şartlar',
  'shipping_method': 'Nakliye Yöntemi',
  'required_name': 'Ad gerekli',
  'edit_po': 'Satınalma Siparişini Düzenle',
  'edit_po_description': 'Satınalma Siparişini güncellemek için aşağıdaki alanları doldurun',
  'purchase_order': 'Satın alma emri',
  'noRows.po.message': 'Satınalma Siparişlerinizi tek yerden yönetin',
  'noRows.po.action': 'Satınalma Siparişi oluşturmak için \'\' butonuna basın.',
  'confirm_delete_po': 'Bu Satınalma Siparişini silmek istediğinizden emin misiniz?',
  'upgrade_po': 'Satınalma Siparişleri oluşturmak için yükseltme yapın',
  'new_po': 'Yeni Satınalma Siparişi',
  'po_create_success': 'Satınalma Siparişi başarıyla oluşturuldu',
  'select_one_part': 'En az 1 bölüm seçin ',
  'po_create_failure': 'Satınalma Siparişi oluşturulamadı',
  'approve_while_submitting': 'Gönderirken onayla',
  'invalid_fax': 'Faks numarası geçersiz',
  'submit': 'Gönder',
  'each_item_superior_zero': 'Her Ürün miktarı 0\'dan büyük olmalıdır',
  'quantity_edit_success': 'Miktar başarıyla değiştirildi',
  'quantity_edit_failure': 'Miktar değiştirilemedi',
  'APPROVED': 'Onaylı',
  'PENDING': 'Askıda olması',
  'REJECTED': 'Reddedilmiş',
  'when_reading_is': 'Ölçer Okuma ne zaman',
  'greater_than': 'Şundan büyük:',
  'lower_than': 'Daha düşük',
  'value': 'Değer',
  'required_value': 'Değer gerekli',
  'required_trigger_condition': 'Koşul gerekli',
  'wo_trigger_create_success': 'İş Emri tetikleyicisi başarıyla oluşturuldu',
  'wo_trigger_create_failure': 'İş Emri tetikleyicisi oluşturulamadı',
  'add_wo_trigger': 'İş Emri Tetikleyicisi Ekle',
  'add_wo_trigger_description': 'İş Emri Tetikleyicisi oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'wo_trigger_edit_success': 'İş Emri tetikleyicisi başarıyla güncellendi',
  'meter_create_success': 'Ölçer başarıyla oluşturuldu',
  'meter_create_failure': 'Ölçer oluşturulamadı ',
  'meter_edit_failure': 'Ölçer düzenlenemedi',
  'meter_delete_success': 'Ölçer başarıyla silindi',
  'meter_delete_failure': 'Ölçer silinemedi',
  'next_reading_due': 'Sonraki Okuma Zamanı',
  'unit_of_measurement': 'Ölçü birimi',
  'last_reading': 'Son Okuma',
  'enter_meter_name': 'Ölçer adını girin',
  'unit': 'Birim',
  'update_frequency': 'Güncelleme sıklığı',
  'update_frequency_in_days': 'Gün cinsinden Güncelleme Sıklığı',
  'required_meter_name': 'Ölçer adı gerekli',
  'required_meter_unit': 'Ölçer birimi gerekli',
  'required_meter_update_frequency': 'Ölçer güncelleme sıklığı gerekli',
  'required_asset': 'Varlık gerekli',
  'add_meter': 'Ölçer Ekle',
  'add_meter_description': 'Yeni bir Ölçer oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'edit_meter': 'Sayacı Düzenle',
  'edit_meter_description': 'Sayacı düzenlemek için aşağıdaki alanları doldurun',
  'confirm_delete_meter': 'Bu Sayacı silmek istediğinizden emin misiniz?',
  'upgrade_create_meter': 'Ölçerler oluşturmak için yükseltme yapın',
  'history': 'Tarih',
  'reading_frequency': 'Okuma Frekansı',
  'required_reading_value': 'Okuma değeri gerekli',
  'wo_triggers': 'İş Emri Tetikleyicileri',
  'add_trigger': 'Tetikleyici Ekle',
  'meter_details': 'Ölçer ayrıntıları',
  'noRows.meter.message': 'Ölçer okumaları size varlıkları izleme ve tanımlanmış koşullara göre yeni iş emirlerini tetikleme olanağı sağlar',
  'noRows.meter.action': 'Bir Ölçer oluşturmak için \'\' düğmesine basın',
  'meter': 'Ölçer',
  'drag_one_file': 'Benzersiz bir dosyayı buraya sürükleyin',
  'drag_many_files': 'Sürükle',
  'drop_to_start': 'Yüklemeye başlamak için dosyaları bırakın',
  'invalid_files_type': 'Bu dosya türlerini yükleyemezsiniz',
  'you_have_uploaded': 'Yüklediniz',
  'upload': 'Yükleme',
  'Vendors_Customers': 'Satıcılar',
  'customer_create_success': 'Yüklenici başarıyla oluşturuldu',
  'customer_create_failure': 'Yüklenici oluşturulamadı',
  'customer_edit_failure': 'Yüklenici düzenlenemedi',
  'customer_delete_success': 'Yüklenici başarıyla silindi',
  'customer_delete_failure': 'Yüklenici silinemedi',
  'customer_name': 'Yüklenici adı',
  'website': 'İnternet sitesi',
  'customer_type': 'Yüklenici türü',
  'customer_type_description': 'Ör. Sucu, Elektrikci ',
  'customer_description_description': 'Bu yüklenicinin amacını birkaç satırla anlatın...',
  'billing_information': 'Fatura bilgileri',
  'address_line_2': 'Adres Satırı 2',
  'billing_name': 'Fatura adı',
  'currency': 'Para birimi',
  'select_currency': 'Para birimini seçin',
  'required_customer_name': 'Yüklenici Adı gerekli',
  'required_phone': 'Telefon numarası gerekli',
  'invalid_website': 'Geçersiz web sitesi',
  'billing_address': 'Fatura Adresi',
  'add_customer': 'Yüklenici Ekle',
  'add_customer_description': 'Yeni bir yüklenici oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'noRows.customer.message': 'Yükleniciler dışarıdan çalışanlardır',
  'noRows.customer.action': 'Yüklenici oluşturmak için \'\' butonuna basın',
  'type': 'Tip',
  'billing_currency': 'Faturalandırma Para Birimi',
  'go_back': 'Geri dön',
  'confirm_delete_customer': 'Bu Yükleniciyi silmek istediğinizden emin misiniz?',
  'vendor_create_success': 'Satıcı başarıyla oluşturuldu',
  'vendor_create_failure': 'Satıcı oluşturulamadı',
  'vendor_edit_failure': 'Satıcı düzenlenemedi',
  'vendor_delete_success': 'Satıcı başarıyla silindi',
  'vendor_delete_failure': 'Satıcı silinemedi',
  'required_company_name': 'Şirket Adı gerekli',
  'vendor_type': 'Satıcı Türü',
  'contact_name': 'Kişi adı',
  'add_vendor': 'Satıcı ekle',
  'add_vendor_description': 'Yeni bir satıcı oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'confirm_delete_vendor': 'Bu Satıcıyı silmek istediğinizden emin misiniz?',
  'noRows.vendor.message': 'Satıcılar Varlık veya Parça sağlayan kuruluşlardır',
  'noRows.vendor.action': 'Satıcı oluşturmak için \'\' düğmesine basın',
  'vendor_type_description': 'Ör. Sucu, Elektrikci ',
  'role': 'Rol',
  'edit_user': 'Kullanıcıyı düzenle',
  'edit_user_description': 'Kullanıcıyı düzenlemek için aşağıdaki alanları doldurun',
  'job_title': 'İş unvanı',
  'invite_users': 'Kullanıcıları Davet Et',
  'bring_people_team': 'Ekibe yeni insanlar getirin',
  'add_20_users': 'Her e-posta girişinden sonra \'sekme\' veya \'enter\' tuşuna basarak aynı anda 20 kullanıcı ekleyebilirsiniz. ',
  'enter_email': 'Email adresini gir',
  'users_invite_success': 'Kullanıcılar davet edildi',
  'users_invite_failure': 'Kullanıcılar davet edilemez. ',
  'please_type_emails': 'Lütfen davet etmek için e-postaları yazın',
  'please_select_role': 'Lütfen bir rol seçin',
  'invite': 'Davet et',
  'team_create_success': 'Ekip başarıyla oluşturuldu',
  'team_create_failure': 'Ekip oluşturulamadı',
  'team_edit_failure': 'Ekip düzenlenemedi',
  'team_delete_success': 'Ekip başarıyla silindi',
  'team_delete_failure': 'Ekip silinemedi',
  'team_name': 'Ekip adı',
  'people_in_team': 'Ekipdeki insanlar',
  'required_team_name': 'Ekip Adı gerekli',
  'create_team': 'Ekip oluştur',
  'create_team_description': 'Ekip üyelerini buradan ekleyebilirsiniz',
  'confirm_delete_team': 'Bu Ekibi silmek istediğinizden emin misiniz?',
  'overview': 'Genel Bakış',
  'activity': 'Aktivite',
  'wo_complete_last_14_days': 'Son 14 günde tamamlanan iş emirleri',
  'no_wo_complete_last_14_days': 'Son iki hafta içinde herhangi bir iş emrini tamamlamadınız',
  'ADMIN_name': 'Yönetici',
  'ADMIN_description': 'Yöneticinin tam erişimi vardır; ',
  'LIMITED_ADMIN_name': 'Sınırlı Yönetici',
  'LIMITED_ADMIN_description': 'Sınırlı yöneticiler, ayarları görüntüleyememeleri/düzenleyememeleri veya kişi ve ekipleri ekleyememeleri/düzenleyememeleri dışında yöneticiyle aynı erişime sahiptir. ',
  'TECHNICIAN_name': 'Teknisyen',
  'TECHNICIAN_description': 'Teknisyenler iş emirleri, varlıklar ve konumlar oluşturabilir ve kapatabilir. ',
  'LIMITED_TECHNICIAN_name': 'Sınırlı Teknisyen',
  'LIMITED_TECHNICIAN_description': 'Sınırlı teknisyenler yalnızca kendilerine atanan iş emirlerini görebilir',
  'VIEW_ONLY_name': 'Sadece Görüntüle',
  'VIEW_ONLY_description': 'Yalnızca görüntüleme kullanıcıları tam görüntüleme erişimine sahiptir ancak hiçbir şeyi düzenleyemezler',
  'REQUESTER_name': 'Talep eden',
  'REQUESTER_description': 'Talepte bulunanlar yalnızca iş isteklerini gönderebilir ve durumlarını görüntüleyebilir',
  'select_user_role': 'Kullanıcı rolünü seçin',
  'noRows.team.message': 'Ekipler belirli insan gruplarını yönetmenize yardımcı olur.',
  'noRows.team.action': 'Ekip oluşturmak için \'\' butonuna basın.',
  'user_details': 'Kullanıcı detayları',
  'file_delete_success': 'Dosya başarıyla silindi',
  'file_delete_failure': 'Dosya silinemedi',
  'uploaded_by': 'Yükleyen:',
  'uploaded_on': 'Yüklenme Tarihi',
  'rename': 'Yeniden isimlendir',
  'required_files': 'Lütfen en az bir dosya yükleyin',
  'required_file_name': 'Lütfen bir dosya adı girin',
  'add_files': 'Dosya Ekle',
  'edit_file': 'Dosya Düzenle',
  'noRows.file.message': 'Tüm dosyaları Tek bir yerde toplayın',
  'noRows.file.action': 'Dosya yüklemek için \'\' butonuna basın',
  'upgrade_files': 'Varlıklar, İş Emirleri, Konumlar vb. dosyaları kullanmak için yükseltme yapın.',
  'parts_consumption': 'Parça Tüketimi',
  'requests_analysis': 'Talep Analizi',
  'category_create_success': 'Kategori başarıyla oluşturuldu',
  'category_create_failure': 'Kategori oluşturulamadı',
  'category_edit_failure': 'Kategori düzenlenemedi',
  'category_delete_success': 'Kategori başarıyla silindi',
  'category_delete_failure': 'Kategori silinemedi',
  'timers': 'Zamanlayıcılar',
  'costs': 'Maliyetler',
  'add_category': 'Yeni Kategori Ekle',
  'add_category_description': 'Yeni bir kategori oluşturmak ve eklemek için adı girin',
  'edit_category': 'Kategoriyi Düzenle',
  'edit_category_description': 'Kategoriyi düzenlemek için adı girin',
  'no_category_action': 'İlk kategorinizi eklemek için \'\' butonuna basın.',
  'confirm_delete_category': 'Bu Kategoriyi silmek istediğinizden emin misiniz?',
  'no_category_message': 'Görünüşe göre sende henüz hiç {{categoryName}} kategorisi yok.',
  'acquisition_cost': 'Elde etme maliyeti',
  'general_settings': 'Genel Ayarlar',
  'request_form_configuration': 'Talep formu yapılandırması',
  'roles': 'Roller',
  'checklists': 'Kontrol listeleri',
  'field_configuration_description': 'Alanları İsteğe Bağlı, Gizli veya Zorunlu olarak işaretleyebilirsiniz.',
  'optional': 'İsteğe bağlı',
  'required': 'Gerekli',
  'hidden': 'Gizlenmiş',
  'creating_wo': 'İş Emri Oluşturma',
  'completing_wo': 'Bir İş Emrinin Tamamlanması',
  'role_edit_failure': 'Rol düzenlenemedi',
  'edit_role': 'Rolü düzenle',
  'edit_role_description': 'Rolü düzenlemek için aşağıdaki alanları doldurun',
  'external_id': 'Harici Kimlik',
  'permissions': 'İzinler',
  'create_role_description': 'Bu rol, bir Yöneticinin Atlas\'ta yapabileceği her şeyi yapabilir ancak bazı önemli izinleri aşağıda özelleştirebilirsiniz.',
  'create_and_edit': 'Oluştur/Düzenle',
  'to_access': 'Erişim',
  'role_delete_success': 'Rol başarıyla silindi',
  'role_delete_failure': 'Rol silinemedi',
  'confirm_delete_role': 'Bu rolü kalıcı olarak silmek istediğinizden emin misiniz?',
  'users': 'Kullanıcılar',
  'paid': 'Ücretli',
  'free': 'Özgür',
  'role_create_success': 'Rol başarıyla oluşturuldu',
  'role_create_failure': 'Rol oluşturulamadı',
  'roles_management': 'Roller Yönetimi',
  'roles_number': '{{count}} roller',
  'create_role': 'Rol Oluştur',
  'upgrade_role': 'Rol oluşturmak için yükseltme yapın',
  'add_role': 'Yeni rol ekle',
  'add_role_description': 'Yeni bir rol oluşturmak ve eklemek için aşağıdaki alanları doldurun',
  'parts_and_sets': 'Parçalar',
  'upgrade_role_edit': 'Rolü düzenlemek için yükseltme yapın',
  'delete_role': 'Rolü Sil',
  'upgrade_role_delete': 'Rolü silmek için yükseltme yapın',
  'create': 'Oluştur',
  'pm_trigger': 'Önleyici Bakım Tetikleyicisi',
  'auto_assign_wo': 'İş Emirlerini Otomatik Ata',
  'auto_assign_wo_description': 'Yeni iş emirlerini onları oluşturan kişiye otomatik olarak atayın',
  'auto_assign_requests': 'Talepleri otomatik atama',
  'auto_assign_requests_description': 'Talebi onaylayan kişiye otomatik olarak yeni iş emirleri atayın',
  'disable_closed_wo_notification': 'Kapatılan İş Emri bildirimlerini devre dışı bırak',
  'disable_closed_wo_notification_description': 'Kapatılan İş Emirleri güncellendiğinde bildirimleri devre dışı bırak',
  'ask_feedback_wo_closed': 'İş Emri kapatıldığında geri bildirim isteyin',
  'ask_feedback_wo_closed_description': 'Kullanıcılardan yapılan iş hakkında geri bildirimde bulunmaları istenir',
  'include_labor_in_total_cost': 'İşçilik maliyetini toplam maliyete dahil edin',
  'include_labor_in_total_cost_description': 'Bir kullanıcı zamanı günlüğe kaydettiğinde ve saatlik ücret kayıtlı olduğunda işçilik maliyetlerini toplama ekleyin',
  'enable_wo_updates_requesters': 'Talepte Bulunanlar için iş emri güncellemelerini etkinleştirin',
  'enable_wo_updates_requesters_description': 'Kullanıcılar talep ettikleri iş emirlerine ilişkin güncellemeleri alır',
  'language': 'Dil',
  'date_format': 'Tarih formatı',
  'business_type': 'İş Tipi',
  'general_asset_management': 'Genel varlık yönetimi',
  'physical_asset_management': 'Fiziksel varlık yönetimi',
  'checklist_delete_success': 'Kontrol Listesi başarıyla silindi',
  'checklist_delete_failure': 'Kontrol Listesi silinemedi',
  'create_checklist': 'Kontrol Listesi Oluştur',
  'confirm_delete_checklist': 'Bu Kontrol Listesini silmek istediğinizden emin misiniz?',
  'upgrade_checklist': 'Kontrol Listelerini kullanmak için lütfen yükseltme yapın',
  'upgrade_now': 'Hemen Yükselt',
  'total': 'Toplam',
  'sub_task_status': 'Alt Görev Durumu',
  'text_field': 'Metin alanı',
  'number_field': 'Sayı Alanı',
  'inspection_check': 'Muayene Kontrolü',
  'multiple_choices': 'Çoklu seçimler',
  'meter_reading': 'Ölçer okuma',
  'select_user': 'Kullanıcı seç',
  'PASS': 'Atla',
  'FLAG': 'Bayrak',
  'FAIL': 'Hata',
  'enter_task_name': 'Bir görev adı girin',
  'see_details': 'Ayrıntılara bakınız',
  'concerned_asset': 'İlgili Varlık',
  'me': 'Ben',
  'remove_blank_tasks': 'Boş görevleri kaldırın',
  'remove_blank_options': 'Boş seçenekleri kaldır',
  'preview': 'Ön izleme',
  'edit_checklist': 'Kontrol Listesini Düzenle',
  'add_tasks': 'Görev Ekle',
  'use_a_checklist': 'Bir kontrol listesi kullanın',
  'select_checklist': 'Kontrol Listesini Seçin',
  'save_checklist': 'Kontrol Listesini Kaydet',
  'assign_tasks_description': 'Teknisyenlerin doldurması için Özel Görevler atayın',
  'select_date': 'Tarih seç',
  'task': 'Görev',
  'checklist': 'Kontrol listesi',
  'upgrade_analytics': 'Analizleri görmek için yükseltme yapın',
  'grouped_by_category': 'Kategoriye göre gruplandırılmış',
  'downtime_by_asset': 'Varlığa Göre Kesinti Süresi',
  'downtime_and_costs': 'Arıza Süresi ve Maliyetler',
  'downtime_and_costs_trends': 'Arıza Süresi ve Maliyet trendleri',
  'downtimes_trends': 'Kesinti süreleri trendleri',
  'repair_time_by_asset': 'Varlığa Göre Onarım Süresi',
  'consumed_parts_costs': 'Tüketilen Parça Maliyetleri',
  'requests_by_priority': 'Önceliğe Göre Talepler',
  'requests_trends': 'Talep trendleri',
  'time_spent': 'Harcanan zaman',
  'grouped_by_completed_by': 'Tamamlayana göre gruplandırılmış',
  'grouped_by_assigned_to': 'Atanan Kişiye Göre Gruplandırılmış',
  'grouped_by_priority': 'Önceliğe göre gruplandırılmış',
  'completion_comparison': 'Tamamlama karşılaştırması',
  'hours_worked': 'Çalışılan saatler',
  'work_remaining': 'Kalan İş',
  'wo_status': 'İş Emri Durumu',
  'cost_trends': 'Maliyet eğilimleri',
  'cost_center': 'Varlık Yaşam Döngüsü Maliyet Analizi',
  'downtime_and_availability': 'Kesinti süresi ve kullanılabilirlik',
  'incomplete_wo': 'Tamamlanmamış İş Emirleri',
  'the_numbers': 'Sayılar',
  'hours_and_cost_by_asset': 'Varlığa Göre Saat ve Maliyet',
  'completion_comparison_description': 'Çeşitli türdeki tamamlanan iş emirlerinin karşılaştırılması.',
  'wo_age_description': 'İş emri yaşı, iş emrinin (veya iş talebinin) oluşturulması ile tamamlanması arasındaki gün sayısı olarak tanımlanır.',
  'rav_description': 'RAV\'ın yüzdesi olarak toplam maliyet (varlıktaki iş emirlerinin maliyeti / satın alma fiyatı)*100\'e eşittir. ',
  'downtime_and_availability_description': 'Kullanılabilirlik, varlıkların hizmete sunulma tarihinden bu yana çalışır durumda olma yüzdesini ifade eder. ',
  'total_cost_description': 'Toplam maliyet, bir iş emrindeki işçilik maliyetini, ek maliyeti ve parça maliyetini içerir',
  'compliant_wo_description': 'Uyumlu iş emirleri, vade tarihinden önce tamamlanan iş emirleri olarak tanımlanır. ',
  'hours_worked_description': 'Bu saatler, son tarihi filtrelerde belirtilen aralıkta olan iş emirlerine karşılık gelir.',
  'work_remaining_description': 'Bu grafik, filtrelerde belirtilen tarih aralığında teslim edilmesi gereken tamamlanmamış iş emirlerinin sayısını gösterir. ',
  'total_downtime_in_hours': 'Toplam Kesinti Süresi (saat)',
  'downtime_events': 'Kesinti Olayları',
  'percent_downtime': 'Kesinti %\'si',
  'meantime_between_failures_in_hours': 'Arızalar arasındaki ortalama süre (saat)',
  'availability_percent': 'Kullanılabilirlik yüzdesi',
  'mean_time_to_repair_in_hours': 'Ortalama Onarım Süresi (saat)',
  'average_age': 'Ortalama yaş',
  'count': 'Sayı',
  'average_cost': 'Ortalama tutar',
  'compliant': 'Uysal',
  'average_cycle_time_in_days': 'Ortalama Çevrim Süresi (Gün)',
  'total_consumption_cost': 'Toplam Tüketim Maliyeti',
  'parts_consumed': 'Tüketilen Parçalar',
  'total_cost_as_pct_rav': 'RAV\'ın yüzdesi olarak toplam maliyet',
  'approved_requests': 'Onaylanan Talepler',
  'pending_requests': 'bekleyen istekler',
  'cancelled_requests': 'İptal Edilen Talepler',
  'average_cycle_time': 'Ortalama Çevrim Süresi',
  'compliance_rate': 'Uyum oranı',
  'complete': 'Tamamla',
  'reactive': 'Reaktif',
  'recurring': 'Tekrarlanan',
  'part_costs': 'Parça Maliyetleri',
  'labor_costs': 'İşgücü maliyeti',
  'total_time_spent_in_hours': 'Harcanan toplam süre (Saat)',
  'estimated_hours': 'Tahmini Saatler',
  'total_purchase_price': 'Toplam Satın Alma Fiyatı',
  'you_have': 'Var',
  'new_messages': 'yeni mesajlar',
  'company': 'Şirket',
  'change_cover': 'Kapağı değiştir',
  'required_address': 'Adres alanı zorunludur',
  'company_details': 'Şirket Detayları',
  'company_details_description': 'Şirketiniz ile ilgili bilgileri yönetin',
  'learn_more': 'Daha fazla bilgi edin',
  'profile': 'Profil',
  'email_notifications': 'E-posta Bildirimleri',
  'email_updates_wo': 'İş Emirleri ve Mesajlar için E-posta Güncellemeleri',
  'email_updates_requests': 'Talep Edilen İş Emirleri için E-posta Güncellemeleri',
  'po_emails': 'Satın Alma Siparişi E-postaları',
  'required_job_title': 'İş unvanı alanı zorunludur',
  'required_old_password': 'Lütfen mevcut şifreyi girin.',
  'invalid_password': 'Şifre çok kısa; minimum 8 karakter olmalıdır.',
  'required_new_password': 'Şifre sağlanmadı.',
  'passwords_must_match': 'Şifreler aynı olmalıdır',
  'password_change_success': 'parola başarıyla değiştirildi',
  'wrong_password': 'Yanlış şifre girildi',
  'current_password': 'Mevcut Şifre',
  'new_password': 'Yeni Şifre',
  'confirm_password': 'Şifreyi Onayla',
  'personal_details': 'Kişisel detaylar',
  'personal_details_description': 'Kişisel bilgilerinizle ilgili bilgileri yönetin',
  'change_password': 'Şifre değiştir',
  'notification_settings': 'Bildirim Ayarları',
  'notification_settings_description': 'Bildirimlerinizle ilgili ayrıntıları yönetin',
  'recent_activity': 'Son Etkinlik',
  'created': 'Oluşturuldu',
  'completed': 'Tamamlanmış',
  'my_account': 'Hesabım',
  'my_company': 'Benim şirketim',
  'you_are_using_plan': 'Şu anda {{planName}} planını kullanıyorsunuz. Süresi {{expiration}} tarihinde sona erecek. Daha fazla özelliğe erişmek için şimdi yükseltin. ',
  'upgrade_plan': 'Yükseltme Planı',
  'plans': 'Planlar',
  'monthly': 'Aylık',
  'annually': 'Yıllık',
  'subscription_change_success': 'Abonelik başarıyla değiştirildi',
  'invalid_credit_card': 'Kredi kartı numarası geçerli değil',
  'required_credit_card': 'Kart alanı zorunludur',
  'invalid_expiration_month': 'Son kullanma ayı geçersiz',
  'required_expiration_month': 'Son kullanma ayı gerekli',
  'invalid_expiration_year': 'Son kullanma yılı geçersiz',
  'required_expiration_year': 'Son kullanma yılı gereklidir',
  'invalid_cvv': 'CVV geçersiz',
  'required_cvv': 'CVV gereklidir',
  'required_cardholder_name': 'Kart sahibinin adı gerekli',
  'card': 'Kart',
  'expiration_month': 'Son Kullanma Ayı',
  'expiration_year': 'Son Kullanma Yılı',
  'cardholder_name': 'Kart Sahibinin Adı',
  'seats': 'Koltuklar',
  'cost_per_seat': 'Koltuk başına maliyet',
  'your_payment_secure': 'Ödeme verileriniz şifrelenir ve güvenlidir.',
  'plan': 'Plan',
  'current_plan': 'Mevcut Plan',
  'number_users_who_will_use_grash': 'Atlas\'ı kullanacak kullanıcı sayısı',
  'admin_role': 'Yönetici',
  'technical_role': 'Teknik',
  'limited_technical_role': 'Sınırlı Teknik',
  'requester_role': 'Talep eden',
  'view_only_role': 'Sadece Görüntüle',
  'how_will_you_be_billed': 'Nasıl faturalandırılmak istersiniz?',
  'which_plan_fits_you': 'Hangi plan size en uygun?',
  'per_user_month': 'kullanıcı başına/ay',
  'per_user_year': 'kullanıcı başına/yıl',
  'you_will_be_charged': 'Sizden ücret alınacak',
  'proceed_to_payment': 'Ödemeye Devam Edin',
  'pay_only_for_roles': 'Yalnızca şunun için ödeme yapın: <strong>Yönetici</strong>, <strong>Teknik</strong> Ve <strong>Sınırlı Teknik</strong> kullanıcılar ve sınırsız kullanım <strong>Talep eden</strong>, <strong>Sadece Görüntüle</strong> ücretsiz',
  'checkout_our': 'Bizim göz atın',
  'pricing_page': 'Fiyatlandırma Sayfası',
  'for_more_details': 'daha fazla ayrıntı için',
  'users_count_display': '{{count}} Kullanıcılar',
  'monthly_adverb': 'aylık',
  'yearly_adverb': 'yıllık',
  'features': 'Özellikler',
  'checkout': 'Çıkış yap',
  'checkout_description': 'Aşağıdaki alanları doldurun',
  'per_month': 'her ay',
  'per_year': 'yıl başına',
  'recover_password_description': 'Şifrenizi sıfırlamak için kayıt için kullanılan e-postayı girin.',
  'send_me_new_password': 'bana yeni bir şifre gönder',
  'want_to_sign_in_again': 'Tekrar oturum açmayı denemek ister misiniz?',
  'check_mails_for_instructions': 'Daha ayrıntılı talimatlar için e-posta kontrol edin',
  'pdf_report': 'PDF Raporu',
  'HIGH': 'Yüksek',
  'MEDIUM': 'Orta',
  'LOW': 'Düşük',
  'NONE': 'Hiçbiri',
  'import': 'İçe aktar',
  'start_import_process': 'İçe Aktarma İşlemini Başlat',
  'import_data': 'Verileri İçe Aktar',
  'set_header': 'Başlığı Ayarla',
  'match_columns': 'Sütunları Eşleştir',
  'review': 'Gözden geçir',
  'done': 'Tamamlandı',
  'no_match_yet': 'Henüz Eşleşen Yok',
  'select': 'Seçme...',
  'matched_to_field': 'Şununla eşleşti: {{field}}',
  'next': 'Sonraki',
  'not_enough_rows': 'Dosyada yeterli satır yok',
  'there_are_duplicates': 'Kopyalar var: {{duplicates}}',
  'assigned_by': 'Atayan',
  'percent_rows_have_value': 'Satırların %{{percent}} kadarı değere sahip',
  'match_at_least_column': 'En az bir sütunla eşleşin',
  'to_import': 'İçe aktar',
  'required_match': '{{field}} eşleşmesi gerekli',
  'archived': 'Arşivlendi',
  'import_wo_success': '{{created}} İş Emirleri oluşturuldu ve {{updated}} Güncellendi',
  'to_export': 'Dışa aktar',
  'filters': 'Filtreler',
  'more_filters': 'Daha Fazla Filtre',
  'customer': 'Müteahhit',
  'to': 'ile',
  'dates': 'Tarih',
  'start': 'Başlangıç',
  'end': 'Son',
  'longitude': 'Boylam',
  'latitude': 'Enlem',
  'ALL': 'Tüm',
  'REACTIVE': 'Reaktif',
  'REPEATING': 'Önleyici',
  'import_location_success': '{{created}} Konumlar oluşturuldu ve {{updated}} Güncellendi',
  'import_part_success': '{{created}} Parçalar oluşturuldu ve {{updated}} Güncellendi',
  'import_meter_success': '{{created}} Ölçerler oluşturuldu ve {{updated}} Güncellendi',
  'import_asset_success': '{{created}} Varlıklar oluşturuldu ve {{updated}} Güncellendi',
  'wo_calendar': 'İş Emirleri Takvimi',
  'month': 'Ay',
  'week': 'Hafta',
  'day': 'Gün',
  'agenda': 'Gündem',
  'previous': 'Önceki',
  'today': 'Bugün',
  'to_filter': 'Filtrele',
  'noRows.asset.message': 'Varlıklar şirketinizin müdahale edebileceği kaynaklardır',
  'noRows.asset.action': 'Yeni bir Varlık oluşturmak için \'\' düğmesine basın.',
  'reset_filters': 'Filtreleri Sıfırla',
  'toggle_menu': 'Menüyü Değiştir',
  'no_results': 'Sonuç bulunamadı',
  'an_error_occured': 'Bir hata oluştu.',
  'density': 'Yoğunluk',
  'compact': 'Kompakt',
  'standard': 'Standart',
  'comfortable': 'Rahat',
  'columns': 'Sütunlar',
  'select_columns': 'Sütunları seçin',
  'show_filters': 'Filtreleri göster',
  'hide_filters': 'Filtreleri gizle',
  'search_placeholder': 'Aramak…',
  'search': 'Ara',
  'clear': 'Temizle',
  'download_as_csv': 'CSV olarak indir',
  'print': 'Yazdır',
  'download_as_excel': 'Excel olarak indir',
  'find_column': 'Sütun bul',
  'column_title': 'Sütun başlığı',
  'reorder_column': 'Sütunu yeniden sırala',
  'show_all': 'Hepsini Göster ↓',
  'hide_all': 'Hepsini sakla',
  'add_filter': 'Filtre ekle',
  'logic_operator': 'Mantık operatörü',
  'operator': 'Şebeke',
  'and': 'Ve',
  'or': 'Veya',
  'filter_value': 'Filtre değeri',
  'contains': 'içerir',
  'equals': 'eşittir',
  'starts_with': 'ile başlar',
  'ends_with': 'ile biter',
  'is': 'dır-dir',
  'is_not': 'değil',
  'is_after': 'sonra',
  'is_on_or_after': 'tarihinde veya sonrasında',
  'is_before': 'önce',
  'is_on_or_before': 'tarihinde veya öncesinde',
  'is_empty': 'boş',
  'is_not_empty': 'boş değil',
  'is_any_of': 'herhangi biri',
  'any': 'herhangi',
  'true_text': 'Doğru',
  'false_text': 'Yanlış',
  'menu': 'Menü',
  'show_columns': 'Sütunları göster',
  'hide': 'Sakla',
  'unsort': 'Sıralamayı kaldır',
  'sort_by_ASC': 'Artana göre sırala',
  'sort_by_DESC': 'Azalana göre sırala',
  'sort': 'Sırala',
  'total_rows': 'Toplam Satır:',
  'yes': 'Evet',
  'no': 'Hayır',
  'more': 'Daha',
  'to_group': 'Grupla',
  'see_children': 'alt öğeleri göster',
  'hide_children': 'alt öğeleri gizle',
  'expand': 'Büyüt',
  'collapse': 'Küçült',
  'detail_panel_toggle': 'Ayrıntı paneli geçişi',
  'cancel_subscription': 'Aboneliği iptal et',
  'resume_subscription': 'Aboneliği Devam Ettir',
  'confirm_cancel_subscription': 'Aboneliği iptal etmek istediğinizden emin misiniz?',
  'workflows': 'İş akışları',
  'create_workflow': 'İş Akışı Oluştur',
  'if': 'Eğer',
  'and_optional': 'Ve(İsteğe bağlı)',
  'then': 'Daha sonra',
  'workflow_description': 'Eğer, Ve, Sonra kadar kolay özel iş akışları oluşturun. ',
  'CREATE_PURCHASE_ORDER': 'Satın Alma Siparişi Oluşturun',
  'PART_IS': 'Bölüm:',
  'QUANTITY_INFERIOR': 'Miktar daha düşük',
  'ASSIGN_VENDOR': 'Satıcıyı atayın',
  'ASSIGN_CATEGORY': 'Kategoriyi atayın',
  'APPROVE': 'Onayla',
  'REJECT': 'Reddet',
  'SEND_REMINDER_EMAIL': 'Hatırlatma e-postası gönder',
  'VENDOR_IS': 'Satıcısı',
  'CATEGORY_IS': 'Kategorisi',
  'STATUS_IS': 'Durumu',
  'DUE_DATE_BETWEEN': 'Vade tarihi şu tarihler arasında:',
  'DUE_DATE_AFTER': 'Son tarih şu tarihten sonra:',
  'ASSIGN_PRIORITY': 'Önceliği atayın',
  'ASSIGN_ASSET': 'Varlığı Ata',
  'ASSIGN_LOCATION': 'Konumu Atayın',
  'ASSIGN_USER': 'Kullanıcıyı atayın',
  'ASSIGN_TEAM': 'Ekibi atayın',
  'ADD_CHECKLIST': 'Kontrol listesi ekle',
  'PRIORITY_IS': 'Önceliği',
  'ASSET_IS': 'Varlığı',
  'LOCATION_IS': 'Konumu',
  'USER_IS': 'Kullanıcısı',
  'TEAM_IS': 'Ekibi',
  'CREATED_AT_BETWEEN': 'Arasında oluşturulur',
  'TITLE_CONTAINS': 'Başlık şunları içeriyor',
  'CREATE_REQUEST': 'Bir istek oluştur',
  'CREATE_WORK_ORDER': 'İş Emri Oluştur',
  'SET_ASSET_STATUS': 'Varlık durumunu ayarla',
  'NAME_IS': 'İsmi',
  'NAME_CONTAINS': 'Adı içerir',
  'VALUE_IS': 'Değer:',
  'VALUE_CONTAINS': 'Değer şunları içerir:',
  'NUMBER_VALUE_SUPERIOR': 'Değeri bundan üstün',
  'NUMBER_VALUE_INFERIOR': 'Değeri daha düşük',
  'WORK_ORDER_CREATED': 'Bir İş Emri oluşturuldu',
  'WORK_ORDER_CLOSED': 'Bir İş Emri kapatıldı',
  'WORK_ORDER_ARCHIVED': 'Bir İş Emri arşivlendi',
  'REQUEST_CREATED': 'Bir istek oluşturuldu',
  'REQUEST_APPROVED': 'Bir istek onaylandı',
  'REQUEST_REJECTED': 'Bir istek reddedildi',
  'PURCHASE_ORDER_CREATED': 'Satınalma siparişi oluşturuldu',
  'PURCHASE_ORDER_UPDATED': 'Bir satın alma siparişi güncellendi',
  'TASK_UPDATED': 'Bir görev güncellendi',
  'PART_UPDATED': 'Bir bölüm güncellendi',
  'add_condition': 'Koşul ekle',
  'workflow_creation_success': 'İş akışı başarıyla oluşturuldu',
  'workflow_creation_failure': 'İş akışı oluşturma işlemi başarısız oldu',
  'action_value_missing': 'İşlem değeri eksik',
  'condition_value_missing': 'Bir koşul değeri eksik',
  'workflow_edit_success': 'İş akışı başarıyla güncellendi',
  'workflow_edit_failure': 'İş akışı düzenlenemedi',
  'workflow_delete_success': 'İş akışı başarıyla silindi',
  'workflow_delete_failure': 'İş akışı silinemedi',
  'confirm_delete_workflow': 'Bu iş akışını silmek istediğinizden emin misiniz?',
  'click_here': 'Buraya tıklayın',
  'verify_email_title': 'E-mail\'i doğrula',
  'verify_email_description': 'Size bir doğrulama e-postası gönderildi. ',
  'upgrade': 'Güncelleme',
  'downgrade': 'Sürüm düşürme',
  'upgrade_description': 'Abonelik planınızı yeni yükselttiniz. ',
  'enable_users': 'Kullanıcıları etkinleştir',
  'max_users_description': 'En fazla {{maxUsers}} kullanıcıyı etkinleştirebilirsiniz',
  'downgrade_description': 'Az önce abonelik planınızın düzeyini düşürdünüz. ',
  'disable_users': 'Kullanıcıları devre dışı bırak',
  'min_users_description': 'Minimum {{minUsers}} kullanıcıyı devre dışı bırakmalısınız',
  'downgrade_description_no_owner': 'Şirketiniz abonelik planının düzeyini düşürdü. ',
  'past_due': 'Vadesi geçmiş',
  'due_date_delay': 'Son Tarih gecikmesi',
  'due_date_delay_description': 'Gün olarak Son Tarih gecikmesi',
  'days_count': '{{days}} gün',
  'trigger_configuration': 'Tetikleyici Yapılandırması',
  'WORK_ORDER_feature': 'İş Emirleri',
  'REQUEST_feature': 'İş Talepleri',
  'PART_feature': 'Parça Yönetimi',
  'MOBILE_APP_feature': 'Mobil uygulama',
  'WORK_ORDER_HISTORY_feature': 'İş Emirleri Geçmişi',
  'PREVENTIVE_MAINTENANCE_feature': 'Önleyici Bakım',
  'CHECKLIST_feature': 'Kontrol listeleri',
  'FILE_feature': 'Dosya Yüklemeleri',
  'PURCHASE_ORDER_feature': 'Satın alma siparişleri',
  'METER_feature': 'Ölçer Okumaları',
  'REQUEST_CONFIGURATION_feature': 'Talep Formu yapılandırması',
  'ADDITIONAL_TIME_feature': 'Gelişmiş Zaman takibi',
  'ADDITIONAL_COST_feature': 'Gelişmiş Maliyet takibi',
  'ANALYTICS_feature': 'Analiz ve Raporlar',
  'REQUEST_PORTAL_feature': 'Talep Portalı',
  'SIGNATURE_feature': 'İmzalar',
  'ROLE_feature': 'Özel roller',
  'WORKFLOW_feature': 'İş akışı otomasyonu',
  'API_ACCESS_feature': 'API Erişimi',
  'WEBHOOK_feature': 'Web kancaları',
  'add_new_option': 'Yeni Seçenek Ekle',
  'edit_company': 'Şirketi düzenle',
  'fill_fields_below': 'Aşağıdaki alanları doldurun',
  'IMPORT_CSV_feature': 'CSV\'yi İçe Aktarma',
  'no_import_access': 'CSV ve Excel dosyalarından içe aktarma planınızı yükseltin',
  'members': 'Üyeler',
  'assign_user': 'Kullanıcı Ata',
  'assign_asset': 'Varlık Ata',
  'required_files_on_completion': 'İş Emri Tamamlamada dosyalar gereklidir',
  'required_tasks_on_completion': 'Görevler tamamlanmalıdır',
  'required_labor_on_completion': 'Zamanı kaydetmeniz gerekir',
  'required_part_on_completion': 'Bu İş Emri\'nde Hiçbir Parça Kullanılmadı',
  'required_cost_on_completion': 'Bu İş Emri\'nde Maliyet bilgisi sağlanmadı',
  'estimated_hours_in_text': 'Tahmini {{hours}} saat',
  'required_part_name': 'Parça adı gerekli',
  'asset_update_failure': 'Varlık düzenlenemedi',
  'terms_of_service': 'Kullanım Şartları',
  'privacy_policy': 'Gizlilik Politikası',
  'last_login': 'Son giriş',
  'not_enough_part': 'Bu kısım yeterli değil',
  'remove_blank_meter_tasks': 'Ölçer olmadan türü olan görevleri kaldırın.',
  'checklist_update_failure': 'Kontrol listesi düzenlenemedi',
  'contact_us': 'Bize Ulaşın',
  'simplify_wo': 'Mobil uygulamada İş Emirlerini Basitleştirin',
  'simplify_wo_description': 'Mobil uygulamayı kullanırken İş Emri ayrıntılarındaki zaman kontrollerini, maliyetleri ve parçaları gizleyin',
  'account_deletion': 'Hesap silme',
  'switch_account': 'Hesap değiştir',
  'switch_to_super_user': 'Süper yöneticiye geç',
  'switch_account_description': 'Devam etmek için lütfen bir hesap seçin',
  'Blueprints': 'Planlar',
  'Extended Sidebar': 'Genişletilmiş kenar çubuğu',
  'Accent Sidebar': 'Vurgu kenar çubuğu',
  'Accent Header': 'Vurgulu başlık',
  'Boxed Sidebar': 'Kutulu kenar çubuğu',
  'Collapsed Sidebar': 'Küçültülmüş kenar çubuğu',
  'Bottom Navigation': 'Alt gezinme',
  'Top Navigation': 'Üst gezinme',
  'Lead Developer': 'Baş Geliştirici',
  'Mega menu': 'Mega menü',
  'Doctors': 'Doktorlar',
  'Reports': 'Raporlar',
  'Custom dashboard built using the included components': 'Dahil edilen bileşenler kullanılarak oluşturulmuş özel kontrol paneli',
  'Hospital': 'Hastane',
  'Export': 'İhracat',
  'Last year': 'Geçen sene',
  'Expenses': 'Masraflar',
  'Products': 'Bazı ürünler',
  'Statistics': 'İstatistik',
  'General': 'Genel',
  'Automation': 'Otomatikleştirme',
  'Data Display': 'Veri görüntüleme',
  'Calendar': 'Takvim',
  'Mailbox': 'Posta kutuları',
  'Dashboards': 'Gösterge tabloları',
  'Analytics': 'Analitik',
  'Banking': 'Bankacılık',
  'Commerce': 'Ticaret',
  'Crypto': 'Kripto',
  'Finance': 'Finans',
  'Fitness': 'Yetenek',
  'Healthcare': 'Sağlık hizmeti',
  'Doctors Page': 'Doktorlar sayfası',
  'Hospital Overview': 'Hastaneye Genel Bakış',
  'Helpdesk': 'Yardım Masası',
  'Learning': 'Öğrenme',
  'Monitoring': 'İzleme',
  'Tasks': 'Görevler',
  'Applications': 'Uygulamalar',
  'File Manager': 'Dosya yöneticisi',
  'Jobs Platform': 'İş platformu',
  'Messenger': 'Haberci',
  'Projects Board': 'Proje Konseyi',
  'Management': 'Yönetmek',
  'Users': 'Kullanıcılar',
  'List All': 'Hepsini listele',
  'User Profile': 'Kullanıcı profili',
  'Projects': 'Projeler',
  'Shop': 'Mağaza',
  'Products List': 'Ürün listesi',
  'View Product': 'Ürünü görün',
  'Create Product': 'Bir ürün oluşturun',
  'Invoices': 'Faturalar',
  'View Single': 'Tekliyi Gör',
  'Auth Pages': 'Kimlik doğrulama sayfaları',
  'Login': 'Giriş yapmak',
  'Basic': 'Temel',
  'Cover': 'Kapak',
  'Register': 'Kayıt olmak',
  'Wizard': 'Büyücü',
  'Recover Password': 'Şifre kurtarma',
  'Status': 'Durum',
  'Foundation': 'Temel',
  'Extra Pages': 'Ek Sayfalar',
  'Error 404': 'Hata 404',
  'Error 500': 'Hata 500',
  'Coming Soon': 'Gelecek',
  'Maintenance': 'Bakım',
  'Overview': 'Ön izleme',
  'Layout Starters': 'Düzen Başlatıcılar',
  'Starter Kit 1': 'Başlangıç ​​Kiti 1',
  'Starter Kit 2': 'Başlangıç ​​Kiti 2',
  'Documentation': 'Dokümantasyon',
  'Welcome': 'Hoş geldin',
  'Help': 'Yardım',
  'Features tour': 'Özellik Turu',
  'Getting started guide': 'Başlangıç ​​Kılavuzu',
  'Contact support': 'İletişim desteği',
  'Version': 'Sürüm',
  'Search': 'Aramak',
  'Notifications': 'Bildirimler',
  'Settings': 'Ayarlar',
  'Language Switcher': 'Dil seçici',
  'Sign out': 'Bağlantıyı kes',
  'Change Theme': 'Temayı değiştir',
  'View all notifications': 'Tüm bildirimleri göster',
  'Tokyo NextJS Typescript Admin Dashboard': 'Tokyo React Yönetici Kontrol Paneli',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes': 'Hızlı ve mükemmel uygulama geliştirme süreçleri için birden fazla ürün yelpazesinde birçok güçlü Material-UI bileşeniyle oluşturulmuş yüksek performanslı React şablonu',
  'Browse Live Preview': 'Canlı önizlemeye göz atın',
  'Live Preview': 'Canlı önizleme',
  'Key Features': 'Temel özellikleri',
  'dashboards': 'Gösterge tabloları',
  'applications': 'uygulamalar',
  'management sections': 'yönetim bölümleri',
  'dark/light themes': 'koyu/açık temalar',
  'components': 'bileşenler',
  'Some of the features that make Tokyo one of the best admin templates available today': 'Tokyo\'yu günümüzün en iyi yönetici şablonlarından biri yapan özelliklerden bazıları',
  'Design Source Files': 'Kaynak dosyalarını tasarlayın',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first': 'Birlikte verilen başlangıç ​​kitlerinden birinden doğrudan projeniz üzerinde çalışmaya başlayın veya önce bir prototip oluşturmak için Figma/Sketch dosyalarını kullanın',
  'We only translated a small part of the template, for demonstration purposes': 'Gösterim amacıyla modelin yalnızca küçük bir kısmını tercüme ettik.',
  put_location_in_map: 'konumu haritaya koy',
  confirm_disable_user: '{{user}} ı devre dışı bırakmak istediğinizden emin misiniz? Yalnızca bir ay sonra etkinleştirebileceksiniz',
  user_disabled_success: 'Kullanıcı başarıyla devre dışı bırakıldı',
  disable: 'Devre dışı bırakmak',
  manufacturer: 'Üretici',
  power: 'Güç',
  import_error: 'İçe aktarma başarısız olmadı',
  request_upgrade: 'Plan değişikliği talep et',
  operation_success: 'Operasyon başarılı',
  CANCELLED: 'Reddedilmiş',
  uptime_hours: 'Kullanılabilirlik(saat)',
  downtime_hours: 'Kesinti süresi(saat)',
  mark_all_as_seen: 'Konuklara teşekkür ederiz',
  estimation_accuracy_ratio: 'Tahmin doğruluk oranı',
  download_template: 'Şablonu indir',
  'pareto_by_total_price': 'Toplam fiyata göre Pareto',
  'cumulative_percent': 'Kümülatif yüzde',
  'part_consumption_by_asset': 'Varlık başına parça tüketimi',
  'grouped_by_wo_category': 'İş emri kategorisine göre gruplanmış',
  'resolution_rate': 'Çözünürlük oranı',
  'resolved': 'Çözüldü',
  average: 'Ortalama',
  documentation: 'Dokümantasyon',
  days_before_pm_notification: 'Planlananlar için iş öncesi sipariş bildirimleri',
  estimated_start_date: 'Beklenen Başlangıç Tarihi',
  no_file_linked_to_wo: 'Bu iş emrine eklenmiş dosya yok',
  add_file: 'Dosya ekle',
  try_for_free: 'Ücretsiz dene',
  choose_your_plan: 'Planınızı seçin',
  STARTER_description: 'Makine durumu ve insan gücü görünürlüğü yoluyla verimli ve güçlü önleyici bakım oluşturmak isteyen ekipler.',
  PROFESSIONAL_description: 'Bakım alanında daha fazla büyüme ve üretkenlik sağlamak için öngörülerden ve analizlerden yararlanması gereken departmanlar.',
  BUSINESS_description: 'Birden fazla konumu ve sistem özelleştirmesini yönetmek için bakım ve operasyon verilerini yakalamaya hazır kuruluşlar.',
  book_demo: 'Demo rezervasyonu yapın',
  request_pricing: 'Daha fazla bilgi için fiyatlandırma isteyin'
};


export default trJSON;