const enJSON = {
  DUPLICATE_OF: 'Duplicate of',
  DUPLICATED_BY: 'Duplicated by',
  RELATED_TO: 'Related to',
  SPLIT_TO: 'Split to',
  SPLIT_FROM: 'Split from',
  BLOCKED_BY: 'Blocked by',
  BLOCKS: 'Blocks',
  MORE_THAN: 'Greater than',
  LESS_THAN: 'Less than',
  OPEN: 'Open',
  IN_PROGRESS: 'In Progress',
  ON_HOLD: 'On Hold',
  COMPLETE: 'Complete',
  'home.built': 'Built to Make Managing Maintenance Easier',
  home_description:
    'When whiteboards and sticky notes are chaos, and you need a CMMS software that\'s easy enough for your team to actually use - try Atlas',
  try_grash: 'Try Atlas',
  login: 'Log In',
  start_trial: 'Start a Free trial',
  'home.what': 'What',
  'home.you_will_have': 'You\'ll have',
  'home.you_will_have_description':
    'Build efficient, reliable workflows that put you in control of maintenance. Generate, assign, and track work orders. Control inventory. And get reliable automated reports that improve visibility and create accountability.',
  key_features: 'Key Features',
  key_features_description:
    'Some of the features that make Atlas one of the best CMMS available today',
  contribute: 'Contribute',
  work_orders: 'Work Orders',
  request_system: 'Work Request System',
  mobile_app: 'Mobile App',
  asset_management: 'Asset Management',
  preventive_maintenance: 'Preventive Maintenance',
  parts_inventory: 'Parts Inventory',
  custom_dashboards: 'Custom Dashboards',
  'work-orders.title': 'Manage your Work Orders like a Boss',
  'work-orders.description.short': 'View and Manage all of your Tasks',
  'work-orders.descriptions.0':
    'Sick of the reams of paperwork and lost Work Orders? All of those lost items directly affect the bottom line and your reputation.',
  'work-orders.descriptions.1':
    'With Atlas you can easily and quickly organize all of your Work Orders, PMs and other Tasks throughout your team in a centralized database that can be accessed anywhere on any device.',
  'work-orders.checks.0':
    'View all of your Tasks including WOs, PMs, and Work Requests',
  'work-orders.checks.1':
    'Set Task priority so the right work gets done first.',
  'work-orders.checks.2': 'View a calendar of current and upcoming Tasks.',
  'work-orders.checks.3':
    'View work assignments with critical information such as Asset information, due date, priority and more.',
  'work-orders.checks.4':
    'View critical KPIs such as actual completed work, time spent and planned vs unplanned work.',

  'work-requests.title': 'A streamlined mobile work request system',
  'work-requests.descriptions.0':
    'Sick of using the old phone and email system that result in LOST tickets, constant daily interruptions, and duplicated work?',
  'work-requests.descriptions.1':
    'Atlas’s maintenance management solution allows anyone you authorize to submit a problem to your maintenance department by simply scanning a QR code (bar code) or visiting a URL and typing in their request through their mobile devices.',
  'work-requests.checks.0': 'Never lose Work again.',
  'work-requests.checks.1':
    'Reduce time spent handling Work Requests by up to 34%.',
  'work-requests.checks.2':
    'Reduce time communicating with Requesters by up to 41% (calls, emails, texts).',

  'mobile-app.title': 'A mobile CMMS app that empowers your maintenance team',
  'mobile-app.descriptions.0':
    'Through the use of our Android and IOS mobile apps, our clients experience better organization, communication, accountability and productivity by up to 30% throughout their entire maintenance team.',
  'mobile-app.descriptions.1':
    'From their handheld device anyone on your maintenance team can:',
  'mobile-app.checks.0': 'View their assigned Work Orders and PMs.',
  'mobile-app.checks.1': 'Log their Work Orders within under 60 seconds',
  'mobile-app.checks.2':
    'Receive instant communications via push and email notifications.',
  'mobile-app.checks.3': 'Start new Work Orders while out in the field.',
  'mobile-app.checks.4':
    'Respond to and log problems while actually working on the problem',
  'mobile-app.checks.5':
    'Lookup critical asset information while diagnosing a problem.',

  'eam.title': 'Enterprise Asset Management',
  'eam.description.short': 'Know your assets',
  'eam.descriptions.0':
    'Frustrated with trying to organize the chaotic mess of company’s equipment, wondering if the equipment has been properly taken care of, not knowing what they cost to maintain and all the other headaches that come with managing assets?',
  'eam.descriptions.1':
    'Atlas CMMS’s Enterprise Asset Management module can be used for anywhere between 10 to 1,000,000 Assets, allowing you to track exactly what you want, the way you want to, in an easy-to-use and searchable tree structure.',
  'eam.checks.0':
    'Know the health of your assets at every point of time and what they are costing you',
  'eam.checks.1': 'View complete and detailed Maintenance Logs.',
  'eam.checks.2': 'Track only what you want with unlimited custom fields',
  'eam.checks.3': 'Organize your assets in a clear parent-to-child hierarchy',
  'eam.checks.4':
    'Find information quickly and easily with QR Codes (bar codes)',
  'eam.checks.5': 'Receive real-time asset data with sensor connectivity.',

  'pm.title': 'An Effective Preventative Maintenance Program',
  'pm.descriptions.0':
    'Stop wasting precious hours manually distributing PM checklists and following up with your team to make sure the work is done.',
  'pm.descriptions.1':
    'Atlas allows you to easily automate PM scheduling so the right work is automatically delivered at the right time to the right technician for the right Asset.',
  'pm.checks.0':
    'Automate PM scheduling to be daily, weekly, monthly, yearly, or based on events or meter readings.',
  'pm.checks.1':
    'Automatic push and email notifications when a PM is created or due.',
  'pm.checks.2':
    'Our easy-to-use Calendar allows you to view all open work and upcoming PM schedules.',
  'pm.checks.3': 'Drag and drop to change due date.',
  'pm.checks.4':
    'Upon PM completion, the work is automatically logged in the asset’s maintenance log..',
  'pm.checks.5': 'Requires little to no training to use.',

  'part.title': 'Get Control of your Spare Parts Inventory',
  'part.description.short': 'Know when your parts will run out',
  'part.descriptions.0':
    'Not sure what parts you have in stock or where they are being used? Don’t know when your parts will run out or if your technicians will have the necessary parts to do their jobs?',
  'part.descriptions.1':
    'We designed Atlas’s parts management to solve those problems and more:',
  'part.checks.0':
    'Get instant push and email notifications when a part’s quantity is low. Keep the right number of parts in stock by receiving instant and automatic push/email notifications when the quantity of a part is below a custom set threshold.',
  'part.checks.1':
    'Technicians instantly know if the part is in stock from inside of a Work Order. If the part is not in stock easily check other locations to see if it is available.',
  'part.checks.2':
    'Automatic parts usage tracking. As Work Orders or PMs use parts, the inventory will be automatically updated to reflect the parts used.',
  'part.checks.3':
    'Know part usage. View Parts Log to see where the part is being used and by whom',
  'part.checks.4': 'Eliminate unused parts. Know when a part has gone stale..',
  'part.checks.5':
    'Real Time Reporting. Know what parts are used where and how much they are costing you.',
  'part.checks.6':
    'Optimize stock levels. Instantly view forecasting for a part to see how many parts may be used within the next year.',
  'part.checks.7':
    'Barcode lookup. Make your inventory easy to track and access with custom QR codes',

  'dashboard.title': 'Your data, your way with custom dashboards',
  'dashboard.descriptions.0':
    'Let’s face it, the first step to improving your operations is to know the good, the bad and the ugly.',
  'dashboard.descriptions.1':
    'With Atlas’s Custom Dashboards you can finally start to understand that nefarious “black hole of maintenance” and begin putting data-backed plans into action, resulting in reduced labor costs, decreased downtime and more.',
  'dashboard.descriptions.2': 'With Atlas’s Custom Dashboards you’ll get to …',
  'dashboard.checks.0': 'Know where your money is being spent and why',
  'dashboard.checks.1': 'Know which asset is costing the most and why',
  'dashboard.checks.2': 'Create your own custom KPIs.',
  'dashboard.checks.3':
    'View reports that are automatically generated and updated based on your metric',
  'dashboard.checks.4':
    'View reports that are easily shared via Excel sheets, PDFs or within Atlas',
  'dashboard.checks.5': 'And so much more ...',
  'home.smarter': 'Smarter',
  'home.work': 'Work',
  'home.smarter_description':
    'Atlas makes it incredibly easy for technicians to work efficiently by giving them access to the tools and data they need to get work done — all from the palm of their hand.',
  leading_maintenance:
    'Leading the Way to a Better Future for Maintenance and Reliability',
  //register
  premium_included: 'Premium features included',
  no_credit_card: 'No credit card required',
  perfect_tool: 'The perfect tool for Maintenance teams',
  start_trial_today: 'Start your Free trial today',
  create_account: 'Create account',
  signup_description: 'Fill in the fields below to sign up for an account.',
  account_already: 'Already have an account?',
  signin_here: 'Sign in here',
  invalid_email: 'The email provided should be a valid email address',
  required_email: 'The email field is required',
  required_firstName: 'The first name field is required',
  required_lastName: 'The last name field is required',
  required_company: 'The company name field is required',
  required_employeesCount: 'Please provide the number of employees',
  invalid_phone: 'The phone number is invalid',
  required_password: 'The password field is required',
  required_terms: 'You must agree to our terms and conditions',
  verify_email: 'Please check your email to activate your account',
  registration_error: 'The registration didn\'t succeed',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email address',
  phone: 'Phone',
  password: 'Password',
  companyName: 'Company Name',
  employeesCount: 'Number of Employees',
  i_accept: 'I accept the',
  terms_conditions: 'terms and conditions',
  create_your_account: 'Create your account',
  register: 'Register',
  //signin
  login_description: 'Fill in the fields below to sign into your account.',
  no_account_yet: 'Don’t have an account, yet?',
  signup_here: 'Sign up here',
  wrong_credentials: 'Wrong credentials provided',
  lost_password: 'Lost password ?',
  //work-orders
  link_wo: 'Link Work Orders',
  link_wo_description: 'Create relationships between Work Orders',
  required_relationType: 'Please select the relationship type.',
  required_wo: 'The Work Order field is required.',
  this_wo: 'This Work Order',
  the_wo: 'The Work Order',
  link: 'Link',
  cost_description: 'Cost Description',
  assigned_to: 'Assigned To',
  category: 'Category',
  date: 'Date',
  cost: 'Cost',
  include_cost: 'Include this cost in the total cost',
  include_cost_description:
    'This will add the cost to the total cost spent on the Work Order',
  required_cost_description: 'Cost Description is required',
  required_cost: 'Cost is required',
  add_cost: 'Add Additional Cost',
  add_cost_description:
    'Fill in the fields below to create and add Additional Cost',
  add: 'Add',
  hourly_rate: 'Hourly Rate',
  include_time: 'Include this time in the total time',
  include_time_description:
    'This will add the duration to the total time spent on the Work Order',
  work_started_at: 'Work Started At',
  duration: 'Duration',
  hours: 'Hours',
  minutes: 'Minutes',
  required_hours: 'Hours field is required',
  required_minutes: 'Minutes field is required',
  add_time: 'Add Time',
  add_time_description: 'Fill in the fields below to create and add Time',
  feedback: 'Feedback',
  feedback_description: 'Give your feedback',
  required_feedback: 'Feedback is required',
  signature: 'Signature',
  required_signature: 'Image is required',
  close_wo: 'Close Work Order',
  close: 'Close',
  task_update_success: 'Task updated successfully',
  task_update_failure: 'Task couldn\'t be updated',
  notes_save_success: 'Notes saved successfully',
  images_add_task_success: 'The images have been added to the task',
  images_add_task_failure: 'Something went wrong',
  images: 'Images',
  required_images: 'Please upload at least an image',
  add_images: 'Add Images',
  tasks: 'Tasks',
  wo_archive_success: 'The Work Order has been archived',
  wo_archive_failure: 'The Work Order couldn\'t be archived',
  wo_archive_confirm: 'Are you sure you want to archive this Work Order ',
  quantity_change_success: 'Quantity changed successfully',
  quantity_change_failure: 'Quantity couldn\'t be changed',
  details: 'Details',
  updates: 'Updates',
  id: 'ID',
  due_date: 'Due Date',
  location: 'Location',
  asset: 'Asset',
  team: 'Team',
  created_at: 'Date created',
  list_view: 'List View',
  calendar_view: 'Calendar View',
  column_view: 'Column View',
  wo_create_success: 'The Work Order has been created successfully',
  wo_create_failure: 'The Work Order couldn\'t be created',
  changes_saved_success: 'The changes have been saved',
  wo_update_failure: 'The Work Order couldn\'t be edited',
  wo_delete_success: 'The Work Order has been deleted successfully',
  wo_delete_failure: 'The Work Order couldn\'t be deleted',
  status: 'Status',
  title: 'Title',
  priority: 'Priority',
  description: 'Description',
  worker: 'Worker',
  location_name: 'Location name',
  location_address: 'Location address',
  asset_name: 'Asset name',
  days_since_creation: 'Days since creation',
  files: 'Files',
  requested_by: 'Requested By',
  completed_on: 'Completed On',
  updated_at: 'Updated At',
  work_order: 'Work Order',
  timer_running: 'Timer running',
  run_timer: 'Run Timer',
  approved_by: 'Approved By',
  created_by: 'Created By',
  completed_by: 'Completed By',
  time: 'Time',
  save: 'Save',
  customers: 'Contractors',
  no_labor:
    'No labor costs have been added yet. They\'ll show up here when a user logs time and has an hourly rate stored in Atlas.',
  not_assigned: 'Not Assigned',
  no_additional_cost: 'No Additional costs have been added yet',
  parts: 'Parts',
  links: 'Links',
  confirm_delete_file_wo:
    'Are you sure you want to remove this file from this Work Order ?',
  archive: 'Archive',
  labors: 'Labors',
  add_additional_cost: 'Add additional Cost',
  additional_costs: 'Additional Costs',
  add_parts: 'Add Parts',
  'wo.title_description': 'Enter Work Order title',
  image: 'Image',
  estimated_duration: 'Estimated Duration in Hours',
  primary_worker: 'Primary Worker',
  additional_workers: 'Additional Workers',
  select_team: 'Select team',
  select_location: 'Select location',
  select_asset: 'Select asset',
  select_tasks: 'Select Tasks',
  requires_signature: 'Requires Signature',
  required_wo_title: 'WorkOrder title is required',
  add_wo: 'Add Work Order',
  add_wo_description:
    'Fill in the fields below to create and add a new Work Order',
  edit_wo: 'Edit Work Order',
  edit_wo_description: 'Fill in the fields below to update the Work Order',
  'noRows.wo.message':
    'Work Orders are tasks or jobs, that can be scheduled or assigned to someone',
  'noRows.wo.action': 'Press the \'+\' button to create a Work Order',
  to_delete: 'Delete',
  confirm_delete_wo: 'Are you sure you want to delete this Work Order?',
  no_access_wo:
    'You don\'t have access to Work Orders. Please contact your administrator if you should have access',
  cancel: 'Cancel',
  select_parts: 'Select Parts',
  sets_of_parts: 'Sets of Parts',
  //preventive maintenance
  paused: 'Paused',
  trigger_details: 'Trigger details',
  starts_on: 'Starts On',
  ends_on: 'Ends On',
  frequency: 'Frequency',
  every_frequency_days: 'Every {{frequency}} days',
  wo_details: 'Work Order details',
  confirm_delete_file: 'Are you sure you want to remove this file?',
  wo_schedule_success: 'Work Order successfully scheduled',
  wo_schedule_failure: 'The Work Order couldn\'t be scheduled',
  wo_trigger_edit_failure: 'The Work Order Trigger couldn\'t be edited',
  wo_trigger_delete_success:
    'The Work Order trigger has been deleted successfully',
  wo_trigger_delete_failure: 'The Work Order Trigger couldn\'t be deleted',
  name: 'Name',
  wo_title: 'Work Order Title',
  next_wo: 'Next Work Order',
  trigger_name: 'Trigger Name',
  enter_trigger_name: 'Enter trigger Name',
  frequency_description: 'Frequency in days',
  wo_configuration: 'Work Order Configuration',
  required_trigger_name: 'Trigger name is required',
  required_frequency: 'The trigger frequency is required',
  invalid_frequency: 'Frequency must be superior to 0',
  schedule_wo: 'Schedule Work Order',
  schedule_wo_description: 'Fill in the fields below to schedule a Work Order',
  edit_wo_trigger: 'Edit Work Order Trigger',
  edit_wo_trigger_description:
    'Fill in the fields below to edit the Work Order Trigger',
  create_trigger: 'Create Trigger',
  'noRows.pm.message': 'Schedule Work Orders with custom triggers',
  'noRows.pm.action': 'Press the \'+\' button to create a trigger',
  confirm_delete_pm: 'Are you sure you want to delete this Work Order Trigger?',
  no_access_pm:
    'You don\'t have access to the Preventive Maintenance section. Please contact your administrator if you should have access',
  enter_wo_title: 'Enter Work Order title',
  //menuItems
  status_report: 'Status Report',
  wo_analysis: 'Work Order analysis',
  wo_aging: 'Work Order Aging',
  time_and_cost: 'Time and Cost',
  assets: 'Assets',
  reliability_dashboard: 'Reliability Dashboard',
  total_maintenance_cost: 'Total Maintenance Cost',
  useful_life: 'Useful life',
  requests: 'Requests',
  locations: 'Locations',
  parts_and_inventory: 'Parts/Inventory',
  purchase_orders: 'Purchase Orders',
  meters: 'Meters',
  people_teams: 'People & Teams',
  people: 'People',
  teams: 'Teams',
  vendors_customers: 'Vendors & Contractors',
  vendors: 'Vendors',
  categories: 'Categories',
  settings: 'Settings',
  //request
  cancelled: 'Cancelled',
  reject: 'Reject',
  approve: 'Approve',
  request_create_success: 'Work Order successfully requested',
  request_create_failure: 'The Work Request couldn\'t be created',
  request_edit_failure: 'The Request couldn\'t be edited',
  request_delete_success: 'The Request has been deleted successfully',
  request_delete_failure: 'The Request couldn\'t be deleted',
  rejected: 'Rejected',
  approved: 'Approved',
  pending: 'Pending',
  required_request_name: 'Request title is required',
  required_field: 'This field is required',
  add_request: 'Add Request',
  add_request_description:
    'Fill in the fields below to create and add a new Request',
  edit_request: 'Edit Request',
  edit_request_description: 'Fill in the fields below to edit the Request',
  'noRows.request.message': 'Manage your Work Requests',
  'noRows.request.action': 'Press the \'+\' button to create a Work Request',
  confirm_delete_request: 'Are you sure you want to delete this Request?',
  request: 'Request',
  request_details: 'Request details',
  none_priority: 'None',
  low_priority: 'Low',
  medium_priority: 'Medium',
  high_priority: 'High',
  //asset
  downtimes: 'Downtimes',
  confirm_delete_file_asset:
    'Are you sure you want to remove this File from this asset?',
  actions: 'Actions',
  show: 'Show',
  model: 'Model',
  serial_number: 'Serial Number',
  operational: 'Operational',
  down: 'Down',
  area: 'Area',
  barcode: 'Barcode',
  placed_in_service: 'Placed in Service',
  warranty_expiration: 'Warranty expiration',
  asset_information: 'Asset Information',
  more_informations: 'More Informations',
  confirm_delete_asset_downtime:
    'Are you sure you want to remove this Downtime?',
  create_downtime_success: 'The Downtime has been added successfully',
  create_downtime_failure: 'The Downtime couldn\'t be added',
  edit_downtime_success: 'The Downtime has been edited successfully',
  started_on: 'Started On',
  required_startsOn: 'The start date is required',
  reading_create_success: 'The Reading has been added successfully',
  reading_create_failure: 'The Reading couldn\'t be added',
  reading: 'Reading',
  added_by: 'Added By',
  enter_meter_value: 'Enter Meter value',
  add_reading: 'Add Reading',
  select_meter: 'Select Meter',
  confirm_delete_part_asset:
    'Are you sure you want to remove this Part from this asset?',
  no_due_date: 'No Due Date',
  due_at_date: 'Due {{date}}',
  no_primary_worker: 'No primary Worker',
  no_wo_linked_asset: 'There is no Work Order linked to this Asset',
  asset_remove_success: 'The Asset has been deleted successfully',
  asset_remove_failure: 'The Asset couldn\'t be deleted',
  asset_name_description: 'Enter asset name',
  select_asset_location: 'Select asset location',
  primary_user_description: 'Select primary user',
  teams_description: 'Select teams',
  customers_description: 'Select contractors',
  vendors_description: 'Select vendors',
  inServiceDate_description: 'Placed in Service date',
  warranty_expiration_date: 'Warranty Expiration date',
  additional_information: 'Additional Information',
  structure: 'Structure',
  parent_asset: 'Parent Asset',
  asset_create_success: 'The Asset has been created successfully',
  asset_create_failure: 'The Asset couldn\'t be created',
  additional_workers_description: 'Select additional workers',
  required_asset_name: 'Asset name is required',
  add_asset: 'Add Asset',
  add_asset_description:
    'Fill in the fields below to create and add a new asset',
  create_asset: 'Create Asset',
  hierarchy: 'Hierarchy',
  add_downtime: 'Add Downtime',
  edit_downtime: 'Edit Downtime',
  remove_downtime: 'Remove Downtime',
  edit: 'Edit',
  edit_asset: 'Edit Asset',
  edit_asset_description: 'Fill in the fields below to edit this asset',
  confirm_delete_asset: 'Are you sure you want to delete this Asset?',
  loading_assets: 'Loading assets under {{name}} #{{id}}',
  no_content: 'No Content',
  no_result_criteria: 'No result matches your search criteria',
  //location
  map_view: 'Map View',
  location_create_success: 'The location has been created successfully',
  location_create_failure: 'The location couldn\'t be created',
  location_edit_failure: 'The location couldn\'t be edited',
  location_delete_success: 'The location has been deleted successfully',
  location_delete_failure: 'The location couldn\'t be deleted',
  enter_location_name: 'Enter location name',
  address: 'Address',
  parent_location: 'Parent Location',
  select_workers: 'Select Workers',
  map_coordinates: 'Map Coordinates',
  required_location_name: 'Location title is required',
  required_location_address: 'Location address is required',
  add_location: 'Add location',
  add_location_description:
    'Fill in the fields below to create and add a new location',
  edit_location: 'Edit location',
  edit_location_description: 'Fill in the fields below to update the location',
  confirm_delete_location: 'Are you sure you want to delete this Location?',
  floor_plans: 'Floor Plans',
  floor_plan: 'Floor Plan',
  floor_plan_name_description: 'Floor plan name',
  'Floor plan area in m²': 'Floor plan area in m²',
  upload_image: 'Upload an image',
  required_floor_plan_name: 'Floor plan name is required',
  add_floor_plan: 'Add new Floor Plan',
  add_floor_plan_description:
    'Fill in the fields below to create a new Floor Plan',
  no_asset_in_location: 'No asset in this location',
  no_wo_in_location: 'No Work Order in this location',
  no_file_in_location: 'No Files attached to this location',
  no_floor_plan_in_location: 'No Floor Plan in this location',
  assigned_teams: 'Assigned Teams',
  assigned_customers: 'Assigned Contractors',
  assigned_vendors: 'Assigned Vendors',
  //no_access
  no_access_location:
    'You don\'t have access to Locations. Please contact your administrator if you should have access',
  no_access_vendors_customers:
    'You don\'t have access to Vendors And Contractors. Please contact your administrator if you should have access',
  no_access_people_team:
    'You don\'t have access to People And Teams. Please contact your administrator if you should have access',
  no_access_analytics:
    'You don\'t have access to Analytics. Please contact your administrator if you should have access',
  no_access_assets:
    'You don\'t have access to Assets. Please contact your administrator if you should have access',
  no_access_categories:
    'You don\'t have access to Categories. Please contact your administrator if you should have access',
  no_access_files:
    'You don\'t have access to Files. Please contact your administrator if you should have access',
  no_access_inventory:
    'You don\'t have access to The Inventory. Please contact your administrator if you should have access',
  no_access_meters:
    'You don\'t have access to Meters. Please contact your administrator if you should have access',
  no_access_purchase_orders:
    'You can\'t create a Purchase Order. Please contact your administrator if you should have access',
  no_access_requests:
    'You don\'t have access to Requests. Please contact your administrator if you should have access',
  no_access_settings:
    'You don\'t have access to settings. Please contact your administrator if you should have access',
  no_access_page: 'You don\'t have access to this page',
  file: 'File',
  'noRows.location.message':
    'Locations let you manage more efficiently assets and workers',
  'noRows.location.action': 'Press the \'+\' button to create a Location',
  //inventory
  card_view: 'Card View',
  set_create_success: 'The Set has been created successfully',
  set_create_failure: 'The Set couldn\'t be created',
  set_edit_failure: 'The Set couldn\'t be edited',
  set_delete_success: 'The Set has been deleted successfully',
  set_delete_failure: 'The Set couldn\'t be deleted',
  total_cost: 'Total Cost',
  part: 'Part',
  enter_part_name: 'Enter Part name',
  required_set_name: 'Set name is required',
  add_set: 'Add Set',
  add_set_description: 'Fill in the fields below to create and add a new Set',
  create_set: 'Create Set',
  edit_set: 'Edit Set',
  edit_set_description: 'Fill in the fields below to edit the Set',
  confirm_delete_set: 'Are you sure you want to delete this Set?',
  part_create_success: 'The Part has been created successfully',
  part_create_failure: 'The Part couldn\'t be created',
  part_edit_failure: 'The Part couldn\'t be edited',
  part_delete_success: 'The Part has been deleted successfully',
  part_delete_failure: 'The Part couldn\'t be deleted',
  quantity: 'Quantity',
  open_wo: 'Open Work Orders',
  enter_part_category: 'Enter Part category',
  enter_part_cost: 'Enter Part cost',
  enter_part_quantity: 'Enter Part quantity',
  minimum_quantity: 'Minimum Quantity',
  enter_part_minimum_quantity: 'Enter Part minimum quantity',
  non_stock: 'Non Stock',
  enter_part_barcode: 'Enter Part Barcode',
  enter_part_area: 'Enter Part Area',
  additional_part_details: 'Additional Part Details',
  add_part: 'Add Part',
  add_part_description: 'Fill in the fields below to create and add a new Part',
  create_part: 'Create Part',
  update_part: 'Update Part',
  update_part_description: 'Fill in the fields below to update the Part',
  'noRows.part.message': 'Parts can be combined into assets or used alone',
  'noRows.part.action': 'Press the \'+\' button to create a Part',
  confirm_delete_part: 'Are you sure you want to delete this Part?',
  part_details: 'Part details',
  area_details: 'Area details',
  assigned_people: 'Assigned people',
  no_asset_related_part: 'No asset related to this part',
  no_file_found: 'No File found',
  no_wo_found: 'No Work Order found',
  workers: 'Workers',
  Parts_and_Inventory: 'Parts & Inventory',
  //purchase order
  po_edit_failure: 'The Purchase Order couldn\'t be edited',
  po_delete_success: 'The Purchase Order has been deleted successfully',
  po_delete_failure: 'The Purchase Order couldn\'t be deleted',
  number_of_items: 'Number of items',
  total_quantity: 'Total Quantity',
  shipping_to: 'Shipping to',
  shipping_address: 'Shipping Address',
  po_details: 'Purchase Order Details',
  enter_po_name: 'Enter Purchase Order name',
  additional_details: 'Additional Details',
  vendor: 'Vendor',
  shipping_information: 'Shipping Information',
  company_name: 'Company Name',
  ship_to: 'Ship To',
  city: 'City',
  state: 'State',
  zip_code: 'Zip Code',
  fax_number: 'Fax Number',
  po_date: 'Purchase Order Date',
  notes: 'Notes',
  add_notes: 'Add Notes',
  requisitioner: 'Requisitioner',
  terms: 'Terms',
  shipping_method: 'Shipping Method',
  required_name: 'The name is required',
  edit_po: 'Edit Purchase Order',
  edit_po_description: 'Fill in the fields below to update the Purchase Order',
  purchase_order: 'Purchase Order',
  'noRows.po.message': 'Manage your Purchase Orders in a single place',
  'noRows.po.action': 'Press the \'+\' button to create a Purchase Order.',
  confirm_delete_po: 'Are you sure you want to delete this Purchase Order?',
  upgrade_po: 'Upgrade to create Purchase Orders',
  new_po: 'New Purchase Order',
  po_create_success: 'The Purchase Order has been created successfully',
  select_one_part: 'Select at least 1 part ',
  po_create_failure: 'The Purchase Order couldn\'t be created',
  approve_while_submitting: 'Approve while submitting',
  invalid_fax: 'The fax number is invalid',
  submit: 'Submit',
  each_item_superior_zero: 'Each Item quantity must be superior to 0',
  quantity_edit_success: 'Quantity changed successfully',
  quantity_edit_failure: 'Quantity couldn\'t be changed',
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  //meter
  when_reading_is: 'When Meter Reading is',
  greater_than: 'Greater than',
  lower_than: 'Lower than',
  value: 'Value',
  required_value: 'The value is required',
  required_trigger_condition: 'The condition is required',
  wo_trigger_create_success:
    'The Work Order trigger has been created successfully',
  wo_trigger_create_failure: 'The Work Order trigger couldn\'t be created',
  add_wo_trigger: 'Add Work Order Trigger',
  add_wo_trigger_description:
    'Fill in the fields below to create and add a Work Order Trigger',
  wo_trigger_edit_success:
    'The Work Order trigger has been updated successfully',
  meter_create_success: 'The meter has been created successfully',
  meter_create_failure: 'The meter couldn\'t be created ',
  meter_edit_failure: 'The meter couldn\'t be edited',
  meter_delete_success: 'The meter has been deleted successfully',
  meter_delete_failure: 'The meter couldn\'t be deleted',
  next_reading_due: 'Next Reading Due',
  unit_of_measurement: 'Unit of Measurement',
  last_reading: 'Last Reading',
  enter_meter_name: 'Enter Meter name',
  unit: 'Unit',
  update_frequency: 'Update Frequency',
  update_frequency_in_days: 'Update Frequency in days',
  required_meter_name: 'Meter name is required',
  required_meter_unit: 'Meter unit is required',
  required_meter_update_frequency: 'Meter update frequency is required',
  required_asset: 'Asset is required',
  add_meter: 'Add Meter',
  add_meter_description:
    'Fill in the fields below to create and add a new Meter',
  edit_meter: 'Edit Meter',
  edit_meter_description: 'Fill in the fields below to edit the Meter',
  confirm_delete_meter: 'Are you sure you want to delete this Meter?',
  upgrade_create_meter: 'Upgrade to create Meters',
  history: 'History',
  reading_frequency: 'Reading Frequency',
  required_reading_value: 'Reading value is required',
  wo_triggers: 'Work Order Triggers',
  add_trigger: 'Add Trigger',
  meter_details: 'Meter details',
  'noRows.meter.message':
    'Meter readings give you the ability to monitor assets and trigger new work orders based on defined conditions',
  'noRows.meter.action': 'Press the \'+\' button to create a Meter',
  meter: 'Meter',
  //upload
  drag_one_file: 'Drag a unique file here',
  drag_many_files: 'Drag & drop files here',
  drop_to_start: 'Drop the files to start uploading',
  invalid_files_type: 'You cannot upload these file types',
  you_have_uploaded: 'You have uploaded',
  upload: 'Upload',
  //vendors_customers
  Vendors_Customers: 'Vendors & Contractors',
  customer_create_success: 'The Contractor has been created successfully',
  customer_create_failure: 'The Contractor couldn\'t be created',
  customer_edit_failure: 'The Contractor couldn\'t be edited',
  customer_delete_success: 'The Contractor has been deleted successfully',
  customer_delete_failure: 'The Contractor couldn\'t be deleted',
  customer_name: 'Contractor Name',
  website: 'Website',
  customer_type: 'Contractor type',
  customer_type_description: 'ex. Plumbing, Electrical',
  customer_description_description:
    'Describe the purpose of this contractor in a few line...',
  billing_information: 'Billing Information',
  address_line_2: 'Address Line 2',
  billing_name: 'Billing Name',
  currency: 'Currency',
  select_currency: 'Select Currency',
  required_customer_name: 'Contractor Name is required',
  required_phone: 'The phone number is required',
  invalid_website: 'Invalid website',
  billing_address: 'Billing Address',
  add_customer: 'Add Contractor',
  add_customer_description:
    'Fill in the fields below to create and add a new contractor',
  'noRows.customer.message': 'Contractors are external workers',
  'noRows.customer.action': 'Press the \'+\' button to create a Contractor',
  type: 'Type',
  billing_currency: 'Billing Currency',
  go_back: 'Go back',
  confirm_delete_customer: 'Are you sure you want to delete this Contractor?',
  //vendor
  vendor_create_success: 'The Vendor has been created successfully',
  vendor_create_failure: 'The Vendor couldn\'t be created',
  vendor_edit_failure: 'The Vendor couldn\'t be edited',
  vendor_delete_success: 'The Vendor has been deleted successfully',
  vendor_delete_failure: 'The Vendor couldn\'t be deleted',
  required_company_name: 'Company Name is required',
  vendor_type: 'Vendor Type',
  contact_name: 'Contact name',
  add_vendor: 'Add vendor',
  add_vendor_description:
    'Fill in the fields below to create and add a new vendor',
  confirm_delete_vendor: 'Are you sure you want to delete this Vendor ?',
  'noRows.vendor.message':
    'Vendors are organizations that provide Assets or Parts',
  'noRows.vendor.action': 'Press the \'+\' button to create a Vendor',
  vendor_type_description: 'ex. Plumbing, Electrical',
  //people teams
  role: 'Role',
  edit_user: 'Edit User',
  edit_user_description: 'Fill in the fields below to edit the user',
  job_title: 'Job Title',
  invite_users: 'Invite Users',
  bring_people_team: 'Bring new people to the team',
  add_20_users:
    'You may add 20 users at a time by pressing \'tab\' or \'enter\' after each email entry. Any duplicate and registered emails will be removed while registering the requested users.',
  enter_email: 'Enter email address',
  users_invite_success: 'Users have been invited',
  users_invite_failure:
    'Users can\'t be invited. Check your current subscription members count',
  please_type_emails: 'Please type in emails to invite',
  please_select_role: 'Please select a role',
  invite: 'Invite',
  team_create_success: 'The Team has been created successfully',
  team_create_failure: 'The Team couldn\'t be created',
  team_edit_failure: 'The Team couldn\'t be edited',
  team_delete_success: 'The Team has been deleted successfully',
  team_delete_failure: 'The Team couldn\'t be deleted',
  team_name: 'Team Name',
  people_in_team: 'People in the team',
  required_team_name: 'Team Name is required',
  create_team: 'Create team',
  create_team_description: 'You can add team members from here',
  confirm_delete_team: 'Are you sure you want to delete this Team ?',
  overview: 'Overview',
  activity: 'Activity',
  wo_complete_last_14_days: 'Work orders completed in last 14 days',
  no_wo_complete_last_14_days:
    'You have not completed any work orders in the last two weeks',
  ADMIN_name: 'Administrator',
  ADMIN_description:
    'Administrator has full access; including editing, adding, deleting work orders and requests',
  LIMITED_ADMIN_name: 'Limited Administrator',
  LIMITED_ADMIN_description:
    'Limited administrators have the same access as administrator except they are unable to view/edit settings or add/edit people and teams. They cannot delete Work Orders, Assets Locations, Meters and Purchase Orders unless they created Contractors, Categories and PM triggers.',
  TECHNICIAN_name: 'Technician',
  TECHNICIAN_description:
    'Technicians can create and close work orders, assets and locations. Able to edit and delete only what they have created',
  LIMITED_TECHNICIAN_name: 'Limited Technician',
  LIMITED_TECHNICIAN_description:
    'Limited technicians can only see work orders assigned to them',
  VIEW_ONLY_name: 'View Only',
  VIEW_ONLY_description:
    'View only users have full view access, but cannot edit anything',
  REQUESTER_name: 'Requester',
  REQUESTER_description:
    'Requesters can only submit work requests and view their status',
  select_user_role: 'Select user role',
  'noRows.team.message': 'Teams help you manage specific groups of people.',
  'noRows.team.action': 'Press the \'+\' button to create a team.',
  user_details: 'User Details',
  //files
  file_delete_success: 'The file has been deleted successfully',
  file_delete_failure: 'The file couldn\'t be deleted',
  uploaded_by: 'Uploaded By',
  uploaded_on: 'Uploaded On',
  rename: 'Rename',
  required_files: 'Please upload at least a file',
  required_file_name: 'Please enter a file name',
  add_files: 'Add Files',
  edit_file: 'Edit File',
  'noRows.file.message': 'Get All files in a Single place',
  'noRows.file.action': 'Press the \'+\' button to upload a File',
  upgrade_files:
    'Upgrade to use files in your Assets, Work Orders, Locations etc...',
  parts_consumption: 'Parts Consumption',
  requests_analysis: 'Request Analysis',
  //categories,
  category_create_success: 'The Category has been created successfully',
  category_create_failure: 'The Category couldn\'t be created',
  category_edit_failure: 'The Category couldn\'t be edited',
  category_delete_success: 'The Category has been deleted successfully',
  category_delete_failure: 'The Category couldn\'t be deleted',
  timers: 'Timers',
  costs: 'Costs',
  add_category: 'Add new category',
  add_category_description: 'Fill in the name to create and add a new category',
  edit_category: 'Edit Category',
  edit_category_description: 'Fill in the name to edit category',
  no_category_action: 'Press the \'+\' button to add your first category.',
  confirm_delete_category: 'Are you sure you want to delete this Category?',
  no_category_message:
    'Looks like you don\'t have any {{categoryName}} Categories yet.',
  acquisition_cost: 'Acquisition Cost',
  //settings
  general_settings: 'General Settings',
  request_form_configuration: 'Request form configuration',
  roles: 'Roles',
  checklists: 'Checklists',
  field_configuration_description:
    'You can mark fields as Optional, Hidden or Required',
  optional: 'Optional',
  required: 'Required',
  hidden: 'Hidden',
  creating_wo: 'Creating a Work Order',
  completing_wo: 'Completing a Work Order',
  role_edit_failure: 'The Role couldn\'t be edited',
  edit_role: 'Edit role',
  edit_role_description: 'Fill in the fields below to edit the role',
  external_id: 'External ID',
  permissions: 'Permissions',
  create_role_description:
    'This role can do everything an Administrator can do in Atlas, but you can customize some important permissions below.',
  create_and_edit: 'Create/Edit',
  to_access: 'Access',
  role_delete_success: 'The Role has been deleted successfully',
  role_delete_failure: 'The Role couldn\'t be deleted',
  confirm_delete_role: 'Are you sure you want to permanently delete this role',
  users: 'Users',
  paid: 'Paid',
  free: 'Free',
  role_create_success: 'The Role has been created successfully',
  role_create_failure: 'The Role couldn\'t be created',
  roles_management: 'Roles Management',
  roles_number: '{{count}} roles',
  create_role: 'Create Role',
  upgrade_role: 'Upgrade to create role',
  add_role: 'Add new role',
  add_role_description: 'Fill in the fields below to create and add a new role',
  parts_and_sets: 'Parts & Sets of Parts',
  upgrade_role_edit: 'Upgrade to edit role',
  delete_role: 'Delete Role',
  upgrade_role_delete: 'Upgrade to delete role',
  create: 'Create',
  pm_trigger: 'Preventative Maintenance Trigger',
  auto_assign_wo: 'Auto-assign Work Orders',
  auto_assign_wo_description:
    'Automatically assign new work orders to the person that creates them',
  auto_assign_requests: 'Auto-assign requests',
  auto_assign_requests_description:
    'Automatically assign new work orders to the person who approve the request',
  disable_closed_wo_notification: 'Disable closed Work Order notifications',
  disable_closed_wo_notification_description:
    'Disable notifications when closed Work Orders are updated',
  ask_feedback_wo_closed: 'Ask for feedback when Work Order is closed',
  ask_feedback_wo_closed_description:
    'Users are asked to give feedback on the job done',
  include_labor_in_total_cost: 'Include labor cost in the total cost',
  include_labor_in_total_cost_description:
    'Add labor costs to the total when a user logs time and has an hourly rate stored',
  enable_wo_updates_requesters: 'Enable work order updates for Requesters',
  enable_wo_updates_requesters_description:
    'Users get updates for the work orders they requested',
  language: 'Language',
  date_format: 'Date format',
  business_type: 'Business type',
  general_asset_management: 'General asset management',
  physical_asset_management: 'Physical asset management',
  //checklist
  checklist_delete_success: 'The Checklist has been deleted successfully',
  checklist_delete_failure: 'The Checklist couldn\'t be deleted',
  create_checklist: 'Create Checklist',
  confirm_delete_checklist: 'Are you sure you want to delete this Checklist?',
  upgrade_checklist: 'Please Upgrade to use Checklists',
  //components
  upgrade_now: 'Upgrade Now',
  total: 'Total',
  sub_task_status: 'Sub-task Status',
  text_field: 'Text Field',
  number_field: 'Number Field',
  inspection_check: 'Inspection Check',
  multiple_choices: 'Multiple Choices',
  meter_reading: 'Meter Reading',
  select_user: 'Select User',
  PASS: 'Pass',
  FLAG: 'Flag',
  FAIL: 'Fail',
  enter_task_name: 'Enter a task name',
  see_details: 'See details',
  concerned_asset: 'Concerned Asset',
  me: 'Me',
  remove_blank_tasks: 'Remove blank tasks',
  remove_blank_options: 'Remove blank options',
  preview: 'Preview',
  edit_checklist: 'Edit Checklist',
  add_tasks: 'Add Tasks',
  use_a_checklist: 'Use a checklist',
  select_checklist: 'Select Checklist',
  save_checklist: 'Save Checklist',
  assign_tasks_description: 'Assign Custom Tasks for technicians to fill out',
  select_date: 'Select date',
  task: 'Task',
  checklist: 'Checklist',
  //analytics
  upgrade_analytics: 'Upgrade to see Analytics',
  grouped_by_category: 'Grouped by Category',
  downtime_by_asset: 'Downtime By Asset',
  downtime_and_costs: 'Downtime and Costs',
  downtime_and_costs_trends: 'Downtime and Costs trends',
  downtimes_trends: 'Downtimes trends',
  repair_time_by_asset: 'Repair time By Asset',
  consumed_parts_costs: 'Consumed Parts Costs',
  requests_by_priority: 'Requests by Priority',
  requests_trends: 'Requests trends',
  time_spent: 'Time spent',
  grouped_by_completed_by: 'Grouped by Completed By',
  grouped_by_assigned_to: 'Grouped by Assigned To',
  grouped_by_priority: 'Grouped by Priority',
  completion_comparison: 'Completion comparison',
  hours_worked: 'Hours Worked',
  work_remaining: 'Work Remaining',
  wo_status: 'Work Order Status',
  cost_trends: 'Cost trends',
  cost_center: 'Asset Lifecycle Cost Analysis',
  downtime_and_availability: 'Downtime and availability',
  incomplete_wo: 'Incomplete Work Orders',
  the_numbers: 'The numbers',
  hours_and_cost_by_asset: 'Hours and Cost By Asset',
  completion_comparison_description:
    'Comparison of various types of completed work orders.',
  wo_age_description:
    'Work order age is defined as the number of days between creation of the work order (or work request) and completion.',
  rav_description:
    'Total cost as % of RAV is equal to (cost of work orders on asset / purchase price)*100. Industry standard is a target value of 3% for this metric',
  downtime_and_availability_description:
    'Availability refers to the percentage that the assets were in an operational state since their placed in service date. Total downtimes refers to the number of downtime events that happened during the specified date range',
  total_cost_description:
    'Total cost includes labor cost, additional cost, and parts cost in a work order',
  compliant_wo_description:
    'Compliant work orders are defined as work orders that were completed before the due date. Cycle time refers to the number of days until a work order was completed.',
  hours_worked_description:
    'These hours correspond to work orders that have a due date within the range specified in the filters.',
  work_remaining_description:
    'This graph shows the number of incomplete work orders that are due in the date range specified in the filters. The estimated hours correspond to those individual work orders.',
  total_downtime_in_hours: 'Total Downtime (hours)',
  downtime_events: 'Downtime Events',
  percent_downtime: '% Downtime',
  meantime_between_failures_in_hours: 'Mean time between failures(hours)',
  availability_percent: 'Availability percentage',
  mean_time_to_repair_in_hours: 'Mean time to Repair(hours)',
  average_age: 'Average Age',
  count: 'Count',
  average_cost: 'Average Cost',
  compliant: 'Compliant',
  average_cycle_time_in_days: 'Average Cycle Time (Days)',
  total_consumption_cost: 'Total Consumption Cost',
  parts_consumed: 'Parts Consumed',
  total_cost_as_pct_rav: 'Total cost as Pct of RAV',
  approved_requests: 'Approved Requests',
  pending_requests: 'Pending Requests',
  cancelled_requests: 'Cancelled Requests',
  average_cycle_time: 'Average Cycle Time',
  compliance_rate: 'Compliance rate',
  complete: 'Complete',
  reactive: 'Reactive',
  recurring: 'Recurring',
  part_costs: 'Part Costs',
  labor_costs: 'Labor Cost',
  total_time_spent_in_hours: 'Total time spent (Hours)',
  estimated_hours: 'Estimated Hours',
  total_purchase_price: 'Total Purchase Price',
  you_have: 'You have',
  new_messages: 'new messages',
  //company profile
  company: 'Company',
  change_cover: 'Change cover',
  required_address: 'The address field is required',
  company_details: 'Company Details',
  company_details_description: 'Manage informations related to your company',
  learn_more: 'Learn more',
  //user profile
  profile: 'Profile',
  email_notifications: 'Email notifications',
  email_updates_wo: 'Email Updates for Work Orders and Messages',
  email_updates_requests: 'Email Updates for Requested Work Orders',
  po_emails: 'Purchase Order Emails',
  required_job_title: 'The Job title field is required',
  required_old_password: 'Please provide the current password.',
  invalid_password: 'Password is too short - should be 8 chars minimum.',
  required_new_password: 'No password provided.',
  passwords_must_match: 'Passwords must match',
  password_change_success: 'Password changed successfully',
  wrong_password: 'Wrong password provided',
  current_password: 'Current password',
  new_password: 'New password',
  confirm_password: 'Confirm password',
  personal_details: 'Personal Details',
  personal_details_description:
    'Manage informations related to your personal details',
  change_password: 'Change password',
  notification_settings: 'Notifications Settings',
  notification_settings_description:
    'Manage details related to your notifications',
  recent_activity: 'Recent Activity',
  created: 'Created',
  completed: 'Completed',
  my_account: 'My account',
  my_company: 'My company',
  you_are_using_plan:
    'You are currently using {{planName}} plan. It will expire on {{expiration}}. Upgrade now to get access to more features.',
  upgrade_plan: 'Upgrade Plan',
  //subscription
  plans: 'Plans',
  monthly: 'Monthly',
  annually: 'Annually',
  subscription_change_success: 'The Subscription has been changed successfully',
  invalid_credit_card: 'Credit Card number is invalid',
  required_credit_card: 'The card field is required',
  invalid_expiration_month: 'Expiration month is invalid',
  required_expiration_month: 'The Expiration month is required',
  invalid_expiration_year: 'Expiration year is invalid',
  required_expiration_year: 'The Expiration year is required',
  invalid_cvv: 'CVV is invalid',
  required_cvv: 'The CVV is required',
  required_cardholder_name: 'The cardholder name is required',
  card: 'Card',
  expiration_month: 'Expiration Month',
  expiration_year: 'Expiration Year',
  cardholder_name: 'Cardholder name',
  seats: 'Seats',
  cost_per_seat: 'Cost per seat',
  your_payment_secure: 'Your payment data is encrypted and secure.',
  plan: 'Plan',
  current_plan: 'Current Plan',
  number_users_who_will_use_grash: 'Number of users who will use Atlas',
  admin_role: 'Admin',
  technical_role: 'Technical',
  limited_technical_role: 'Limited Technical',
  requester_role: 'Requester',
  view_only_role: 'View-Only',
  how_will_you_be_billed: 'How would you like to be billed?',
  which_plan_fits_you: 'Which plan fits you best?',
  per_user_month: 'per user/month',
  per_user_year: 'per user/year',
  you_will_be_charged: 'You will be charged',
  proceed_to_payment: 'Proceed to Payment',
  pay_only_for_roles:
    'Pay only for <strong>Admin</strong>, <strong>Technical</strong> and <strong>Limited Technical</strong> users, and use unlimited <strong>Requester</strong>, <strong>View-Only</strong> for free',
  checkout_our: 'Check out our',
  pricing_page: 'Pricing Page',
  for_more_details: 'for more details',
  users_count_display: '{{count}} Users',
  monthly_adverb: 'monthly',
  yearly_adverb: 'yearly',
  features: 'Features',
  checkout: 'Checkout',
  checkout_description: 'Fill in the fields below',
  per_month: 'per month',
  per_year: 'per year',
  recover_password_description:
    'Enter the email used for registration to reset your password.',
  send_me_new_password: 'Send me a new password',
  want_to_sign_in_again: 'Want to try to sign in again?',
  check_mails_for_instructions: 'Check your email for further instructions',
  pdf_report: 'PDF Report',
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  NONE: 'None',
  import: 'Import',
  start_import_process: 'Start Import Process',
  import_data: 'Import Data',
  set_header: 'Set Header',
  match_columns: 'Match Columns',
  review: 'Review',
  done: 'Done',
  no_match_yet: 'No Match Yet',
  select: 'Select...',
  matched_to_field: 'Matched to {{field}}',
  next: 'Next',
  not_enough_rows: 'Not enough rows in the file',
  there_are_duplicates: 'There are duplicates: {{duplicates}}',
  assigned_by: 'Assigned By',
  percent_rows_have_value: '{{percent}}% of rows have value',
  match_at_least_column: 'Match at least a column',
  to_import: 'Import',
  required_match: '{{field}} matching is required',
  archived: 'Archived',
  import_wo_success:
    '{{created}} Work Orders have been created and {{updated}} have been updated',
  to_export: 'Export',
  filters: 'Filters',
  more_filters: 'More Filters',
  customer: 'Contractor',
  to: 'to',
  dates: 'Dates',
  start: 'Start',
  end: 'End',
  longitude: 'Longitude',
  latitude: 'Latitude',
  ALL: 'All',
  REACTIVE: 'Reactive',
  REPEATING: 'Preventive',
  import_location_success:
    '{{created}} Locations have been created and {{updated}} have been updated',
  import_part_success:
    '{{created}} Parts have been created and {{updated}} have been updated',
  import_meter_success:
    '{{created}} Meters have been created and {{updated}} have been updated',
  import_asset_success:
    '{{created}} Assets have been created and {{updated}} have been updated',
  wo_calendar: 'Work Orders Calendar',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  agenda: 'Agenda',
  previous: 'Previous',
  today: 'Today',
  to_filter: 'Filter',
  'noRows.asset.message':
    'Assets are resources on which your company can intervene',
  'noRows.asset.action': 'Press the \'+\' button to create a new Asset.',
  reset_filters: 'Reset Filters',
  toggle_menu: 'Toggle Menu',
  no_results: 'No results found',
  an_error_occured: 'An error occurred.',
  density: 'Density',
  compact: 'Compact',
  standard: 'Standard',
  comfortable: 'Comfortable',
  columns: 'Columns',
  select_columns: 'Select columns',
  show_filters: 'Show filters',
  hide_filters: 'Hide filters',
  search_placeholder: 'Search…',
  search: 'Search',
  clear: 'Clear',
  download_as_csv: 'Download as CSV',
  print: 'Print',
  download_as_excel: 'Download as Excel',
  find_column: 'Find column',
  column_title: 'Column title',
  reorder_column: 'Reorder column',
  show_all: 'Show all',
  hide_all: 'Hide all',
  add_filter: 'Add filter',
  logic_operator: 'Logic operator',
  operator: 'Operator',
  and: 'And',
  or: 'Or',
  filter_value: 'Filter value',
  contains: 'contains',
  equals: 'equals',
  starts_with: 'starts with',
  ends_with: 'ends with',
  is: 'is',
  is_not: 'is not',
  is_after: 'is after',
  is_on_or_after: 'is on or after',
  is_before: 'is before',
  is_on_or_before: 'is on or before',
  is_empty: 'is empty',
  is_not_empty: 'is not empty',
  is_any_of: 'is any of',
  any: 'any',
  true_text: 'true',
  false_text: 'false',
  menu: 'Menu',
  show_columns: 'Show columns',
  hide: 'Hide',
  unsort: 'Unsort',
  sort_by_ASC: 'Sort by ASC',
  sort_by_DESC: 'Sort by DESC',
  sort: 'Sort',
  total_rows: 'Total Rows:',
  yes: 'Yes',
  no: 'No',
  more: 'More',
  to_group: 'Group',
  see_children: 'see children',
  hide_children: 'hide children',
  expand: 'Expand',
  collapse: 'Collapse',
  detail_panel_toggle: 'Detail panel toggle',
  cancel_subscription: 'Cancel Subscription',
  resume_subscription: 'Resume Subscription',
  confirm_cancel_subscription:
    'Are you sure you want to cancel the subscription?',
  workflows: 'Workflows',
  create_workflow: 'Create Workflow',
  if: 'If',
  and_optional: 'And(Optional)',
  then: 'Then',
  workflow_description:
    'Create custom workflows as easy as If, And, Then. Save time and easily assign your work orders automatically through workflows to customize Atlas for the way your team operates.\nAll plans can create up to 1 custom workflow. To add additional workflows, upgrade to our Business plan',
  CREATE_PURCHASE_ORDER: 'Create a Purchase Order',
  PART_IS: 'The Part is',
  QUANTITY_INFERIOR: 'The quantity is inferior to',
  ASSIGN_VENDOR: 'Assign the vendor',
  ASSIGN_CATEGORY: 'Assign the category',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  SEND_REMINDER_EMAIL: 'Send reminder email',
  VENDOR_IS: 'The vendor is',
  CATEGORY_IS: 'The category is',
  STATUS_IS: 'The status is',
  DUE_DATE_BETWEEN: 'The Due date is between',
  DUE_DATE_AFTER: 'The Due date is after',
  ASSIGN_PRIORITY: 'Assign the priority',
  ASSIGN_ASSET: 'Assign the Asset',
  ASSIGN_LOCATION: 'Assign the Location',
  ASSIGN_USER: 'Assign the user',
  ASSIGN_TEAM: 'Assign the team',
  ADD_CHECKLIST: 'Add checklist',
  PRIORITY_IS: 'The priority is',
  ASSET_IS: 'The asset is',
  LOCATION_IS: 'The location is',
  USER_IS: 'The user is',
  TEAM_IS: 'The team is',
  CREATED_AT_BETWEEN: 'Is created between',
  TITLE_CONTAINS: 'The title contains',
  CREATE_REQUEST: 'Create a request',
  CREATE_WORK_ORDER: 'Create a Work Order',
  SET_ASSET_STATUS: 'Set asset status',
  NAME_IS: 'The name is',
  NAME_CONTAINS: 'The name contains',
  VALUE_IS: 'The value is',
  VALUE_CONTAINS: 'The value contains',
  NUMBER_VALUE_SUPERIOR: 'The value is superior to',
  NUMBER_VALUE_INFERIOR: 'The value is inferior to',
  WORK_ORDER_CREATED: 'A Work Order is created',
  WORK_ORDER_CLOSED: 'A Work Order is closed',
  WORK_ORDER_ARCHIVED: 'A Work Order is archived',
  REQUEST_CREATED: 'A request is created',
  REQUEST_APPROVED: 'A request is approved',
  REQUEST_REJECTED: 'A request is rejected',
  PURCHASE_ORDER_CREATED: 'A purchase order is created',
  PURCHASE_ORDER_UPDATED: 'A purchase order is updated',
  TASK_UPDATED: 'A task is updated',
  PART_UPDATED: 'A part is updated',
  add_condition: 'Add condition',
  workflow_creation_success: 'Workflow created successfully',
  workflow_creation_failure: 'Workflow creation didn\'t succeed',
  action_value_missing: 'The action value is missing',
  condition_value_missing: 'A condition value is missing',
  workflow_edit_success: 'The workflow has been updated successfully',
  workflow_edit_failure: 'The workflow couldn\'t be edited',
  workflow_delete_success: 'The workflow has been deleted successfully',
  workflow_delete_failure: 'The workflow couldn\'t be deleted',
  confirm_delete_workflow: 'Are you sure you want to delete this workflow',
  click_here: 'Click here',
  verify_email_title: 'Verify Email',
  verify_email_description:
    'A verification email has been sent to you. Check the spams if you don\'t see it in your inbox.',
  upgrade: 'Upgrade',
  downgrade: 'Downgrade',
  upgrade_description:
    'You just upgraded your subscription plan. You need to enable the disabled users who might use Atlas. You can only do this once for the current subscription. Please choose carefully',
  enable_users: 'Enable users',
  max_users_description: 'You can enable up to {{maxUsers}} users',
  downgrade_description:
    'You just downgraded your subscription plan. You need to disable some users in order to proceed. You can only do this once for the current subscription. Please choose carefully',
  disable_users: 'Disable users',
  min_users_description: 'You must disable a minimum of {{minUsers}} users',
  downgrade_description_no_owner:
    'Your company just downgraded its subscription plan. The company owner needs to disable some users before you can proceed',
  past_due: 'Past due',
  due_date_delay: 'Due Date delay',
  due_date_delay_description: 'Due Date delay in days',
  days_count: '{{days}} days',
  trigger_configuration: 'Trigger Configuration',
  WORK_ORDER_feature: 'Work Orders',
  REQUEST_feature: 'Work Requests',
  PART_feature: 'Parts Management',
  MOBILE_APP_feature: 'Mobile App',
  WORK_ORDER_HISTORY_feature: 'Work Orders History',
  PREVENTIVE_MAINTENANCE_feature: 'Preventive Maintenance',
  CHECKLIST_feature: 'Checklists',
  FILE_feature: 'File Uploads',
  PURCHASE_ORDER_feature: 'Purchase Orders',
  METER_feature: 'Meter Readings',
  REQUEST_CONFIGURATION_feature: 'Request Form configuration',
  ADDITIONAL_TIME_feature: 'Advanced Time tracking',
  ADDITIONAL_COST_feature: 'Advanced Cost tracking',
  ANALYTICS_feature: 'Analytics & Reporting',
  REQUEST_PORTAL_feature: 'Request Portal',
  SIGNATURE_feature: 'Signatures',
  ROLE_feature: 'Custom roles',
  WORKFLOW_feature: 'Workflow automation',
  API_ACCESS_feature: 'API Access',
  WEBHOOK_feature: 'Webhooks',
  add_new_option: 'Add new Option',
  edit_company: 'Edit company',
  fill_fields_below: 'Fill in the fields below',
  IMPORT_CSV_feature: 'CSV Importing',
  no_import_access: 'Upgrade your plan to import from CSV, Excel files',
  members: 'Members',
  assign_user: 'Assign User',
  assign_asset: 'Assign Asset',
  required_files_on_completion: 'Files are required on Work Order Completion',
  required_tasks_on_completion: 'Tasks must be completed',
  required_labor_on_completion: 'You must log time',
  required_part_on_completion: 'No Part has been used in this Work Order',
  required_cost_on_completion:
    'No Cost information provided in this Work Order',
  estimated_hours_in_text: '{{hours}} estimated hours',
  required_part_name: 'Part name is required',
  asset_update_failure: 'The Asset couldn\'t be edited',
  terms_of_service: 'Terms of service',
  privacy_policy: 'Privacy policy',
  last_login: 'Last Login',
  not_enough_part: 'There is not enough of this part',
  remove_blank_meter_tasks: 'Remove tasks with type meter without a meter',
  checklist_update_failure: 'The checklist could\'nt be edited',
  contact_us: 'Contact Us',
  simplify_wo: 'Simplify Work Orders in the mobile app',
  simplify_wo_description:
    'Hide time controls, costs and parts in the Work Order details while using the mobile app',
  account_deletion: 'Account deletion',
  switch_account: 'Switch account',
  switch_to_super_user: 'Switch to super admin',
  switch_account_description: 'Please choose an account to proceed',
  put_location_in_map: 'Put location in map',
  confirm_disable_user: 'Are you sure you want to disable {{user}}? You will only be able to enable it after one month',
  user_disabled_success: 'User disabled successfully',
  disable: 'Disable',
  manufacturer: 'Manufacturer',
  power: 'Power',
  import_error: 'The import didn\'t succeed',
  request_upgrade: 'Request upgrade',
  operation_success: 'The operation is a success',
  CANCELLED: 'Rejected',
  uptime_hours: 'Uptime(h)',
  downtime_hours: 'Downtime(h)',
  mark_all_as_seen: 'Mark all as seen',
  estimation_accuracy_ratio: 'Estimation accuracy ratio',
  download_template: 'Download template',
  pareto_by_total_price: 'Pareto by total price',
  cumulative_percent: 'Cumulative percent',
  part_consumption_by_asset: 'Part consumption by asset',
  grouped_by_wo_category: 'Grouped by work order category',
  resolution_rate: 'Resolution rate',
  resolved: 'Resolved',
  average: 'Average',
  documentation: 'Documentation',
  days_before_pm_notification: 'Pre-work order notifications for scheduled ones',
  estimated_start_date: 'Expected start date',
  no_file_linked_to_wo: 'No file attached to this work order',
  add_file: 'Add file',
  try_for_free: 'Try for free',
  choose_your_plan: 'Choose your plan',
  STARTER_description: 'Teams looking to build efficient and strong preventive maintenance through machine status and manpower visibility.',
  PROFESSIONAL_description: 'Departments that need to leverage insights and analytics to drive further maintenance growth and productivity.',
  BUSINESS_description: 'Organizations ready to capture maintenance & operations data to manage multiple locations & system customization.',
  book_demo: 'Book a demo',
  request_pricing: 'Request pricing for more information'
};

export default enJSON;
