const frJSON = {
  Blueprints: 'Plans',
  'Extended Sidebar': 'Barre latérale étendue',
  'Accent Sidebar': 'Barre latérale d\'accentuation',
  'Accent Header': 'En-tête accentué',
  'Boxed Sidebar': 'Barre latérale en boîte',
  'Collapsed Sidebar': 'Barre latérale réduite',
  'Bottom Navigation': 'Navigation du bas',
  'Top Navigation': 'Navigation supérieure',
  'Lead Developer': 'Développeur principal',
  'Mega menu': 'Méga menu',
  Doctors: 'Médecins',
  Reports: 'Rapports',
  'Custom dashboard built using the included components':
    'Tableau de bord personnalisé construit à l\'aide des composants inclus',
  Hospital: 'Hôpital',
  Export: 'Exportation',
  'Last year': 'L\'année dernière',
  Expenses: 'Dépenses',
  Products: 'Des produits',
  Statistics: 'Statistiques',
  General: 'Général',
  Automation: 'Automatisation',
  'Data Display': 'Affichage des données',
  Calendar: 'Calendrier',
  Mailbox: 'Boites aux lettres',
  Dashboards: 'Tableaux de bord',
  Analytics: 'Analytique',
  Banking: 'Bancaire',
  Commerce: 'Commerce',
  Crypto: 'Crypto',
  Finance: 'La finance',
  Fitness: 'Aptitude',
  Healthcare: 'Soins de santé',
  'Doctors Page': 'Page des médecins',
  'Hospital Overview': 'Aperçu de l\'hôpital',
  Helpdesk: 'Bureau d\'aide',
  Learning: 'Apprentissage',
  Monitoring: 'Surveillance',
  Tasks: 'Tâches',
  Applications: 'Applications',
  'File Manager': 'Gestionnaire de fichiers',
  'Jobs Platform': 'Plateforme d\'emploi',
  Messenger: 'Messager',
  'Projects Board': 'Conseil des projets',
  Management: 'La gestion',
  Users: 'Utilisateurs',
  'List All': 'Tout lister',
  'User Profile': 'Profil de l\'utilisateur',
  Projects: 'Projets',
  Shop: 'Magasin',
  'Products List': 'Liste de produits',
  'View Product': 'Voir le produit',
  'Create Product': 'Créer un produit',
  Invoices: 'Les factures',
  'View Single': 'Voir Single',
  'Auth Pages': 'Pages d\'authentification',
  Login: 'Se connecter',
  Basic: 'De base',
  Cover: 'Couvrir',
  Register: 'S\'inscrire',
  Wizard: 'Magicien',
  'Recover Password': 'Récupérer mot de passe',
  Status: 'Statut',
  Foundation: 'Fondation',
  'Extra Pages': 'Pages supplémentaires',
  'Error 404': 'Erreur 404',
  'Error 500': 'Erreur 500',
  'Coming Soon': 'À venir',
  Maintenance: 'Maintenance',
  Overview: 'Aperçu',
  'Layout Starters': 'Démarreurs de mise en page',
  'Starter Kit 1': 'Kit de démarrage 1',
  'Starter Kit 2': 'Kit de démarrage 2',
  Documentation: 'Documentation',
  Welcome: 'Bienvenue',
  Help: 'Aider',
  'Features tour': 'Visite des fonctionnalités',
  'Getting started guide': 'Guide de Démarrage',
  'Contact support': 'Contactez le support',
  Version: 'Version',
  Search: 'Chercher',
  Notifications: 'Notifications',
  Settings: 'Paramètres',
  'Language Switcher': 'Sélecteur de langue',
  'Sign out': 'Déconnexion',
  'Change Theme': 'Change le thème',
  'View all notifications': 'Afficher toutes les notifications',
  'Tokyo NextJS Typescript Admin Dashboard':
    'Tableau de bord d\'administration de Tokyo React',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Modèle React haute performance construit avec de nombreux composants Material-UI puissants dans plusieurs niches de produits pour des processus de développement d\'applications rapides et parfaits',
  'Browse Live Preview': 'Parcourir l\'aperçu en direct',
  'Live Preview': 'Aperçu en direct',
  'Key Features': 'Principales caractéristiques',
  dashboards: 'tableaux de bord',
  applications: 'applications',
  'management sections': 'sections de gestion',
  'dark/light themes': 'thèmes sombres / clairs',
  components: 'composants',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Certaines des fonctionnalités qui font de Tokyo l\'un des meilleurs modèles d\'administration disponibles aujourd\'hui',
  'Design Source Files': 'Fichiers source de conception',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Commencez à travailler sur votre projet directement à partir de l\'un des kits de démarrage inclus ou utilisez les fichiers Figma / Sketch pour créer d\'abord un prototype',
  'We only translated a small part of the template, for demonstration purposes':
    'Nous n\'avons traduit qu\'une petite partie du modèle, à des fins de démonstration',

  DUPLICATE_OF: 'Duplicata de',
  DUPLICATED_BY: 'Dupliqué par',
  RELATED_TO: 'Relié à',
  SPLIT_TO: 'Se divise en',
  SPLIT_FROM: 'Provient de',
  BLOCKED_BY: 'Bloqué par',
  BLOCKS: 'Bloque',
  MORE_THAN: 'Plus grand que',
  LESS_THAN: 'Moins que',
  OPEN: 'Ouvert',
  IN_PROGRESS: 'En cours',
  ON_HOLD: 'En attente',
  COMPLETE: 'Terminé',
  //home
  'home.built': 'Conçu pour faciliter la gestion de la maintenance',
  home_description:
    'Lorsque les tableaux blancs et les notes autocollantes sont chaotiques et que vous avez besoin d\'un logiciel de GMAO suffisamment facile à utiliser pour votre équipe, essayez Atlas',
  try_grash: 'Essayer Atlas',
  login: 'Se connecter',
  start_trial: 'Commencez un essai gratuit',
  'home.what': 'Ce que',
  'home.you_will_have': 'Vous allez avoir',
  'home.you_will_have_description':
    'Créez des flux de travail efficaces et fiables qui vous permettent de contrôler la maintenance. ',
  key_features: 'Principales fonctionnalités',
  key_features_description:
    'Certaines des fonctionnalités qui font de Atlas l\'une des meilleures GMAO disponibles aujourd\'hui',
  contribute: 'Contribuer',
  work_orders: 'Ordres de travail',
  request_system: 'Demandes d\'intervention',
  mobile_app: 'Application mobile',
  asset_management: 'Gestion d\'équipements',
  preventive_maintenance: 'Maintenance Préventive',
  parts_inventory: 'Inventaire des pièces',
  custom_dashboards: 'Tableaux de bord personnalisés',
  'work-orders.title': 'Gérez vos bons de travail comme un patron',
  'work-orders.description.short': 'Affichez et gérez toutes vos tâches',
  'work-orders.descriptions.0':
    'Vous en avez assez des rames de paperasse et des ordres de travail perdus? ',
  'work-orders.descriptions.1':
    'Avec Atlas, vous pouvez facilement et rapidement organiser tous vos ordres de travail, Maintenances Préventives et autres tâches au sein de votre équipe dans une base de données centralisée accessible n\'importe où, sur n\'importe quel appareil.',
  'work-orders.checks.0':
    'Affichez toutes vos tâches, y compris les interventions, les Maintenances préventives et les demandes de travail',
  'work-orders.checks.1':
    'Définissez la priorité des tâches afin que le bon travail soit effectué en premier.',
  'work-orders.checks.2':
    'Affichez un calendrier des tâches en cours et à venir.',
  'work-orders.checks.3':
    'Affichez les affectations de travail avec des informations critiques telles que les informations sur les équipements, la date d\'échéance, la priorité, etc.',
  'work-orders.checks.4':
    'Affichez les KPI critiques tels que le travail réellement achevé, le temps passé et le travail planifié par rapport au travail non planifié.',
  'work-requests.title': 'Un système simplifié de demande de travail mobile',
  'work-requests.descriptions.0':
    'Vous en avez assez d\'utiliser l\'ancien système de téléphone et de messagerie électronique qui entraîne des tickets PERDUS, des interruptions quotidiennes constantes et un travail en double?',
  'work-requests.descriptions.1':
    'La solution de gestion de la maintenance de Atlas permet à toute personne que vous autorisez de soumettre un problème à votre service de maintenance en scannant simplement un code QR (code à barres) ou en visitant une URL et en saisissant sa demande via ses appareils mobiles.',
  'work-requests.checks.0': 'Ne perdez plus jamais de travail.',
  'work-requests.checks.1':
    'Réduisez jusqu\'à 34% le temps consacré au traitement des demandes de travail.',
  'work-requests.checks.2':
    'Réduisez jusqu\'à 41% le temps de communication avec les demandeurs (appels, e-mails, SMS).',
  'mobile-app.title':
    'Une application GMAO mobile qui renforce votre équipe de maintenance',
  'mobile-app.descriptions.0':
    'Grâce à l\'utilisation de nos applications mobiles Android et IOS, nos prestataires bénéficient d\'une meilleure organisation, communication, responsabilité et productivité jusqu\'à 30% pour l\'ensemble de leur équipe de maintenance.',
  'mobile-app.descriptions.1':
    'À partir de leur appareil portable, n\'importe quel membre de votre équipe de maintenance peut :',
  'mobile-app.checks.0':
    'Voir les ordres de travail et MP qui leur sont assignés.',
  'mobile-app.checks.1':
    'Enregistrer leurs ordres de travail en moins de 60 secondes',
  'mobile-app.checks.2':
    'Recevoir des communications instantanées via des notifications push et par e-mail.',
  'mobile-app.checks.3':
    'Démarrer de nouveaux ordres de travail lorsque vous êtes sur le terrain.',
  'mobile-app.checks.4':
    'Répondre aux problèmes et les consigner tout en travaillant réellement sur le problème',
  'mobile-app.checks.5':
    'Rechercher des informations sur les équipements critiques tout en diagnostiquant un problème.',
  'eam.title': 'Gestion des équipements d\'entreprise',
  'eam.description.short': 'Connaissez mieux vos équipements',
  'eam.descriptions.0':
    'Frustré d\'essayer d\'organiser le désordre chaotique de l\'équipement de l\'entreprise, vous vous demandez si l\'équipement a été correctement entretenu, vous ne savez pas ce qu\'il en coûte pour l\'entretenir et tous les autres maux de tête qui accompagnent la gestion des équipements ?',
  'eam.descriptions.1':
    'Le module de gestion des équipements de Atlas, vous permet de suivre exactement ce que vous voulez, comme vous le souhaitez, dans une arborescence facile à utiliser et interrogeable.',
  'eam.checks.0':
    'Connaîssez la santé de vos équipements à tout moment et ce qu\'ils vous coûtent',
  'eam.checks.1': 'Affichez les journaux de maintenance complets et détaillés.',
  'eam.checks.2':
    'Suivez uniquement ce que vous voulez avec des champs personnalisés illimités',
  'eam.checks.3':
    'Organisez vos équipements dans une hiérarchie parent-enfant claire',
  'eam.checks.4':
    'Trouvez des informations rapidement et facilement avec les QR Codes (codes à barres)',
  'eam.checks.5':
    'Recevez des données d\'équipements en temps réel grâce à la connectivité des capteurs.',
  'pm.title': 'Un programme de maintenance préventive efficace',
  'pm.descriptions.0':
    'Arrêtez de perdre de précieuses heures à distribuer manuellement des checklists et à faire un suivi avec votre équipe pour vous assurer que le travail est fait.',
  'pm.descriptions.1':
    'Atlas vous permet d\'automatiser facilement la planification des interventions afin que le bon travail soit automatiquement livré au bon moment au bon technicien pour le bon équipement.',
  'pm.checks.0':
    'Automatisez la planification des interventions préventives pour qu\'elle soit quotidienne, hebdomadaire, mensuelle, annuelle ou basée sur des événements ou des relevés de compteurs.',
  'pm.checks.1':
    'Notifications push et e-mail automatiques lorsqu\'une intervention préventive est créée ou dûe.',
  'pm.checks.2':
    'Notre calendrier facile à utiliser vous permet de visualiser tous les horaires de travail ouverts et de maintenance préventive à venir.',
  'pm.checks.3': 'Faites glisser et déposez pour modifier la date d\'échéance.',
  'pm.checks.4':
    'Une fois l\'intervention préventive terminée, le travail est automatiquement enregistré dans le journal de maintenance de l\'équipement.',
  'pm.checks.5': 'Nécessite peu ou pas de formation à utiliser.',
  'part.title': 'Prenez le contrôle de votre inventaire de pièces de rechange',
  'part.description.short': 'Sachez quand vos pièces manquent',
  'part.descriptions.0':
    'Vous ne savez pas quelles pièces vous avez en stock ou où elles sont utilisées ? ',
  'part.descriptions.1':
    'Nous avons conçu la gestion des pièces de Atlas pour résoudre ces problèmes et plus encore :',
  'part.checks.0':
    'Recevez des notifications push et par e-mail instantanées lorsque la quantité d\'une pièce est faible. ',
  'part.checks.1':
    'Les techniciens savent instantanément si la pièce est en stock à partir d\'un bon de travail. ',
  'part.checks.2': 'Suivi automatique de l\'utilisation des pièces. ',
  'part.checks.3': 'Connaître l\'utilisation des pièces. ',
  'part.checks.4': 'Éliminez les pièces inutilisées. ',
  'part.checks.5': 'Rapports en temps réel. ',
  'part.checks.6': 'Optimisez les niveaux de stock. ',
  'part.checks.7': 'Recherche de code-barres. ',
  'dashboard.title':
    'Vos données, à votre façon avec des tableaux de bord personnalisés',
  'dashboard.descriptions.0':
    'Avouons-le, la première étape pour améliorer vos opérations est de connaître le bon, le mauvais et le laid.',
  'dashboard.descriptions.1':
    'Avec les tableaux de bord personnalisés de Atlas, vous pouvez enfin commencer à comprendre cet infâme « trou noir de la maintenance » et commencer à mettre en œuvre des plans basés sur des données, ce qui entraîne une réduction des coûts de main-d\'œuvre, une diminution des temps d\'arrêt et plus encore.',
  'dashboard.descriptions.2':
    'Avec les tableaux de bord personnalisés de Atlas, vous pourrez…',
  'dashboard.checks.0': 'Savoir où votre argent est dépensé et pourquoi',
  'dashboard.checks.1': 'Savoir quel équipement coûte le plus cher et pourquoi',
  'dashboard.checks.2': 'Créer vos propres KPI personnalisés.',
  'dashboard.checks.3':
    'Afficher des rapports générés et mis à jour automatiquement en fonction de votre métrique',
  'dashboard.checks.4':
    'Afficher des rapports facilement partageables via des feuilles Excel, des PDF ou dans Atlas',
  'dashboard.checks.5': 'Et bien plus ...',
  'home.smarter': 'Plus intelligent',
  'home.work': 'Travail',
  'home.smarter_description':
    'Atlas permet aux techniciens de travailler efficacement en toute simplicité en leur donnant accès aux outils et aux données dont ils ont besoin pour accomplir leur travail, le tout depuis la paume de leur main.',
  leading_maintenance:
    'Ouvrir la voie à un avenir meilleur pour la maintenance et la fiabilité',
  //register
  premium_included: 'Fonctionnalités premium incluses',
  no_credit_card: 'Pas de carte de crédit nécessaire',
  perfect_tool: 'L\'outil parfait pour les équipes de maintenance',
  start_trial_today: 'Commencez votre essai gratuit aujourd\'hui',
  create_account: 'Créer un compte',
  signup_description: 'Remplissez les champs ci-dessous pour ouvrir un compte.',
  account_already: 'Vous avez déjà un compte?',
  signin_here: 'Se connecter ici',

  invalid_email: 'L\'e-mail fourni doit être une adresse e-mail valide',
  required_email: 'Le champ email est obligatoire',
  required_firstName: 'Le champ prénom est obligatoire',
  required_lastName: 'Le champ nom de famille est obligatoire',
  required_company: 'Le champ nom de l\'entreprise est obligatoire',
  required_employeesCount: 'Veuillez indiquer le nombre d\'employés',
  invalid_phone: 'Le numéro de téléphone est invalide',
  required_password: 'Le champ mot de passe est obligatoire',
  required_terms: 'Vous devez accepter nos termes et conditions',
  verify_email: 'Un email vous a été envoyé pour activer votre compte',
  registration_error: 'L\'inscription n\'a pas réussi',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  email: 'Adresse e-mail',
  phone: 'Téléphone fixe',
  password: 'Mot de passe',
  companyName: 'Nom de l\'entreprise',
  employeesCount: 'Nombre d\'employés',
  i_accept: 'J\'accepte les',
  terms_conditions: 'termes et conditions',
  create_your_account: 'Créer votre compte',
  login_description:
    'Remplissez les champs ci-dessous pour vous connecter à votre compte.',
  no_account_yet: 'Vous n\'avez pas encore de compte?',
  signup_here: 'Inscrivez-vous ici',
  wrong_credentials: 'Informations d\'identification erronées fournies',
  lost_password: 'Mot de passe oublié ?',
  register: 'S\'inscrire',
  //work-orders
  link_wo: 'Lier les bons de travail',
  link_wo_description: 'Créer des relations entre les ordres de travail',
  required_relationType: 'Veuillez sélectionner le type de relation.',
  required_wo: 'Le champ Bon de commande est obligatoire.',
  this_wo: 'Cet ordre de travail',
  the_wo: 'L\'ordre de travail',
  link: 'Lier',
  cost_description: 'Descriptif des coûts',
  assigned_to: 'Assigné à',
  category: 'Catégorie',
  date: 'Date',
  cost: 'Coût',
  include_cost: 'Inclure ce coût dans le coût total',
  include_cost_description:
    'Cela ajoutera le coût au coût total dépensé pour le bon de travail',
  required_cost_description: 'La description du coût est requise',
  required_cost: 'Le coût est requis',
  add_cost: 'Ajouter un coût supplémentaire',
  add_cost_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un coût supplémentaire',
  add: 'Ajouter',
  hourly_rate: 'Taux horaire',
  include_time: 'Inclure ce temps dans le temps total',
  include_time_description:
    'Cela ajoutera la durée au temps total passé sur le bon de travail',
  work_started_at: 'Le travail a commencé à',
  duration: 'Durée',
  hours: 'Heures',
  minutes: 'Minutes',
  required_hours: 'Le champ Heures est requis',
  required_minutes: 'Le champ des minutes est obligatoire',
  add_time: 'Ajouter du temps',
  add_time_description:
    'Remplissez les champs ci-dessous pour créer et ajouter du temps',
  feedback: 'Feedback',
  feedback_description: 'Donnez votre avis',
  required_feedback: 'L\'avis est requis',
  signature: 'Signature',
  required_signature: 'L\'image est requise',
  close_wo: 'Terminer le bon de travail',
  close: 'Fermer',
  task_update_success: 'Tâche mise à jour avec succès',
  task_update_failure: 'La tâche n\'a pas pu être mise à jour',
  notes_save_success: 'Notes enregistrées avec succès',
  images_add_task_success: 'Les images ont été ajoutées à la tâche',
  images_add_task_failure: 'Quelque chose s\'est mal passé',
  images: 'Images',
  required_images: 'Veuillez téléverser au moins une image',
  add_images: 'Ajouter des images',
  tasks: 'Tâches',
  wo_archive_success: 'Le bon de travail a été archivé',
  wo_archive_failure: 'Le bon de travail n\'a pas pu être archivé',
  wo_archive_confirm: 'Êtes-vous sûr de vouloir archiver ce bon de travail ',
  quantity_change_success: 'Quantité modifiée avec succès',
  quantity_change_failure: 'La quantité n\'a pas pu être modifiée',
  details: 'Détails',
  updates: 'Historique',
  id: 'ID',
  due_date: 'Date d\'échéance',
  location: 'Emplacement',
  asset: 'Équipement',
  team: 'Équipe',
  created_at: 'Date créée',
  list_view: 'Vue liste',
  calendar_view: 'Vue calendrier',
  column_view: 'Vue colonnes',
  wo_create_success: 'Le bon de travail a été créé avec succès',
  wo_create_failure: 'Le bon de travail n\'a pas pu être créé',
  changes_saved_success: 'les changements ont été enregistrés',
  wo_update_failure: 'Le bon de travail n\'a pas pu être modifié',
  wo_delete_success: 'Le bon de travail a été supprimé avec succès',
  wo_delete_failure: 'Le bon de travail n\'a pas pu être supprimé',
  status: 'Statut',
  title: 'Titre',
  priority: 'Priorité',
  description: 'Description',
  worker: 'Travailleur',
  location_name: 'Nom de l\'emplacement',
  location_address: 'Adresse de l\'emplacement',
  asset_name: 'Nom de l\'équipement',
  days_since_creation: 'Jours depuis la création',
  files: 'Fichiers',
  requested_by: 'Demandé par',
  completed_on: 'Terminé le',
  updated_at: 'Modifié le',
  work_order: 'Ordre de travail',
  timer_running: 'Minuterie en cours d\'exécution',
  run_timer: 'Démarrer la minuterie',
  approved_by: 'Approuvé par',
  created_by: 'Créé par',
  completed_by: 'Terminé par',
  time: 'Temps',
  save: 'Sauvegarder',
  customers: 'Prestataires',
  no_labor: 'Aucun coût de main-d\'œuvre n\'a encore été ajouté. ',
  not_assigned: 'Non attribué',
  no_additional_cost: 'Aucun coût supplémentaire n\'a encore été ajouté',
  parts: 'Pièces',
  links: 'Liens',
  confirm_delete_file_wo:
    'Voulez-vous vraiment supprimer ce fichier de ce bon de travail ?',
  archive: 'Archiver',
  labors: 'Main d\'oeuvre',
  add_additional_cost: 'Ajouter un coût supplémentaire',
  additional_costs: 'Coûts supplémentaires',
  add_parts: 'Ajouter des pièces',
  'wo.title_description': 'Entrez le titre du bon de travail',
  image: 'Image',
  estimated_duration: 'Durée estimée en heures',
  primary_worker: 'Travailleur principal',
  additional_workers: 'Travailleurs supplémentaires',
  select_team: 'Choisis une équipe',
  select_location: 'Sélectionnez l\'emplacement',
  select_asset: 'Sélectionnez l\'équipement',
  select_tasks: 'Sélectionnez les tâches',
  requires_signature: 'Nécessite une signature',
  required_wo_title: 'Le titre de l\'ordre de travail est requis',
  add_wo: 'Ajouter un bon de travail',
  add_wo_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un nouveau bon de travail',
  edit_wo: 'Modifier le bon de travail',
  edit_wo_description:
    'Remplissez les champs ci-dessous pour mettre à jour le bon de travail',
  'noRows.wo.message':
    'Les ordres de travail sont des tâches ou des travaux qui peuvent être planifiés ou attribués à quelqu\'un',
  'noRows.wo.action': 'Appuyez sur le bouton \'+\' pour créer un bon de travail',
  to_delete: 'Supprimer',
  confirm_delete_wo: 'Voulez-vous vraiment supprimer ce bon de travail ?',
  no_access_wo: 'Vous n\'avez pas accès aux bons de travail. ',
  cancel: 'Annuler',
  select_parts: 'Sélectionner des pièces',
  sets_of_parts: 'Jeux de pièces',
  //pm
  paused: 'En pause',
  trigger_details: 'Détails du déclencheur',
  starts_on: 'Commence le',
  ends_on: 'Se termine le',
  frequency: 'Fréquence',
  every_frequency_days: 'Tous les {{frequency}} jours',
  wo_details: 'Détails du bon de travail',
  confirm_delete_file: 'Voulez-vous vraiment supprimer ce fichier ?',
  wo_schedule_success: 'Ordre de travail planifié avec succès',
  wo_schedule_failure: 'L\'ordre de travail n\'a pas pu être planifié',
  wo_trigger_edit_failure:
    'Impossible de modifier le déclencheur d\'ordre de travail',
  wo_trigger_delete_success:
    'Le déclencheur d\'ordre de travail a été supprimé avec succès',
  wo_trigger_delete_failure:
    'Le déclencheur d\'ordre de travail n\'a pas pu être supprimé',
  name: 'Nom',
  wo_title: 'Titre de l\'ordre de travail',
  next_wo: 'Prochain ordre de travail',
  trigger_name: 'Nom du déclencheur',
  enter_trigger_name: 'Entrez le nom du déclencheur',
  frequency_description: 'Fréquence en jours',
  wo_configuration: 'Configuration de l\'ordre de travail',
  required_trigger_name: 'Le nom du déclencheur est requis',
  required_frequency: 'La fréquence de déclenchement est requise',
  invalid_frequency: 'La fréquence doit être supérieure à 0',
  schedule_wo: 'Planifier l\'ordre de travail',
  schedule_wo_description:
    'Remplissez les champs ci-dessous pour planifier un bon de travail',
  edit_wo_trigger: 'Modifier le déclencheur d\'ordre de travail',
  edit_wo_trigger_description:
    'Remplissez les champs ci-dessous pour modifier le déclencheur d\'ordre de travail',
  create_trigger: 'Créer un déclencheur',
  'noRows.pm.message':
    'Planifier des ordres de travail avec des déclencheurs personnalisés',
  'noRows.pm.action': 'Appuyez sur le bouton \'+\' pour créer un déclencheur',
  confirm_delete_pm:
    'Voulez-vous vraiment supprimer ce déclencheur d\'ordre de travail ?',
  no_access_pm: 'Vous n\'avez pas accès à la section Maintenance préventive. ',
  enter_wo_title: 'Entrez le titre du bon de travail',
  //menuItems
  status_report: 'Rapport de situation',
  wo_analysis: 'Analyse des bons de travail',
  wo_aging: 'Ancienneté des bons de travail',
  time_and_cost: 'Temps et coût',
  assets: 'Équipements',
  reliability_dashboard: 'Tableau de bord de fiabilité',
  total_maintenance_cost: 'Coût d\'entretien total',
  useful_life: 'Vie utile',
  requests: 'Demandes',
  locations: 'Emplacements',
  parts_and_inventory: 'Pièces/Inventaire',
  purchase_orders: 'Bons de commande',
  meters: 'Compteurs',
  people_teams: 'Personnes & Equipes',
  people: 'Personnes',
  teams: 'Équipes',
  vendors_customers: 'Fournisseurs/Prestataires',
  vendors: 'Fournisseurs',
  categories: 'Catégories',
  settings: 'Paramètres',
  //request
  cancelled: 'Annulé',
  reject: 'Rejeter',
  approve: 'Approuver',
  request_create_success: 'Bon de travail demandé avec succès',
  request_create_failure: 'La demande de travail n\'a pas pu être créée',
  request_edit_failure: 'La demande n\'a pas pu être modifiée',
  request_delete_success: 'La demande a été supprimée avec succès',
  request_delete_failure: 'La demande n\'a pas pu être supprimée',
  rejected: 'Rejeté',
  approved: 'Approuvé',
  pending: 'En attente',
  required_request_name: 'Le titre de la demande est requis',
  required_field: 'Ce champ est obligatoire',
  add_request: 'Ajouter une demande',
  add_request_description:
    'Remplissez les champs ci-dessous pour créer et ajouter une nouvelle demande',
  edit_request: 'Modifier la demande',
  edit_request_description:
    'Remplissez les champs ci-dessous pour modifier la demande',
  'noRows.request.message': 'Gérez vos demandes de travail',
  'noRows.request.action':
    'Appuyez sur le bouton \'+\' pour créer une demande de travail',
  confirm_delete_request: 'Voulez-vous vraiment supprimer cette demande ?',
  request: 'Demande',
  request_details: 'Détails sur la demande',
  none_priority: 'Aucune',
  low_priority: 'Basse',
  medium_priority: 'Moyenne',
  high_priority: 'Haute',
  //asset
  downtimes: 'Temps d\'arrêt',
  confirm_delete_file_asset:
    'Voulez-vous vraiment supprimer ce fichier de cet équipement?',
  actions: 'Actions',
  show: 'Montrer',
  model: 'Modèle',
  serial_number: 'Numéro de série',
  operational: 'Opérationnel',
  down: 'En panne',
  area: 'Zone',
  barcode: 'Code à barre',
  placed_in_service: 'Mis en service',
  warranty_expiration: 'Expiration de la garantie',
  asset_information: 'Informations sur l\'équipement',
  more_informations: 'Plus d\'informations',
  confirm_delete_asset_downtime:
    'Voulez-vous vraiment supprimer ce temps d\'arrêt ?',
  create_downtime_success: 'Le temps d\'arrêt a été ajouté avec succès',
  create_downtime_failure: 'Le temps d\'arrêt n\'a pas pu être ajouté',
  edit_downtime_success: 'Le temps d\'arrêt a été modifié avec succès',
  started_on: 'A Commencé le',
  required_startsOn: 'La date de début est obligatoire',
  reading_create_success: 'Le relevé a été ajouté avec succès',
  reading_create_failure: 'Le relevé n\'a pas pu être ajouté',
  reading: 'Relevé',
  added_by: 'Ajouté par',
  enter_meter_value: 'Entrez la valeur du compteur',
  add_reading: 'Ajouter un relevé',
  select_meter: 'Sélectionnez le compteur',
  confirm_delete_part_asset:
    'Voulez-vous vraiment supprimer cette pièce de cet équipement ?',
  no_due_date: 'Pas de date d\'échéance',
  due_at_date: 'Dû le {{date}}',
  no_primary_worker: 'Aucun assigné',
  no_wo_linked_asset: 'Il n\'y a pas de bon de travail lié à cet équipement',
  asset_remove_success: 'L\'équipement a été supprimé avec succès',
  asset_remove_failure: 'L\'équipement n\'a pas pu être supprimé',
  asset_name_description: 'Entrez le nom de l\'équipement',
  select_asset_location: 'Sélectionnez l\'emplacement de l\'équipement',
  primary_user_description: 'Sélectionnez l\'utilisateur principal',
  teams_description: 'Sélectionnez les équipes',
  customers_description: 'Sélectionnez des prestataires',
  vendors_description: 'Sélectionnez les fournisseurs',
  inServiceDate_description: 'Date de mise en service',
  warranty_expiration_date: 'Date d\'expiration de la garantie',
  additional_information: 'Informations supplémentaires',
  structure: 'Structure',
  parent_asset: 'Équipement parent',
  asset_create_success: 'L\'équipement a été créé avec succès',
  asset_create_failure: 'L\'équipement n\'a pas pu être créé',
  additional_workers_description:
    'Sélectionnez des travailleurs supplémentaires',
  required_asset_name: 'Le nom de l\'équipement est requis',
  add_asset: 'Ajouter un équipement',
  add_asset_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un nouvel équipement',
  create_asset: 'Créer un équipement',
  hierarchy: 'Hiérarchie',
  add_downtime: 'Ajouter un temps d\'arrêt',
  edit_downtime: 'Modifier le temps d\'arrêt',
  remove_downtime: 'Supprimer le temps d\'arrêt',
  edit: 'Éditer',
  edit_asset: 'Modifier l\'équipement',
  edit_asset_description:
    'Remplissez les champs ci-dessous pour modifier cet équipement',
  confirm_delete_asset: 'Etes-vous sûrs de vouloir supprimer cet équipement ?',
  no_content: 'Pas de contenu',
  no_result_criteria: 'Aucun résultat pour vos critères de recherche',
  loading_assets: 'Chargement des équipements enfants de {{name}} #{{id}}',
  //location
  map_view: 'Vue carte',
  location_create_success: 'L\'emplacement a été créé avec succès',
  location_create_failure: 'L\'emplacement n\'a pas pu être créé',
  location_edit_failure: 'Impossible de modifier l\'emplacement',
  location_delete_success: 'L\'emplacement a été supprimé avec succès',
  location_delete_failure: 'L\'emplacement n\'a pas pu être supprimé',
  enter_location_name: 'Entrez le nom de l\'emplacement',
  address: 'Adresse',
  parent_location: 'Emplacement parent',
  select_workers: 'Sélectionnez les travailleurs',
  map_coordinates: 'Coordonnées cartographiques',
  required_location_name: 'Le nom de l\'emplacement est requis',
  required_location_address: 'L\'adresse de l\'emplacement est obligatoire',
  add_location: 'Ajouter un emplacement',
  add_location_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un nouvel emplacement',
  edit_location: 'Modifier l\'emplacement',
  edit_location_description:
    'Remplissez les champs ci-dessous pour mettre à jour l\'emplacement',
  confirm_delete_location:
    'Êtes-vous sûr de vouloir supprimer cet emplacement?',
  floor_plans: 'Plans d\'étage',
  floor_plan: 'Plan d\'étage',
  floor_plan_name_description: 'Nom du plan d\'étage',
  'Floor plan area in m²': 'Superficie du plan d\'étage en m²',
  upload_image: 'Télécharger une image',
  required_floor_plan_name: 'Le nom du plan d\'étage est requis',
  add_floor_plan: 'Ajouter un nouveau plan d\'étage',
  add_floor_plan_description:
    'Remplissez les champs ci-dessous pour créer un nouveau plan d\'étage',
  no_asset_in_location: 'Aucun équipement à cet emplacement',
  no_wo_in_location: 'Aucun ordre de travail à cet emplacement',
  no_file_in_location: 'Aucun fichier joint à cet emplacement',
  no_floor_plan_in_location: 'Pas de plan d\'étage à cet emplacement',
  assigned_teams: 'Équipes assignées',
  assigned_customers: 'Prestataires assignés',
  assigned_vendors: 'Fournisseurs affectés',
  no_access_location:
    'Vous n\'avez pas accès aux emplacements. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_vendors_customers:
    'Vous n\'avez pas accès aux fournisseurs et aux prestataires.Veuillez contacter votre administrateur si vous devriez avoir accès. ',
  no_access_people_team:
    'Vous n\'avez pas accès aux personnes et aux équipes. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_analytics:
    'Vous n\'avez pas accès à Analytics. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_assets:
    'Vous n\'avez pas accès aux ressources. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_categories:
    'Vous n\'avez pas accès aux catégories. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_files:
    'Vous n\'avez pas accès aux fichiers. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_inventory:
    'Vous n\'avez pas accès à l\'inventaire. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_meters:
    'Vous n\'avez pas accès aux compteurs. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_purchase_orders:
    'Vous ne pouvez pas accès aux bons de commande. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_requests:
    'Vous n\'avez pas accès aux demandes. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_settings:
    'Vous n\'avez pas accès aux paramètres. Veuillez contacter votre administrateur si vous devriez avoir accès.',
  no_access_page: 'Vous n\'avez pas accès à cette page',
  file: 'Fichier',
  'noRows.location.message':
    'Les emplacements vous permettent de gérer plus efficacement les équipements et les travailleurs',
  'noRows.location.action':
    'Appuyez sur le bouton \'+\' pour créer un emplacement',
  //inventory
  card_view: 'Vue Carte',
  set_create_success: 'L\'ensemble a été créé avec succès',
  set_create_failure: 'L\'ensemble n\'a pas pu être créé',
  set_edit_failure: 'L\'ensemble n\'a pas pu être modifié',
  set_delete_success: 'L\'ensemble a été supprimé avec succès',
  set_delete_failure: 'L\'ensemble n\'a pas pu être supprimé',
  total_cost: 'Coût total',
  part: 'Pièce',
  enter_part_name: 'Entrez le nom de la pièce',
  required_set_name: 'Le nom de l\'ensemble est requis',
  add_set: 'Ajouter un ensemble',
  add_set_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un nouvel ensemble',
  create_set: 'Créer un ensemble',
  edit_set: 'Modifier l\'ensemble',
  edit_set_description:
    'Remplissez les champs ci-dessous pour modifier l\'ensemble',
  confirm_delete_set: 'Voulez-vous vraiment supprimer cet ensemble ?',
  part_create_success: 'La pièce a été créée avec succès',
  part_create_failure: 'La pièce n\'a pas pu être créée',
  part_edit_failure: 'La pièce n\'a pas pu être modifiée',
  part_delete_success: 'La pièce a été supprimée avec succès',
  part_delete_failure: 'La pièce n\'a pas pu être supprimée',
  quantity: 'Quantité',
  open_wo: 'Bons de travail ouverts',
  enter_part_category: 'Entrez la catégorie de pièce',
  enter_part_cost: 'Entrez le coût de la pièce',
  enter_part_quantity: 'Entrez la quantité de pièces',
  minimum_quantity: 'Quantité minimale',
  enter_part_minimum_quantity: 'Entrez la quantité minimale de pièces',
  non_stock: 'Hors stock',
  enter_part_barcode: 'Entrez le code-barres de la pièce',
  enter_part_area: 'Entrez dans la zone de pièce',
  additional_part_details: 'Détails supplémentaires sur les pièces',
  add_part: 'Ajouter une pièce',
  add_part_description:
    'Remplissez les champs ci-dessous pour créer et ajouter une nouvelle pièce',
  create_part: 'Créer une pièce',
  update_part: 'Mettre à jour la pièce',
  update_part_description:
    'Remplissez les champs ci-dessous pour mettre à jour la pièce',
  'noRows.part.message':
    'Les pièces peuvent être combinées en jeux de pièce ou utilisées seules',
  'noRows.part.action': 'Appuyez sur le bouton \'+\' pour créer une Pièce',
  confirm_delete_part: 'Voulez-vous vraiment supprimer cette pièce?',
  part_details: 'Détails de la pièce',
  area_details: 'Détails de la zone',
  assigned_people: 'Personnes affectées',
  no_asset_related_part: 'Aucun équipement lié à cette pièce',
  no_file_found: 'Aucun fichier trouvé',
  no_wo_found: 'Aucun bon de travail trouvé',
  workers: 'Travailleurs',
  Parts_and_Inventory: 'Pièces & Inventaire',
  //purchase order
  po_edit_failure: 'Le bon de commande n\'a pas pu être modifié',
  po_delete_success: 'Le bon de commande a été supprimé avec succès',
  po_delete_failure: 'Le bon de commande n\'a pas pu être supprimé',
  number_of_items: 'Nombre d\'objets',
  total_quantity: 'Quantité totale',
  shipping_to: 'Expédition à',
  shipping_address: 'Adresse de livraison',
  po_details: 'Détails du bon de commande',
  enter_po_name: 'Saisissez le nom du bon de commande',
  additional_details: 'Détails supplémentaires',
  vendor: 'Fournisseur',
  shipping_information: 'Informations sur la livraison',
  company_name: 'Nom de l\'entreprise',
  ship_to: 'Envoyer à',
  city: 'Ville',
  state: 'État',
  zip_code: 'Code postal',
  fax_number: 'Numéro de fax',
  po_date: 'Date du bon de commande',
  notes: 'Remarques',
  add_notes: 'Ajouter des remarques',
  requisitioner: 'Demandeur',
  terms: 'Termes',
  shipping_method: 'Mode de livraison',
  required_name: 'Le nom est obligatoire',
  edit_po: 'Modifier le bon de commande',
  edit_po_description:
    'Remplissez les champs ci-dessous pour mettre à jour le bon de commande',
  purchase_order: 'Bon de commande',
  'noRows.po.message': 'Gérez vos bons de commande en un seul endroit',
  'noRows.po.action':
    'Appuyez sur le bouton \'+\' pour créer un bon de commande.',
  confirm_delete_po: 'Voulez-vous vraiment supprimer ce bon de commande ?',
  upgrade_po: 'Mettre à niveau pour créer des bons de commande',
  new_po: 'Nouveau bon de commande',
  po_create_success: 'Le bon de commande a été créé avec succès',
  select_one_part: 'Sélectionnez au moins 1 pièce ',
  po_create_failure: 'Le bon de commande n\'a pas pu être créé',
  approve_while_submitting: 'Approuver lors de la soumission',
  invalid_fax: 'Le numéro de fax n\'est pas valide',
  submit: 'Soumettre',
  each_item_superior_zero: 'Chaque quantité de pièce doit être supérieure à 0',
  quantity_edit_success: 'Quantité modifiée avec succès',
  quantity_edit_failure: 'La quantité n\'a pas pu être modifiée',
  APPROVED: 'Approuvé',
  PENDING: 'En attente',
  REJECTED: 'Rejeté',
  //meters
  when_reading_is: 'Lorsque le relevé du compteur est',
  greater_than: 'Supérieure à',
  lower_than: 'Inférieure à',
  value: 'Évaluer',
  required_value: 'La valeur est requise',
  required_trigger_condition: 'La condition est requise',
  wo_trigger_create_success:
    'Le déclencheur d\'ordre de travail a été créé avec succès',
  wo_trigger_create_failure:
    'Le déclencheur d\'ordre de travail n\'a pas pu être créé',
  add_wo_trigger: 'Ajouter un déclencheur d\'ordre de travail',
  add_wo_trigger_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un déclencheur d\'ordre de travail',
  wo_trigger_edit_success:
    'Le déclencheur d\'ordre de travail a été mis à jour avec succès',
  meter_create_success: 'Le compteur a été créé avec succès',
  meter_create_failure: 'Le compteur n\'a pas pu être créé ',
  meter_edit_failure: 'Impossible de modifier le compteur',
  meter_delete_success: 'Le compteur a été supprimé avec succès',
  meter_delete_failure: 'Le compteur n\'a pas pu être supprimé',
  next_reading_due: 'Prochain relevé',
  unit_of_measurement: 'Unité de mesure',
  last_reading: 'Dernier relevé',
  enter_meter_name: 'Entrez le nom du compteur',
  unit: 'Unité',
  update_frequency: 'Fréquence de mise à jour',
  update_frequency_in_days: 'Fréquence de mise à jour en jours',
  required_meter_name: 'Le nom du compteur est requis',
  required_meter_unit: 'L\'unité de compteur est requise',
  required_meter_update_frequency:
    'La fréquence de mise à jour du compteur est requise',
  required_asset: 'L\'équipement est requis',
  add_meter: 'Ajouter un compteur',
  add_meter_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un nouveau Compteur',
  edit_meter: 'Modifier le compteur',
  edit_meter_description:
    'Remplissez les champs ci-dessous pour modifier le compteur',
  confirm_delete_meter: 'Voulez-vous vraiment supprimer ce compteur ?',
  upgrade_create_meter: 'Mettre à niveau pour créer des compteurs',
  history: 'Historique',
  reading_frequency: 'Fréquence de relevés',
  required_reading_value: 'La valeur du relevé est requise',
  wo_triggers: 'Déclencheurs d\'ordre de travail',
  add_trigger: 'Ajouter un déclencheur',
  meter_details: 'Détails du compteur',

  'noRows.meter.message':
    'Les relevés de compteur vous permettent de surveiller les équipements et de déclencher de nouveaux ordres de travail en fonction de conditions définies',
  'noRows.meter.action': 'Appuyez sur le bouton \'+\' pour créer un Compteur',
  meter: 'Compteur',
  drag_one_file: 'Faites glisser un seul fichier ici',
  drag_many_files: 'Faites glisser plusieurs fichier ici',
  drop_to_start: 'Déposez les fichiers pour commencer le téléchargement',
  invalid_files_type: 'Vous ne pouvez pas télécharger ces types de fichiers',
  you_have_uploaded: 'Vous avez téléchargé',
  upload: 'Télécharger',
  Vendors_Customers: 'Fournisseurs & Prestataires',
  customer_create_success: 'Le prestataire a été créé avec succès',
  customer_create_failure: 'Le prestataire n\'a pas pu être créé',
  customer_edit_failure: 'Le prestataire n\'a pas pu être modifié',
  customer_delete_success: 'Le prestataire a été supprimé avec succès',
  customer_delete_failure: 'Le prestataire n\'a pas pu être supprimé',
  customer_name: 'Nom du prestataire',
  website: 'Site Internet',
  customer_type: 'Type de prestataire',
  customer_type_description: 'ex. Electricien, Plombier',
  customer_description_description:
    'Décrivez le but de ce prestataire en quelques lignes...',
  rate: 'Fréquence',
  billing_information: 'Détails sur la facturation',
  address_line_2: 'Adresse Ligne 2',
  billing_name: 'Nom de la facturation',
  currency: 'Devise',
  select_currency: 'Sélectionnez la devise',
  required_customer_name: 'Le nom du prestataire est requis',
  required_phone: 'Le numéro de téléphone est obligatoire',
  invalid_website: 'Site Web invalide',
  billing_address: 'Adresse de facturation',
  add_customer: 'Ajouter un prestataire',
  add_customer_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un nouveau prestataire',
  'noRows.customer.message': 'Les prestataires sont des travailleurs externes',
  'noRows.customer.action':
    'Appuyez sur le bouton \'+\' pour créer un prestataire',
  type: 'Type',
  billing_currency: 'Devise de facturation',
  go_back: 'Retourner',
  confirm_delete_customer: 'Voulez-vous vraiment supprimer ce prestataire ?',
  vendor_create_success: 'Le fournisseur a été créé avec succès',
  vendor_create_failure: 'Le fournisseur n\'a pas pu être créé',
  vendor_edit_failure: 'Le fournisseur n\'a pas pu être modifié',
  vendor_delete_success: 'Le fournisseur a été supprimé avec succès',
  vendor_delete_failure: 'Le fournisseur n\'a pas pu être supprimé',
  required_company_name: 'Le nom de l\'entreprise est requis',
  vendor_type: 'Type de fournisseur',
  contact_name: 'Nom du contact',
  add_vendor: 'Ajouter un fournisseur',
  add_vendor_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un nouveau fournisseur',
  confirm_delete_vendor: 'Voulez-vous vraiment supprimer ce fournisseur ?',
  'noRows.vendor.message':
    'Les fournisseurs sont des organisations qui fournissent des équipements ou des pièces',
  'noRows.vendor.action': 'Appuyez sur le bouton \'+\' pour créer un fournisseur',
  vendor_type_description: 'ex. Plombier, Electricien',
  //people teams
  role: 'Rôle',
  edit_user: 'Modifier l\'utilisateur',
  edit_user_description:
    'Remplissez les champs ci-dessous pour modifier l\'utilisateur',
  job_title: 'Profession',
  invite_users: 'Inviter des utilisateurs',
  bring_people_team: 'Amener de nouvelles personnes dans l\'équipe',
  add_20_users:
    'Vous pouvez ajouter 20 utilisateurs à la fois en appuyant sur «Tab» ou «Entrée» après chaque entrée d\'e-mail. ',
  enter_email: 'Entrer l\'adresse e-mail',
  users_invite_success: 'Les utilisateurs ont été invités',
  users_invite_failure:
    'Les utilisateurs ne peuvent pas être invités. Vérifiez le nombre de membres de votre abonnement actuel.',
  please_type_emails: 'Veuillez saisir des e-mails à inviter',
  please_select_role: 'Veuillez sélectionner un rôle',
  invite: 'Inviter',
  team_create_success: 'L\'équipe a été créée avec succès',
  team_create_failure: 'L\'équipe n\'a pas pu être créée',
  team_edit_failure: 'L\'équipe n\'a pas pu être modifiée',
  team_delete_success: 'L\'équipe a été supprimée avec succès',
  team_delete_failure: 'L\'équipe n\'a pas pu être supprimée',
  team_name: 'Nom de l\'équipe',
  people_in_team: 'Les membres de l\'équipe',
  required_team_name: 'Le nom de l\'équipe est requis',
  create_team: 'Créer une équipe',
  create_team_description: 'Vous pouvez ajouter des membres à partir d\'ici',
  confirm_delete_team: 'Êtes-vous sûr de vouloir supprimer cette équipe ?',
  overview: 'Aperçu',
  activity: 'Activité',
  wo_complete_last_14_days:
    'Ordres de travail clôturés au cours des 14 derniers jours',
  no_wo_complete_last_14_days:
    'Vous n\'avez clôturé aucun bon de travail au cours des deux dernières semaines',
  ADMIN_name: 'Administrateur',
  ADMIN_description:
    'L\'administrateur a un accès complet; y compris l\'édition, l\'ajout, la suppression d\'ordres de travail et de demandes',
  LIMITED_ADMIN_name: 'Administrateur limité',
  LIMITED_ADMIN_description:
    'Les administrateurs limités ont le même accès que l\'administrateur, sauf qu\'ils ne peuvent pas afficher/modifier les paramètres ou ajouter/modifier des personnes et des équipes. Ils ne peuvent pas supprimer les ordres de travail, les emplacements des équipements, les compteurs et les bons de commande à moins d\'avoir créé des déclencheurs d\'ordre de travail, des catégories ou des prestataires.',
  TECHNICIAN_name: 'Technicien',
  TECHNICIAN_description:
    'Les techniciens peuvent créer et clôturer des ordres de travail, des équipe et des emplacements. Capables de modifier et de supprimer uniquement ce qu\'ils ont créé',
  LIMITED_TECHNICIAN_name: 'Technicien limité',
  LIMITED_TECHNICIAN_description:
    'Les techniciens limités ne peuvent voir que les bons de travail qui leur sont assignés',
  VIEW_ONLY_name: 'Vue uniquement',
  VIEW_ONLY_description:
    'Ces utilisateurs peuvent tout voir, mais ne peuvent rien modifier',
  REQUESTER_name: 'Demandeur',
  REQUESTER_description:
    'Les demandeurs peuvent uniquement soumettre des demandes de travail et voir leur statut',
  select_user_role: 'Sélectionnez le rôle',
  'noRows.team.message':
    'Les équipes vous aident à gérer des groupes spécifiques de personnes.',
  'noRows.team.action': 'Appuyez sur le bouton \'+\' pour créer une équipe.',
  user_details: 'Détails sur l\'utilisateur',
  file_delete_success: 'Le fichier a été supprimé avec succès',
  file_delete_failure: 'Le fichier n\'a pas pu être supprimé',
  uploaded_by: 'Telechargé par',
  uploaded_on: 'Téléchargé le',
  rename: 'Renommer',
  required_files: 'Veuillez télécharger au moins un fichier',
  required_file_name: 'Veuillez entrer un nom de fichier',
  add_files: 'Ajouter des fichiers',
  edit_file: 'Modifier le fichier',
  'noRows.file.message': 'Affichez tous les fichiers en un seul endroit',
  'noRows.file.action': 'Appuyez sur le bouton \'+\' pour télécharger un fichier',
  upgrade_files:
    'Mettez à niveau pour utiliser des fichiers dans vos équipements, ordres de travail, emplacements, etc.',
  parts_consumption: 'Consommation des pièces',
  requests_analysis: 'Analyse des Demandes',
  category_create_success: 'La catégorie a été créée avec succès',
  category_create_failure: 'La catégorie n\'a pas pu être créée',
  category_edit_failure: 'La catégorie n\'a pas pu être modifiée',
  category_delete_success: 'La catégorie a été supprimée avec succès',
  category_delete_failure: 'La catégorie n\'a pas pu être supprimée',
  timers: 'Minuteries',
  costs: 'Coûts',
  add_category: 'Ajouter une nouvelle catégorie',
  add_category_description:
    'Entrez le nom pour créer et ajouter une nouvelle catégorie',
  edit_category: 'Modifier la catégorie',
  edit_category_description: 'Entrez le nom pour modifier la catégorie',
  no_category_action:
    'Appuyez sur le bouton \'+\' pour ajouter votre première catégorie.',
  confirm_delete_category: 'Voulez-vous vraiment supprimer cette catégorie ?',
  no_category_message:
    'Il semble que vous n\'ayez pas encore de catégories sur les {{categoryName}}.',
  acquisition_cost: 'Coût d\'acquisition',
  //settings
  general_settings: 'Paramètres généraux',
  request_form_configuration: 'Configuration du formulaire de demande',
  roles: 'Rôles',
  checklists: 'Checklists',
  field_configuration_description:
    'Vous pouvez marquer les champs comme facultatifs, masqués ou obligatoires',
  optional: 'Facultatif',
  required: 'Obligatoire',
  hidden: 'Masqué',
  creating_wo: 'Création d\'un bon de travail',
  completing_wo: 'Clôture d\'un bon de travail',
  role_edit_failure: 'Impossible de modifier le rôle',
  edit_role: 'Modifier le rôle',
  edit_role_description:
    'Remplissez les champs ci-dessous pour modifier le rôle',
  external_id: 'ID externe',
  permissions: 'Permissions',
  create_role_description:
    'Ce rôle peut faire tout ce qu\'un administrateur peut faire dans Atlas, mais vous pouvez personnaliser certaines permissions importantes ci-dessous.',
  create_and_edit: 'Créer/Modifier',
  to_access: 'Accéder',
  role_delete_success: 'Le rôle a été supprimé avec succès',
  role_delete_failure: 'Le rôle n\'a pas pu être supprimé',
  confirm_delete_role:
    'Êtes-vous sûr de vouloir supprimer définitivement ce rôle',
  users: 'Utilisateurs',
  paid: 'Payant',
  free: 'Gratuit',
  role_create_success: 'Le rôle a été créé avec succès',
  role_create_failure: 'Le rôle n\'a pas pu être créé',
  roles_management: 'Gestion des rôles',
  roles_number: '{{count}} rôles',
  create_role: 'Créer un rôle',
  upgrade_role: 'Mettez à niveau pour créer un rôle',
  add_role: 'Ajouter un nouveau rôle',
  add_role_description:
    'Remplissez les champs ci-dessous pour créer et ajouter un nouveau rôle',
  parts_and_sets: 'Pièces & Jeux de pièces',
  upgrade_role_edit: 'Mettez à niveau pour modifier le rôle',
  delete_role: 'Supprimer le rôle',
  upgrade_role_delete: 'Mettez à niveau pour supprimer le rôle',
  create: 'Créer',
  pm_trigger: 'Déclencheurs de Maintenance Préventive',
  auto_assign_wo: 'Affecter automatiquement les bons de travail',
  auto_assign_wo_description:
    'Affecter automatiquement de nouveaux bons de travail à la personne qui les crée',
  auto_assign_requests: 'Attribuer automatiquement les demandes',
  auto_assign_requests_description:
    'Attribuer automatiquement de nouveaux ordres de travail à la personne qui approuve la demande',
  disable_closed_wo_notification:
    'Désactiver les notifications de bons de travail terminés',
  disable_closed_wo_notification_description:
    'Désactiver les notifications lorsque les bons de travail terminés sont mis à jour',
  ask_feedback_wo_closed:
    'Demander des commentaires lorsque le bon de travail est fermé',
  ask_feedback_wo_closed_description:
    'Les utilisateurs sont invités à donner leur avis sur le travail effectué',
  include_labor_in_total_cost:
    'Inclure le coût de la main-d\'œuvre dans le coût total',
  include_labor_in_total_cost_description:
    'Ajouter les coûts de main-d\'œuvre au total lorsqu\'un utilisateur enregistre du temps et a un taux horaire',
  enable_wo_updates_requesters:
    'Activer les mises à jour des bons de travail pour les demandeurs',
  enable_wo_updates_requesters_description:
    'Les utilisateurs reçoivent des mises à jour pour les ordres de travail qu\'ils ont demandés',
  language: 'Langue',
  date_format: 'Format de date',
  business_type: 'Type d\'entreprise',
  general_asset_management: 'Gestion générale des équipements',
  physical_asset_management: 'Gestion des équipements physiques',
  checklist_delete_success: 'La Checklist a été supprimée avec succès',
  checklist_delete_failure: 'La Checklist n\'a pas pu être supprimée',
  create_checklist: 'Créer une Checklist',
  confirm_delete_checklist: 'Voulez-vous vraiment supprimer cette Checklist?',
  upgrade_checklist: 'Veuillez mettre à niveau pour utiliser les Checklists',
  upgrade_now: 'Mettre à jour maintenant',
  total: 'Total',
  sub_task_status: 'Sous-tâche',
  text_field: 'Champ de texte',
  number_field: 'Champ numérique',
  inspection_check: 'Contrôle d\'inspection',
  multiple_choices: 'Choix multiples',
  meter_reading: 'Relevé de compteur',
  select_user: 'Sélectionnez l\'utilisateur',
  PASS: 'Passé',
  FLAG: 'Signalé',
  FAIL: 'Échoué',
  enter_task_name: 'Entrez un nom de tâche',
  see_details: 'Voir les détails',
  concerned_asset: 'Equipement concerné',
  me: 'Moi',
  remove_blank_tasks: 'Supprimer les tâches vides',
  remove_blank_options: 'Supprimer les options vides',
  preview: 'Aperçu',
  edit_checklist: 'Modifier la checklist',
  add_tasks: 'Ajouter des tâches',
  use_a_checklist: 'Utilisr une Checklist',
  select_checklist: 'Sélectionner une Checklist',
  save_checklist: 'Enregistrer la Checklist',
  assign_tasks_description:
    'Attribuez des tâches personnalisées aux techniciens à remplir',
  select_date: 'Sélectionner une date',
  task: 'Tâche',
  checklist: 'Checklist',
  upgrade_analytics: 'Mettez à niveau pour voir les analytiques',
  grouped_by_category: 'Regroupés par catégorie',
  downtime_by_asset: 'Temps d\'arrêt par équipement',
  downtime_and_costs: 'Temps d\'arrêt et coûts',
  downtime_and_costs_trends: 'Tendances des temps d\'arrêt et des coûts',
  downtimes_trends: 'Tendances des temps d\'arrêt',
  repair_time_by_asset: 'Temps de réparation par équipement',
  consumed_parts_costs: 'Coûts des pièces consommées',
  requests_by_priority: 'Demandes par priorité',
  requests_trends: 'Tendances des demandes',
  time_spent: 'Temps passé',
  grouped_by_completed_by: 'Regroupés par Complété par',
  grouped_by_assigned_to: 'Regroupés par Attribué à',
  grouped_by_priority: 'Regroupés par priorité',
  completion_comparison: 'Comparaison d\'achèvement',
  hours_worked: 'Heures travaillées',
  work_remaining: 'Travail restant',
  wo_status: 'Statut de l\'ordre de travail',
  cost_trends: 'Tendances des coûts',
  cost_center: 'Analyse des coûts du cycle de vie des actifs',
  downtime_and_availability: 'Temps d\'arrêt et disponibilité',
  incomplete_wo: 'Bons de travail incomplets',
  the_numbers: 'Les chiffres',
  hours_and_cost_by_asset: 'Heures et coût par équipement',
  completion_comparison_description:
    'Comparaison de divers types de bons de travail terminés.',
  wo_age_description:
    'L\'âge du bon de travail est défini comme le nombre de jours entre la création du bon de travail (ou de la demande de travail) et la clotûre.',
  rav_description:
    'Le coût total en % de la RAV est égal à (coût des ordres de travail sur l\'équipement / prix d\'achat)*100. Le standard est une valeur de 3%',
  downtime_and_availability_description:
    'La disponibilité fait référence au pourcentage selon lequel les équipements étaient dans un état opérationnel depuis leur mise en service. ',
  total_cost_description:
    'Le coût total comprend le coût de la main-d\'œuvre, le coût supplémentaire et le coût des pièces dans un bon de travail',
  compliant_wo_description:
    'Les bons de travail conformes sont définis comme des bons de travail terminés avant la date d\'échéance. ',
  hours_worked_description:
    'Ces heures correspondent aux ordres de travail dont la date d\'échéance se situe dans la plage spécifiée dans les filtres.',
  work_remaining_description:
    'Ce graphique montre le nombre d\'ordres de travail incomplets qui sont dus dans la plage de dates spécifiée dans les filtres. ',
  total_downtime_in_hours: 'Temps d\'arrêt total (heures)',
  downtime_events: 'Événements d\'indisponibilité',
  percent_downtime: '% de temps d\'arrêt',
  meantime_between_failures_in_hours: 'Temps moyen entre les pannes (heures)',
  availability_percent: 'Pourcentage de disponibilité',
  mean_time_to_repair_in_hours: 'Temps moyen de réparation (heures)',
  average_age: 'Âge moyen',
  count: 'Nombre',
  average_cost: 'Coût moyen',
  compliant: 'Conformes',
  average_cycle_time_in_days: 'Durée de cycle moyenne (jours)',
  total_consumption_cost: 'Coût total de consommation',
  parts_consumed: 'Pièces consommées',
  total_cost_as_pct_rav: 'Coût total en pourcentage de RAV',
  approved_requests: 'Demandes approuvées',
  pending_requests: 'Demandes en attente',
  cancelled_requests: 'Demandes rejetées',
  average_cycle_time: 'Durée de cycle moyenne',
  compliance_rate: 'Taux de conformité',
  complete: 'Terminés',
  reactive: 'Réactifs',
  recurring: 'Récurrents',
  part_costs: 'Coûts des pièces',
  labor_costs: 'Coût du travail',
  total_time_spent_in_hours: 'Temps total (Heures)',
  estimated_hours: 'Heures estimées',
  total_purchase_price: 'Coût d\'acquisition total',
  company: 'Entreprise',
  change_cover: 'Changer de couverture',
  required_address: 'Le champ adresse est obligatoire',
  company_details: 'Détails de l\'entreprise',
  company_details_description:
    'Gérer les informations relatives à votre entreprise',
  learn_more: 'Voir plus',
  you_have: 'Vous avez',
  new_messages: 'nouveaux messages',
  profile: 'Profil',
  email_notifications: 'Notifications par email',
  email_updates_wo:
    'Notifications par e-mail pour les ordres de travail et les messages',
  email_updates_requests: 'Notifications par e-mail pour les demandes',
  po_emails: 'E-mails de bon de commande',
  required_job_title: 'Le champ Intitulé du poste est obligatoire',
  required_old_password: 'Veuillez fournir le mot de passe actuel.',
  invalid_password:
    'Le mot de passe est trop court - doit comporter au moins 8 caractères.',
  required_new_password: 'Aucun mot de passe fourni.',
  passwords_must_match: 'les mots de passe doivent correspondre',
  password_change_success: 'Le mot de passe a été changé avec succès',
  wrong_password: 'Mauvais mot de passe fourni',
  current_password: 'Mot de passe actuel',
  new_password: 'Nouveau mot de passe',
  confirm_password: 'Confirmez le mot de passe',
  personal_details: 'Détails personnels',
  personal_details_description:
    'Gérer les informations relatives à vos données personnelles',
  change_password: 'Changer le mot de passe',
  notification_settings: 'Paramètres de notifications',
  notification_settings_description:
    'Gérer les détails liés à vos notifications',
  recent_activity: 'Activité récente',
  created: 'Créés',
  completed: 'Clôturés',
  my_account: 'Mon compte',
  my_company: 'Mon entreprise',
  you_are_using_plan:
    'Vous utilisez actuellement le plan {{planName}}. Il expirera le {{expiration}}. Mettez à niveau maintenant pour accéder à plus de fonctionnalités.',
  upgrade_plan: 'Changer de plan',
  plans: 'Plans',
  monthly: 'Mensuel',
  annually: 'Annuel',
  subscription_change_success: 'L\'abonnement a été modifié avec succès',
  invalid_credit_card: 'Numéro de carte de crédit invalide',
  required_credit_card: 'Le numéro de la carte est obligatoire',
  invalid_expiration_month: 'Le mois d\'expiration n\'est pas valide',
  required_expiration_month: 'Le mois d\'expiration est requis',
  invalid_expiration_year: 'L\'année d\'expiration n\'est pas valide',
  required_expiration_year: 'L\'année d\'expiration est requise',
  invalid_cvv: 'Le CVV n\'est pas valide',
  required_cvv: 'Le CVV est obligatoire',
  required_cardholder_name: 'Le nom du titulaire de la carte est requis',
  card: 'Carte',
  expiration_month: 'Mois d\'expiration',
  expiration_year: 'Année d\'expiration',
  cardholder_name: 'Nom du titulaire',
  seats: 'Places',
  cost_per_seat: 'Coût par place',
  your_payment_secure: 'Vos données de paiement sont cryptées et sécurisées.',
  plan: 'Plan',
  current_plan: 'Plan actuel',
  number_users_who_will_use_grash:
    'Nombre d\'utilisateurs qui utiliseront Atlas',
  admin_role: 'Administrateur',
  technical_role: 'Technique',
  limited_technical_role: 'Technique limitée',
  requester_role: 'Demandeur',
  view_only_role: 'Affichage uniquement',
  how_will_you_be_billed: 'Comment souhaitez-vous être facturé ?',
  which_plan_fits_you: 'Quel plan vous convient le mieux?',
  per_user_month: 'par utilisateur/mois',
  per_user_year: 'par utilisateur/an',
  you_will_be_charged: 'Vous serez facturé',
  proceed_to_payment: 'Procéder au paiement',
  pay_only_for_roles:
    'Payez uniquement pour les utilisateurs <strong>Administrateurs</strong>, <strong>Techniques</strong> et <strong>Techniques limités</strong>, et utilisez gratuitement un nombre illimité de <strong>Demandeurs</strong>, et utilisateurs en <strong>Lecture seule</strong>',
  checkout_our: 'Regardez notre',
  pricing_page: 'Page des tarifs',
  for_more_details: 'pour plus de détails',
  users_count_display: '{{count}} Utilisateurs',
  monthly_adverb: 'mensuellement',
  yearly_adverb: 'annuellement',
  features: 'Fonctionnalités',
  checkout: 'Checkout',
  checkout_description: 'Remplissez les champs ci-dessous',
  per_month: 'par mois',
  per_year: 'par an',
  recover_password_description:
    'Entrez votre email afin de réinitialiser votre mot de passe.',
  send_me_new_password: 'Envoyez-moi un nouveau mot de passe',
  want_to_sign_in_again: 'Voulez-vous réessayer de vous connecter?',
  check_mails_for_instructions:
    'Vérifiez votre e-mail pour plus d\'instructions',
  pdf_report: 'Rapport PDF',
  HIGH: 'Haute',
  MEDIUM: 'Moyenne',
  LOW: 'Basse',
  NONE: 'Aucune',
  import: 'Importation',
  start_import_process: 'Commencer le processus d\'importation',
  import_data: 'Importer',
  set_header: 'Entêtes',
  match_columns: 'Faire correspondre les colonnes',
  review: 'Evaluer',
  done: 'Finaliser',
  no_match_yet: 'Aucune correspondance pour le moment',
  select: 'Sélectionnez...',
  matched_to_field: 'Correspond à {{field}}',
  next: 'Suivant',
  not_enough_rows: 'Pas assez de lignes dans le fichier',
  there_are_duplicates: 'Il y a des doublons: {{duplicates}}',
  assigned_by: 'Assigné Par',
  percent_rows_have_value: '{{percent}}% des lignes ont une valeur',
  select_at_least_column: 'Faites correspondre au moins une colonne',
  to_import: 'Importer',
  required_match: 'La correspondance à {{field}} est requise',
  archived: 'Archivé',
  import_wo_success:
    '{{created}} bons de travail ont été créés et {{updated}} ont été mis à jour',
  to_export: 'Exporter',
  filters: 'Filtres',
  more_filters: 'Plus de Filtres',
  customer: 'Prestataire',
  to: 'au',
  dates: 'Dates',
  start: 'Début',
  end: 'Fin',
  longitude: 'Longitude',
  latitude: 'Latitude',
  ALL: 'Tous',
  REACTIVE: 'Réactif',
  REPEATING: 'Préventif',
  import_location_success:
    '{{created}} emplacements ont été créés et {{updated}} ont été mis à jour',
  import_part_success:
    '{{created}} Pièces ont été créées et {{updated}} ont été mises à jour',
  import_meter_success:
    '{{created}} Compteurs ont été créés et {{updated}} ont été mis à jour',
  import_asset_success:
    '{{created}} Equipements ont été créés et {{updated}} ont été mis à jour',
  wo_calendar: 'Calendrier des ordres de travail',
  month: 'Mois',
  week: 'Semaine',
  day: 'Jour',
  agenda: 'Agenda',
  previous: 'Précédent',
  today: 'Aujourd\'hui',
  to_filter: 'Filtrer',
  'noRows.asset.message':
    'Les équipements sont des ressources sur lesquelles votre entreprise peut intervenir',
  'noRows.asset.action':
    'Appuyez sur le bouton \'+\' pour créer un nouvel équipement.',
  reset_filters: 'Réinitialiser les filtres',
  toggle_menu: 'Commuter le menu',
  no_results: 'Aucun résultat trouvé',
  an_error_occured: 'Une erreur s\'est produite.',
  density: 'Densité',
  compact: 'Compact',
  standard: 'Standard',
  comfortable: 'Confortable',
  columns: 'Colonnes',
  select_columns: 'Sélectionner les colonnes',
  show_filters: 'Afficher les filtres',
  hide_filters: 'Masquer les filtres',
  search_placeholder: 'Rechercher…',
  search: 'Recherche',
  clear: 'Réinitialiser',
  download_as_csv: 'Télécharger au format CSV',
  print: 'Imprimer',
  download_as_excel: 'Télécharger au format Excel',
  find_column: 'Rechercher la colonne',
  column_title: 'Titre de la colonne',
  reorder_column: 'Réorganiser la colonne',
  show_all: 'Afficher tout',
  hide_all: 'Cacher tout',
  add_filter: 'Ajouter un filtre',
  logic_operator: 'Opérateur logique',
  operator: 'Opérateur',
  and: 'Et',
  or: 'Ou',
  filter_value: 'Valeur du filtre',
  contains: 'contient',
  equals: 'équivaut à',
  starts_with: 'commence avec',
  ends_with: 'se termine par',
  is: 'est',
  is_not: 'n\'est pas',
  is_after: 'est après',
  is_on_or_after: 'est sur ou après',
  is_before: 'est avant',
  is_on_or_before: 'est le ou avant',
  is_empty: 'est vide',
  is_not_empty: 'n\'est pas vide',
  is_any_of: 'est l\'un des',
  any: 'n\'importe quel',
  true_text: 'vrai',
  false_text: 'faux',
  menu: 'Menu',
  show_columns: 'Afficher les colonnes',
  hide: 'Cacher',
  unsort: 'Détrier',
  sort_by_ASC: 'Trier par ASC',
  sort_by_DESC: 'Trier par DESC',
  sort: 'Trier',
  total_rows: 'Nombre total de lignes:',
  yes: 'Oui',
  no: 'Non',
  more: 'Plus',
  to_group: 'Grouper',
  see_children: 'voir les enfants',
  hide_children: 'cacher les enfants',
  expand: 'Développer',
  collapse: 'Plier',
  detail_panel_toggle: 'Bascule du panneau de détails',
  cancel_subscription: 'Annuler la souscription',
  resume_subscription: 'Reprendre la souscription',
  confirm_cancel_subscription:
    'Etes-vous surs de vouloir annuler la souscription?',
  workflows: 'Flux de travail',
  create_workflow: 'Créer Un flux de travail',
  if: 'Si',
  and_optional: 'Et(Optionnel)',
  then: 'Alors',
  workflow_description:
    'Créez des flux de travail personnalisés aussi simples que Si, Et, Alors. Gagnez du temps et attribuez facilement vos ordres de travail automatiquement via des flux de travail pour personnaliser Atlas en fonction du fonctionnement de votre équipe.\nTous les plans peuvent créer jusqu\'à 1 flux de travail personnalisé. Pour ajouter des flux de travail supplémentaires, passez à notre plan Business',
  CREATE_PURCHASE_ORDER: 'Créer un bon de commande',
  PART_IS: 'La pièce est',
  QUANTITY_INFERIOR: 'La quantité est inférieure à',
  ASSIGN_VENDOR: 'Affecter le fournisseur',
  ASSIGN_CATEGORY: 'Affecter la catégorie',
  APPROVE: 'Approuver',
  REJECT: 'Rejeter',
  SEND_REMINDER_EMAIL: 'Envoyer un e-mail de rappel',
  VENDOR_IS: 'Le fournisseur est',
  CATEGORY_IS: 'La catégorie est',
  STATUS_IS: 'Le statut est',
  DUE_DATE_BETWEEN: 'La date d\'échéance se situe entre',
  DUE_DATE_AFTER: 'La date d\'échéance est postérieure à',
  ASSIGN_PRIORITY: 'Attribuer la priorité',
  ASSIGN_ASSET: 'Attribuer l\'équipement',
  ASSIGN_LOCATION: 'Attribuer l\'emplacement',
  ASSIGN_USER: 'Attribuer l\'utilisateur',
  ASSIGN_TEAM: 'Affecter l\'équipe',
  ADD_CHECKLIST: 'Ajouter une liste de contrôle',
  PRIORITY_IS: 'La priorité est',
  ASSET_IS: 'L\'équipement est',
  LOCATION_IS: 'L\'emplacement est',
  USER_IS: 'L\'utilisateur est',
  TEAM_IS: 'L\'équipe est',
  CREATED_AT_BETWEEN: 'Est créé entre',
  TITLE_CONTAINS: 'Le titre contient',
  CREATE_REQUEST: 'Créer une demande',
  CREATE_WORK_ORDER: 'Créer un bon de travail',
  SET_ASSET_STATUS: 'Définir l\'état de l\'équipement',
  NAME_IS: 'Le nom est',
  NAME_CONTAINS: 'Le nom contient',
  VALUE_IS: 'La valeur est',
  VALUE_CONTAINS: 'La valeur contient',
  NUMBER_VALUE_SUPERIOR: 'La valeur est supérieure à',
  NUMBER_VALUE_INFERIOR: 'La valeur est inférieure à',
  WORK_ORDER_CREATED: 'Un ordre de travail est créé',
  WORK_ORDER_CLOSED: 'Un bon de travail est clôturé',
  WORK_ORDER_ARCHIVED: 'Un bon de travail est archivé',
  REQUEST_CREATED: 'Une demande est créée',
  REQUEST_APPROVED: 'Une demande est approuvée',
  REQUEST_REJECTED: 'Une demande est rejetée',
  PURCHASE_ORDER_CREATED: 'Un bon de commande est créé',
  PURCHASE_ORDER_UPDATED: 'Un bon de commande est mis à jour',
  TASK_UPDATED: 'Une tâche est mise à jour',
  PART_UPDATED: 'Une pièce est mise à jour',
  add_condition: 'Ajouter une condition',
  workflow_creation_success: 'Le flux de travail a été créé avec succès',
  workflow_creation_failure: 'La création du workflow a échoué',
  action_value_missing: 'La valeur de l\'action est manquante',
  condition_value_missing: 'La valeur d\'une condition est manquante',
  workflow_edit_success: 'Le flux de travail a été mis à jour avec succès',
  workflow_edit_failure: 'Le flux de travail n\'a pas pu être mis à jour',
  workflow_delete_success: 'Le workflow a été supprimé',
  workflow_delete_failure: 'Le workflow n\'a pas pu être supprimé',
  confirm_delete_workflow: 'Etes-vous sûr de vouloir supprimer ce workflow',
  click_here: 'Cliquez ici',
  verify_email_title: 'Vérifier l\'e-mail',
  verify_email_description:
    'Un e-mail de vérification vous a été envoyé. Vérifiez les spams si vous ne le voyez pas dans votre boîte de réception.',
  upgrade: 'Mise à niveau',
  downgrade: 'Rétrograder',
  upgrade_description:
    'Vous venez de mettre à jour votre plan d\'abonnement. Vous devez activer les utilisateurs désactivés susceptibles d\'utiliser Atlas. Vous ne pouvez le faire qu\'une seule fois pour l\'abonnement en cours. Veuillez choisir avec soin',
  enable_users: 'Activer les utilisateurs',
  max_users_description:
    'Vous pouvez activer jusqu\'à {{maxUsers}} utilisateurs',
  downgrade_description:
    'Vous venez de rétrograder votre plan d\'abonnement. Vous devez désactiver certains utilisateurs pour continuer. Vous ne pouvez le faire qu\'une seule fois pour l\'abonnement en cours. Veuillez choisir avec soin',
  disable_users: 'Désactiver les utilisateurs',
  min_users_description:
    'Vous devez désactiver un minimum de {{minUsers}} utilisateurs',
  downgrade_description_no_owner:
    'Votre entreprise vient de diminuer le nombre d\'utilisateurs dans son plan d\'abonnement. Le propriétaire de l\'entreprise doit désactiver certains utilisateurs avant que vous puissiez continuer',
  past_due: 'En retard',
  already_read: 'Relevé',
  due_date_delay: 'Jours avant l\'échéance',
  due_date_delay_description: 'Nombre de jours',
  days_count: '{{days}} jours',
  trigger_configuration: 'Configuration du déclencheur',
  WORK_ORDER_feature: 'Bons de travail',
  REQUEST_feature: 'Demandes de travail',
  PART_feature: 'Gestion des pièces',
  MOBILE_APP_feature: 'Application mobile',
  WORK_ORDER_HISTORY_feature: 'Historique des bons de travail',
  PREVENTIVE_MAINTENANCE_feature: 'Maintenance préventive',
  CHECKLIST_feature: 'Listes de contrôle',
  FILE_feature: 'Téléchargements de fichiers',
  PURCHASE_ORDER_feature: 'Bons de commande',
  METER_feature: 'Relevés de compteurs',
  REQUEST_CONFIGURATION_feature: 'Configuration du formulaire de demande',
  ADDITIONAL_TIME_feature: 'Suivi du temps avancé',
  ADDITIONAL_COST_feature: 'Suivi avancé des coûts',
  ANALYTICS_feature: 'Analytique',
  REQUEST_PORTAL_feature: 'Portail de demande',
  SIGNATURE_feature: 'Signatures',
  ROLE_feature: 'Rôles personnalisés',
  WORKFLOW_feature: 'Flux de travail automatisés',
  API_ACCESS_feature: 'Accès à l\'API',
  WEBHOOK_feature: 'Webhooks',
  add_new_option: 'Ajouter une option',
  edit_company: 'Modifier les informations sur l\'entreprise',
  fill_fields_below: 'Remplissez les champs ci-dessous',
  IMPORT_CSV_feature: 'Importation CSV',
  no_import_access:
    'Mettez à jour votre plan pour importer depuis des fichiers CSV ou Excel',
  members: 'Membres',
  assign_user: 'Assigner un utilisateur',
  assign_asset: 'Assigner un équipement',
  required_files_on_completion:
    'Les fichiers sont requis à la clôture du bon de travail',
  required_tasks_on_completion: 'Les tâches doivent être terminées',
  required_labor_on_completion:
    'Vous devez enregistrer du temps pour clôturer ce bon de travail',
  required_part_on_completion:
    'Aucune pièce n\'a été utilisée dans ce bon de travail',
  required_cost_on_completion:
    'Aucune information de coût fournie dans ce bon de travail',
  estimated_hours_in_text: '{{hours}} heures estimées',
  required_part_name: 'Le nom de la pièce est requis',
  asset_update_failure:
    'La modification de l\'équipement ne s\'est pas bien passée',
  terms_of_service: 'Conditions d\'utilisation',
  privacy_policy: 'Politique de confidentialité',
  last_login: 'Dernière connexion',
  not_enough_part: 'Cette pièce n\'est plus en stock',
  remove_blank_meter_tasks:
    'Supprimez les tâches de type compteur sans compteur',
  checklist_update_failure: 'La checklist n\'a pas pu être modifiée',
  contact_us: 'Nous Contacter',
  simplify_wo: 'Simplifier les Ordres de Travail dans l\'application mobile',
  simplify_wo_description:
    'Cacher le compteur de temps, les coûts et pièces dans les details d\'un Ordre de travail en utilisant l\'application mobile',
  switch_account: 'Changer de compte',
  switch_to_super_user: 'Passer en super administrateur',
  switch_account_description: 'Veuillez choisir un compte pour continuer',
  put_location_in_map: 'Placer l\'emplacement sur une carte',
  confirm_disable_user: 'Etes-vous sûr de désactiver {{user}}? Vous ne pourrez l\'activer que dans un mois',
  user_disabled_success: 'Utilisateur désactivé',
  disable: 'Désactiver',
  manufacturer: 'Fabriquant',
  power: 'Puissance',
  import_error: 'L\'importation a échoué',
  request_upgrade: 'Demander un changement de plan',
  operation_success: 'L\'opération a réussi',
  CANCELLED: 'Rejeté',
  uptime_hours: 'Disponibilité(h)',
  downtime_hours: 'Temps d\'arrêt(h)',
  mark_all_as_seen: 'Tout marquer comme lues',
  estimation_accuracy_ratio: 'Taux de précision de l\'estimation',
  download_template: 'Télécharger le modèle',
  'pareto_by_total_price': 'Pareto par prix total',
  'cumulative_percent': 'Pourcentage cumulatif',
  'part_consumption_by_asset': 'Consommation de pièces par équipement',
  'grouped_by_wo_category': 'Groupés par catégorie de bon de travail',
  'resolution_rate': 'Taux de résolution',
  'resolved': 'Résolus',
  average: 'Moyenne',
  documentation: 'Documentation',
  days_before_pm_notification: 'Notifications préalables aux bons de travail pour ceux planifiés',
  estimated_start_date: 'Date de début prévue',
  no_file_linked_to_wo: 'Aucun fichier joint à ce bon de travail',
  add_file: 'Ajouter fichier',
  try_for_free: 'Essayez gratuitement',
  choose_your_plan: 'Choisissez votre forfait',
  STARTER_description: 'Équipes cherchant à mettre en place une maintenance préventive efficace et solide grâce à la visibilité sur l\'état des machines et la main-d\'œuvre.',
  PROFESSIONAL_description: 'Départements qui doivent exploiter les informations et les analyses pour stimuler davantage la croissance et la productivité de la maintenance.',
  BUSINESS_description: 'Organisations prêtes à capturer des données de maintenance et d\'exploitation pour gérer plusieurs sites et la personnalisation du système.',
  book_demo: 'Réserver une démo',
  request_pricing: 'Demander un devis pour plus d\'informations'
};

export default frJSON;
